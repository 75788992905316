import { EnvironmentRecord } from '@feature-flags/entities';
import { Loader } from '../../atoms';
import { Header, SegmentDetails, Sidebar } from '../../organisms';
import { SegmentDetailsProps } from '../../organisms/segment/segment-details/types';

interface SegmentDetailsTemplateProps extends SegmentDetailsProps {
  environments: EnvironmentRecord[];
  isLoading: boolean;
}

export function SegmentDetailsTemplate({
  environments,
  isLoading,
  segmentData,
  usageInFlags,
  deleteSegment,
  getUsageInFlags,
  saveSegment,
}: SegmentDetailsTemplateProps) {
  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Header />
      <main data-testid="segment-details-template">
        <Sidebar environments={environments} />
        <section className="container container-x-padding fill">
          {isLoading ? (
            <Loader />
          ) : (
            <SegmentDetails
              segmentData={segmentData}
              usageInFlags={usageInFlags}
              deleteSegment={deleteSegment}
              getUsageInFlags={getUsageInFlags}
              saveSegment={saveSegment}
            />
          )}
        </section>
      </main>
    </>
  );
}
