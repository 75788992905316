import { useModalContext } from '../../organisms';
import { ProjectListTemplate } from '../../templates';
import { useProjectListPageController } from './controller';

export const ProjectListPage: React.FC = () => {
  // Data and Controller Group
  const { projects, ...projectListController } = useProjectListPageController();

  // Context API Group
  const modal = useModalContext();

  // Is this page fully loaded?
  const isLoaded = !!projects;

  return (
    <ProjectListTemplate
      data={{
        projects,
      }}
      controller={{
        projectListController,
      }}
      modal={modal}
      isLoaded={isLoaded}
    />
  );
};
