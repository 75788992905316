export enum AbsolutePath {
  /* Pages Group */
  INDEX = '/',
  PROJECT_LIST = '/projects',
  PROJECT_DETAILS = '/projects/:projectName',
  FLAG_LIST = '/projects/:projectName/environments/:environmentName/flags',
  FLAG_DETAILS = '/projects/:projectName/environments/:environmentName/flags/:flagName',
  FLAG_NOT_FOUND = '/projects/:projectName/environments/:environmentName/flags/:flagName/not-found',
  SEGMENT_LIST = '/projects/:projectName/environments/:environmentName/segments',
  SEGMENT_DETAILS = '/projects/:projectName/environments/:environmentName/segments/:segmentName',
  VISUALIZATION_LIST = '/projects/:projectName/environments/:environmentName/visualization',
  AUDITLOG_LIST = '/projects/:projectName/environments/:environmentName/logs',
  TAG_LIST = '/projects/:projectName/environments/:environmentName/tags',

  /* Api Group */
  PROJECT_ENVIRONMENTS = '/projects/:projectName/environments',
  PROJECT_ENVIRONMENT = '/projects/:projectName/environments/:environmentName',
}
