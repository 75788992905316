import React, { useEffect, useRef, useState } from 'react';
import {
  Dropdown,
  Table,
  TableCell,
  TableRow,
  TableBody,
} from 'yarn-design-system-react-components';
import { Rule, Segment, Split, Variant } from '@feature-flags/entities';
import { DragAndDropList } from '../drag-n-drop/drag-n-drop-list';
import { DragAndDropHandler } from '../drag-n-drop/drag-n-drop-handler';
import { DragAndDropProvider } from '../drag-n-drop/drag-n-drop-context';
import { ButtonList } from '..';
import { Input, ButtonAdd, ButtonDelete } from '../../atoms';
import { Card, CardList } from '../card';
import styles from './rule-list.module.scss';
import { CollectionEvents } from '../../constants';
import { CollectionEvent } from '../../types';
import {
  ConstraintDropdownAction,
  ConstraintDropdownConfig,
  ConstraintDroprownOption,
  SegmentMatch,
  SegmentWithType,
} from './types';
import {
  buildConstraintDropdownsConfig,
  getSegmentsWithTypes,
  getUpdatedConstraintsConfig,
  rebuildInconsistentConstraintsInRules,
  shouldRulesBeRebuilded,
} from './utils';
import { getObjectDeepClone } from '../../helpers/clone/object-deep-clone';

const PERCENTAGE_SPLIT = '%PERCENTAGE_SPLIT%';

type RuleDetailsListProps = {
  rules: Rule[];
  variants: Variant[];
  segments?: Segment[];
  onChange: (event: CollectionEvent<Rule>) => void;
  setRebuiltRules: (rules: Rule[]) => void;
};

export function RuleDetailsList({
  rules,
  segments = [],
  variants,
  onChange,
  setRebuiltRules,
}: RuleDetailsListProps) {
  const [rulesConstraintDropdownsConfig, setRulesConstraintDropdownsConfig] =
    useState<ConstraintDropdownConfig[][]>([]);
  const [segmentsWithTypes, setSegmentsWithTypes] = useState<SegmentWithType[]>(
    []
  );
  const typesExcludedFromDropdownOptions = useRef<Set<string>[]>([]);

  useEffect(() => {
    const segmentsWithTypes = getSegmentsWithTypes(segments);

    setSegmentsWithTypes(segmentsWithTypes);
  }, [segments]);

  useEffect(() => {
    if (!rules.length || !segmentsWithTypes.length) {
      return;
    }

    const constraintsAreInconsistent = shouldRulesBeRebuilded(
      rules,
      segmentsWithTypes
    );

    if (constraintsAreInconsistent) {
      const restructuredRules = rebuildInconsistentConstraintsInRules(
        rules,
        segmentsWithTypes
      );

      return setRebuiltRules(restructuredRules);
    }

    if (rules?.length && segmentsWithTypes.length) {
      const {
        constraintDropdownsConfig,
        typesExcludedFromDropdownOptions: excludedTypes,
      } = buildConstraintDropdownsConfig(rules, segmentsWithTypes);

      typesExcludedFromDropdownOptions.current = excludedTypes;
      setRulesConstraintDropdownsConfig(constraintDropdownsConfig);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rules, segmentsWithTypes]);

  function handleChange(newRule: Rule, index: number) {
    onChange({
      type: CollectionEvents.ItemUpdated,
      newItem: newRule,
      oldItem: rules[index],
    });
  }

  function handleSwap(firstIndex: number, secondIndex: number) {
    onChange({
      type: CollectionEvents.ItemSwapped,
      firstIndex,
      secondIndex,
    });

    [
      rulesConstraintDropdownsConfig[firstIndex],
      rulesConstraintDropdownsConfig[secondIndex],
    ] = [
      rulesConstraintDropdownsConfig[secondIndex],
      rulesConstraintDropdownsConfig[firstIndex],
    ];

    [
      typesExcludedFromDropdownOptions.current[firstIndex],
      typesExcludedFromDropdownOptions.current[secondIndex],
    ] = [
      typesExcludedFromDropdownOptions.current[secondIndex],
      typesExcludedFromDropdownOptions.current[firstIndex],
    ];

    const updatedConfig = getObjectDeepClone(rulesConstraintDropdownsConfig);
    setRulesConstraintDropdownsConfig(updatedConfig);
  }

  function handleAdd() {
    const item: Rule = {
      segmentMatch: { operator: 'any', value: [] },
      splits: [],
    };

    onChange({ type: CollectionEvents.ItemAdded, item });
    typesExcludedFromDropdownOptions.current.unshift(new Set());

    rulesConstraintDropdownsConfig.unshift([
      {
        selectedType: '',
        types: new Set(),
      },
    ]);

    const updatedConfig = getObjectDeepClone(rulesConstraintDropdownsConfig);

    setRulesConstraintDropdownsConfig(updatedConfig);
  }

  function handleDelete(rule: Rule, ruleIndex: number) {
    onChange({ type: CollectionEvents.ItemRemoved, item: rule });
    typesExcludedFromDropdownOptions.current.splice(ruleIndex, 1);
    rulesConstraintDropdownsConfig.splice(ruleIndex, 1);

    const updatedConfig = getObjectDeepClone(rulesConstraintDropdownsConfig);
    setRulesConstraintDropdownsConfig(updatedConfig);
  }

  function handleConstraintChange(
    options: ConstraintDroprownOption[],
    action: ConstraintDropdownAction,
    rule: Rule,
    ruleIndex: number,
    constraintIndex: number
  ) {
    if (
      action.action !== CollectionEvents.ItemAdded &&
      action.action !== CollectionEvents.ItemRemoved
    ) {
      const segmentMatch: SegmentMatch | SegmentMatch[] = Array.isArray(
        rule.segmentMatch
      )
        ? rule.segmentMatch
        : [rule.segmentMatch];

      handleChange(
        {
          ...rule,
          segmentMatch: [
            ...segmentMatch.slice(0, constraintIndex),
            { operator: 'any', value: options.map((opt: any) => opt.value) },
            ...segmentMatch.slice(constraintIndex + 1),
          ],
        },
        ruleIndex
      );
    }

    const updatedConfig = getUpdatedConstraintsConfig({
      options,
      action,
      rule,
      ruleIndex,
      constraintIndex,
      rulesConstraintDropdownsConfig,
      typesExcludedFromDropdownOptions:
        typesExcludedFromDropdownOptions.current,
    });

    setRulesConstraintDropdownsConfig(updatedConfig);
  }

  function renderRule(rule: Rule, ruleIndex: number) {
    return (
      <React.Fragment key={ruleIndex}>
        <RuleDetails
          constraintDropdownsConfig={rulesConstraintDropdownsConfig[ruleIndex]}
          ruleIndex={ruleIndex}
          rule={rule}
          segments={segmentsWithTypes}
          variants={variants}
          onChange={(newRule) => handleChange(newRule, ruleIndex)}
          onDelete={(rule, ruleIndex) => handleDelete(rule, ruleIndex)}
          onConstraintChange={handleConstraintChange}
          onMove={handleSwap}
          excludedTypes={typesExcludedFromDropdownOptions.current[ruleIndex]}
        ></RuleDetails>
      </React.Fragment>
    );
  }

  return (
    <div className="row" data-testid="rules">
      <DragAndDropProvider hasToggler={false} hasHandler={true}>
        <h5 className="space-m-b-0">Rules</h5>
        <ButtonList className="space-p-v-3">
          <ButtonAdd type="secondary" onClick={handleAdd} text="Add Rule" />
        </ButtonList>
        <CardList>
          <DragAndDropList
            items={rules}
            render={renderRule}
            onChange={handleSwap}
          />
        </CardList>
      </DragAndDropProvider>
    </div>
  );
}

type RuleDetailsProps = {
  constraintDropdownsConfig: ConstraintDropdownConfig[];
  excludedTypes: Set<string>;
  ruleIndex: number;
  rule: Rule;
  segments: SegmentWithType[];
  variants: Variant[];
  onChange: (rule: Rule) => void;
  onDelete: (rule: Rule, index: number) => void;
  onConstraintChange: (
    options: ConstraintDroprownOption[],
    action: ConstraintDropdownAction,
    rule: Rule,
    ruleIndex: number,
    constraintIndex: number
  ) => void;
  onMove: (firstIndex: number, secondIndex: number) => void;
};
function RuleDetails({
  constraintDropdownsConfig,
  excludedTypes,
  ruleIndex,
  rule,
  segments,
  variants,
  onChange,
  onDelete,
  onConstraintChange,
  onMove,
}: RuleDetailsProps) {
  if (!rule) return null;

  const constraints = Array.isArray(rule.segmentMatch)
    ? rule.segmentMatch
    : [rule.segmentMatch];

  const isVariantSet = rule.splits.length === 1;

  const variantOptions = variants.map((variant) => ({
    value: variant.name,
    label: variant.name,
    selected: isVariantSet && rule.splits[0].variant === variant.name,
  }));

  variantOptions.push({
    value: PERCENTAGE_SPLIT,
    label: 'Percentage Split',
    selected: !isVariantSet,
  });

  const selectedVariant = variantOptions.find((v) => v.selected);

  function handleVariantChange(option: any) {
    let splits: Split[] = [];
    if (option.value === PERCENTAGE_SPLIT) {
      const size = Math.floor(100 / variants.length);
      const sum = size * variants.length;
      splits = variants.map((v) => ({
        size,
        variant: v.name,
      }));
      if (sum < 100) {
        splits[rule.splits.length - 1].size += 100 - size;
      }
    } else {
      splits = [
        {
          size: 100,
          variant: option.value,
        },
      ];
    }

    onChange({
      ...rule,
      splits,
    });
  }

  function handleSplitChange(split: Split, size: string) {
    onChange({
      ...rule,
      splits: rule.splits.map((s) => {
        const newSplit = { ...s };
        if (s === split) {
          newSplit.size = parseInt(size);
        }
        return newSplit;
      }),
    });
  }

  function renderSplits() {
    return (
      <div className={`${styles['split']} form-groups-no-margin`}>
        <Table border="freeform">
          <TableBody className="border-0">
            {rule.splits.map((split, index) => {
              return (
                <TableRow variant="body" key={index}>
                  <TableCell variant="body" className={`${styles['label']}`}>
                    {split.variant}
                  </TableCell>
                  <TableCell variant="body">
                    <Input
                      onChange={(value) => handleSplitChange(split, value)}
                      value={split.size.toString()}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  }

  function handleAddConstraint(ruleIndex: number) {
    const segmentMatch: SegmentMatch | SegmentMatch[] = Array.isArray(
      rule.segmentMatch
    )
      ? rule.segmentMatch
      : [rule.segmentMatch];

    onChange({
      ...rule,
      segmentMatch: [...segmentMatch, { operator: 'any', value: [] }],
    });

    onConstraintChange(
      [],
      {
        action: CollectionEvents.ItemAdded,
      } as ConstraintDropdownAction,
      {} as Rule,
      ruleIndex,
      0
    );
  }

  function handleDeleteConstraint(
    removedValues: ConstraintDroprownOption[],
    ruleIndex: number,
    constraintIndex: number
  ) {
    const segmentMatch: SegmentMatch | SegmentMatch[] = Array.isArray(
      rule.segmentMatch
    )
      ? rule.segmentMatch
      : [rule.segmentMatch];

    const segmentMatches = [
      ...segmentMatch.slice(0, constraintIndex),
      ...segmentMatch.slice(constraintIndex + 1),
    ];

    if (segmentMatches.length === 0) {
      onDelete(rule, ruleIndex);
    } else {
      onChange({
        ...rule,
        segmentMatch: segmentMatches,
      });

      onConstraintChange(
        [],
        {
          action: CollectionEvents.ItemRemoved,
          removedValues,
          option: {} as ConstraintDroprownOption,
        },
        {} as Rule,
        ruleIndex,
        constraintIndex
      );
    }
  }

  function renderConstraint(
    constraint: any,
    ruleIndex: number,
    constraintIndex: number,
    constraintDropdownConfig: ConstraintDropdownConfig,
    handleDelete: () => void
  ) {
    if (!constraintDropdownConfig) {
      return null;
    }

    const filteredSegments = segments.filter((segment) => {
      if (constraintDropdownConfig.selectedType) {
        return segment.typeLabel === constraintDropdownConfig.selectedType;
      } else {
        const isInExcludedTypes = (
          segment.compositeType
            ? [
                ...Array.from(segment.compositeTypeParts as Set<string>),
                segment.compositeType,
              ]
            : [segment.singleType]
        ).some((type) => type && excludedTypes.has(type));

        return !isInExcludedTypes;
      }
    });

    const segmentOptions = filteredSegments.map((segment) => ({
      value: segment.name,
      label: (
        <>
          <span>{segment.name}</span>
          {Boolean(!constraintDropdownConfig.selectedType) && (
            <span>
              {segment.match.length > 0 &&
                ` (${segment.match.map((match) => match.key)})`}
            </span>
          )}
        </>
      ),
      selected: constraint.value.includes(segment.name),
      compositeType: segment.compositeType,
      compositeTypeParts: segment.compositeTypeParts,
      typeLabel: segment.typeLabel,
    }));

    const selectedSegmentsOptions = segmentOptions.filter(
      (opt) => opt.selected
    );

    return (
      <div key={constraintIndex} className="flex flex-align-start">
        <div className="flex-expand">
          <Dropdown
            value={selectedSegmentsOptions}
            portaling={true}
            multiple
            clearable
            options={segmentOptions}
            onChange={(
              options: ConstraintDroprownOption[],
              action: ConstraintDropdownAction
            ) =>
              onConstraintChange(
                options,
                action,
                rule,
                ruleIndex,
                constraintIndex
              )
            }
            prefix={constraintDropdownConfig?.selectedType}
          />
        </div>
        <ButtonDelete
          icon="minus"
          onClick={() =>
            handleDeleteConstraint(
              selectedSegmentsOptions.map((option) => ({
                typeLabel: option.typeLabel,
                compositeType: option.compositeType,
                compositeTypeParts: option.compositeTypeParts,
              })) as ConstraintDroprownOption[],
              ruleIndex,
              constraintIndex
            )
          }
          noText
        />
      </div>
    );
  }

  function renderServeDropdown() {
    return (
      <div className={`${styles['rule__footer']} flex`}>
        <div className="flex w-half gap-6 form-groups-no-margin">
          <small className="space-p-t-2">Serve</small>
          <div className="flex-1">
            <Dropdown
              portaling={true}
              value={selectedVariant}
              type="single"
              clearable={false}
              options={variantOptions}
              onChange={handleVariantChange}
            />
          </div>
        </div>
        <div className="flex-1">{!isVariantSet && renderSplits()}</div>
      </div>
    );
  }

  function handleDelete() {
    onDelete(rule, ruleIndex);
  }

  // for up/down arrows to swap the items, turned off at the moment
  // function handleMove(ruleIndex: number, direction: number) {
  //   onMove(ruleIndex, ruleIndex - direction);
  // }

  return (
    <>
      <Card
        item={rule}
        header={{
          title: ruleIndex < 1 ? `if` : `else if `,
          actions: [
            {
              name: 'Move',
              element: <DragAndDropHandler index={ruleIndex} />,
            },
            {
              name: 'Delete',
              element: <ButtonDelete onClick={() => handleDelete()} noText />,
            },
          ],
        }}
        footer={{
          children: renderServeDropdown(),
        }}
      >
        {constraints.map((constraint, constraintIndex) =>
          renderConstraint(
            constraint,
            ruleIndex,
            constraintIndex,
            (constraintDropdownsConfig || [])[constraintIndex],
            handleDelete
          )
        )}
        <ButtonAdd
          className={`${styles['add-constraint']}`}
          size="sm"
          onClick={() => handleAddConstraint(ruleIndex)}
          text="Add constraint"
        />
      </Card>
    </>
  );
}
