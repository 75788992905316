type FormatType = 'long' | 'short';
type PrettyDate = (
  date: Date | string,
  formatType?: FormatType,
  locale?: string
) => string;

const FormatOptions: Record<FormatType, Intl.DateTimeFormatOptions> = {
  long: {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    month: 'long',
    day: '2-digit',
  },
  short: {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  },
};

export const prettyDate: PrettyDate = (
  date,
  formatType = 'long',
  locale = 'en-US'
) => {
  if (typeof date === 'string') {
    date = new Date(date);
  }

  return date.toLocaleDateString(locale, FormatOptions[formatType]);
};
