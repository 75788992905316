import { useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useLinkedEnvironments } from '../../api-client/api-client-hooks';
import { AbsolutePath } from '../../app/routes';
import { FlagTypes } from '../../constants';
import { useFlagListPageController } from '../flag-list-page/controller';

export function useFlagNotFoundPageController() {
  const { projectName, environmentName, flagName } = useParams();
  const navigate = useNavigate();

  const { data: linkedEnvironments } = useLinkedEnvironments(
    projectName!,
    environmentName!,
    flagName!
  );
  const { createFlag, copyFlag } = useFlagListPageController();

  const [flagType, setFlagType] = useState<string>(FlagTypes.BOOLEAN);
  const [showCreateModal, setCreateModalVisibility] = useState(false);

  const create = () => {
    createFlag(flagName as string, flagType);
  };

  const clone = (sourceEnvironmentName: string) => {
    copyFlag(sourceEnvironmentName, environmentName!, flagName!, flagType);
  };

  const leavePage = () => {
    navigate(
      generatePath(AbsolutePath.FLAG_LIST, {
        projectName,
        environmentName,
      })
    );
  };

  const showModal = () => {
    if (!showCreateModal) {
      setCreateModalVisibility(true);
    }
  };

  const hideModal = () => {
    if (showCreateModal) {
      setCreateModalVisibility(false);
    }
  };

  return {
    flagName,
    flagType,
    linkedEnvironments: linkedEnvironments?.environments || [],
    showCreateModal,
    leavePage,
    create,
    clone,
    hideModal,
    setFlagType,
    showModal,
  };
}
