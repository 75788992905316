import { Tag } from '@feature-flags/entities';
import { Dropdown } from 'yarn-design-system-react-components';
import { DropdownActions, TagEvents } from '../../constants';
import { TagController } from '../../pages/tag-list-page/types';
import { DropdownAction, DropdownOption } from '../../types';

type TagSelectProps = {
  tags?: Tag[];
  selectedTags?: Tag[] | null;
  onChange: TagController['changeSelectedTags'];
};

export function TagSelect({
  tags = [],
  selectedTags = [],
  onChange,
}: TagSelectProps) {
  function handleChange(
    options: DropdownOption[],
    { action, option, removedValue }: DropdownAction
  ) {
    if (action === DropdownActions.Clear) {
      return onChange(
        {
          type: TagEvents.Clear,
        },
        selectedTags
      );
    }

    if (action === DropdownActions.CreateOption) {
      return onChange(
        {
          type: TagEvents.ItemCreated,
          item: option,
        },
        selectedTags
      );
    }

    if (
      action === DropdownActions.RemoveValue ||
      action === DropdownActions.DeselectOption
    ) {
      return onChange(
        {
          type: TagEvents.ItemRemoved,
          item: option || removedValue,
        },
        selectedTags
      );
    }

    if (action === DropdownActions.SelectOption) {
      return onChange(
        {
          type: TagEvents.ItemAttached,
          item: option,
        },
        selectedTags
      );
    }
  }

  const options = tags.map((tag) => ({
    value: tag,
    label: tag,
    selected: selectedTags?.includes(tag),
  }));
  const value = selectedTags?.map((tag) => ({ value: tag, label: tag }));

  return (
    <div className="row" data-testid="tag-select">
      <h5>Tags</h5>
      <div className="grid grid-cols-3 auto-rows-fr gap-4 space-m-b-5">
        <Dropdown
          value={value}
          options={options}
          creatable
          multiple
          clearable
          onChange={handleChange}
        />
      </div>
    </div>
  );
}
