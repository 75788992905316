import { AuditLogRecord, EnvironmentRecord } from '@feature-flags/entities';
import { generatePath } from 'react-router-dom';
import { Dropdown, Icon } from 'yarn-design-system-react-components';
import { AbsolutePath } from '../../../app/routes';
import { ButtonSave, Loader } from '../../../atoms';
import { prettyDate, timeSince } from '../../../helpers/datetime';
import { DropdownOption } from '../../../types';
import { FlagCopyProps } from './types';
import styles from '../flag.module.scss';
import { useEffect } from 'react';

export const FlagCopy: React.FC<FlagCopyProps> = ({
  flagName,
  environments,
  defaultEnv,
  sourceEnv,
  destinationEnv,
  versionsToDiff,
  isLoading,
  onChangeSourceEnv,
  onChangeDestinationEnv,
  onSuccess,
  copyFlag,
}) => {
  useEffect(() => {
    if (defaultEnv && (!sourceEnv || sourceEnv === destinationEnv)) {
      onChangeSourceEnv(sourceEnv || defaultEnv);
      onChangeDestinationEnv(undefined);
    }
  }, [
    sourceEnv,
    destinationEnv,
    defaultEnv,
    onChangeSourceEnv,
    onChangeDestinationEnv,
  ]);

  if (!sourceEnv || !versionsToDiff) {
    return <Loader />;
  }

  if (!environments || !flagName || !defaultEnv) {
    console.error(
      'No environments or defaultEnv or flagName props defined for the Flag Copy component.',
      environments,
      defaultEnv,
      flagName
    );
    return null;
  }

  const [lastSourceFlagVersion, lastDestinationFlagVersion] = versionsToDiff;

  const sourceEnvOptions = environments.map((env) => ({
    value: env.name,
    label: env.name,
    isSelected: env.name === sourceEnv,
  }));

  const destinationEnvOptions = sourceEnvOptions
    .filter((environment) => environment.value !== sourceEnv)
    .map((env) => ({ ...env, isSelected: env.value === destinationEnv }));

  const isCopyPossible = Boolean(destinationEnv && lastSourceFlagVersion);

  const handleSubmit = async () => {
    if (isCopyPossible && typeof destinationEnv !== 'undefined') {
      const res = await copyFlag(sourceEnv, destinationEnv, flagName);
      if (res && typeof onSuccess !== 'undefined') {
        onSuccess();
      }
    }
  };

  const renderVersionDate = (
    flagVersion: AuditLogRecord | undefined,
    envName: EnvironmentRecord['name'] | undefined
  ) => {
    if (!flagVersion) {
      return (
        <small className="opacity-50">
          {envName ? 'This flag does not exists in the environment.' : ''}
        </small>
      );
    }
    return (
      <small>
        <span className="opacity-50">Flag version:</span>{' '}
        <a
          href={generatePath(AbsolutePath.FLAG_DETAILS, {
            projectName: flagVersion.projectName,
            environmentName: flagVersion.environmentName,
            flagName: flagVersion.name,
          })}
          target="_blank"
          rel="noreferrer"
        >
          {prettyDate(flagVersion.createdAt, 'short')}
        </a>{' '}
        <span className="opacity-50">({timeSince(flagVersion.createdAt)})</span>
      </small>
    );
  };

  return (
    <>
      <p>
        You're copying this version of the <strong>{flagName}</strong> flag to
        other environments.
      </p>

      <div className={`${styles['flag-copy-selectors']} flex gap-4`}>
        <div className="flex-1">
          <Dropdown
            value={sourceEnvOptions.find((option) => option.isSelected)}
            label="Source environment version"
            options={sourceEnvOptions}
            clearable={false}
            selectedOptionsVisible={false}
            onChange={(selectedOption: DropdownOption) =>
              onChangeSourceEnv(selectedOption.value)
            }
          />
          {renderVersionDate(lastSourceFlagVersion, sourceEnv)}
        </div>
        <div className="space-p-t-5">
          <Icon
            name="arrow-right"
            size="md"
            status="default"
            data-testid="flag-copy-direction-icon"
            className="space-m-t-1"
          />
        </div>
        <div className="flex-1">
          <Dropdown
            value={
              destinationEnvOptions.find((option) => option.isSelected) || ''
            }
            label="Destination environment version"
            options={destinationEnvOptions}
            clearable={false}
            // multiple
            // clearable
            selectedOptionsVisible={false}
            onChange={(selectedOption: DropdownOption) =>
              onChangeDestinationEnv(selectedOption.value)
            }
          />
          {renderVersionDate(lastDestinationFlagVersion, destinationEnv)}
        </div>
      </div>
      <footer className="space-m-t-6 flex">
        <div className="flex-1">
          <div className="flex items-center gap-2">
            <div>
              <Icon name="attention-big" size="md" status="warning" />
            </div>
            <div>
              <strong>{flagName}</strong> Flag version will be updated in the
              destination environment.
            </div>
          </div>
        </div>
        <div className="flex-1 text-right">
          <ButtonSave
            onClick={handleSubmit}
            isLoading={isLoading}
            type="primary"
            disabled={!isCopyPossible}
          >
            Copy
          </ButtonSave>
        </div>
      </footer>
    </>
  );
};
