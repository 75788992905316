import { Dropdown } from 'yarn-design-system-react-components';
import { DropdownOption } from '../../../types';
import { FlagFilterKeys } from '../../../constants';
import { ButtonAdd, ButtonFilter, Input } from '../../../atoms';
import { FlagFilterProps } from '../types';
import styles from './../flag.module.scss';

export const FlagFilter: React.FC<FlagFilterProps> = ({
  flags,
  deserializedFilterParams,
  deserializedSearchParams,
  setFilterBySearchText,
  showAdvancedFilterState,
  toggleFilters,
  filterDropdownsOptionsState,
  setFilterByParams,
  modal,
}) => {
  return (
    <>
      <div className="flex space-p-v-3" data-testid="flag-filter">
        <ButtonFilter
          type="secondary"
          active={showAdvancedFilterState}
          onClick={toggleFilters}
        />
        <Input
          placeholder="Search..."
          type="text"
          value={deserializedSearchParams.search?.[0] || ''}
          onChange={setFilterBySearchText}
          className="flex-expand space-m-b-0 space-m-h-3"
          data-testid="flags-search-control"
        />
        <ButtonAdd
          type="primary"
          onClick={modal.create.show}
          text="New flag"
          data-testid="create-flag-button"
        />
      </div>
      {flags && showAdvancedFilterState && (
        <div
          className={`space-m-3 space-m-t-2 space-p-3 ${styles['advanced-filter-section']}`}
          data-testid="advanced-filter"
        >
          <Dropdown
            value={{
              value: deserializedFilterParams[FlagFilterKeys.Status],
              label: deserializedFilterParams[FlagFilterKeys.Status],
            }}
            label="Status"
            options={filterDropdownsOptionsState?.status}
            onChange={(selectedOption: DropdownOption) =>
              setFilterByParams({
                filterProperty: FlagFilterKeys.Status,
                filterValue: selectedOption ? [selectedOption.value] : [],
              })
            }
          />
          <Dropdown
            value={(
              (deserializedFilterParams[FlagFilterKeys.Type] as string[]) || []
            ).map((typeValue) => ({
              value: typeValue,
              label: typeValue,
            }))}
            label="Type"
            options={filterDropdownsOptionsState?.type}
            onChange={(selectedOptions: DropdownOption[]) =>
              setFilterByParams({
                filterProperty: FlagFilterKeys.Type,
                filterValue: selectedOptions.map((option) => option.value),
              })
            }
            multiple
          />
          <Dropdown
            value={(
              (deserializedFilterParams[FlagFilterKeys.Tag] as string[]) || []
            ).map((typeValue) => ({
              value: typeValue,
              label: typeValue,
            }))}
            label="Tag"
            options={filterDropdownsOptionsState?.tag}
            onChange={(selectedOptions: DropdownOption[]) =>
              setFilterByParams({
                filterProperty: FlagFilterKeys.Tag,
                filterValue: selectedOptions.map((option) => option.value),
              })
            }
            multiple
          />
        </div>
      )}
    </>
  );
};
