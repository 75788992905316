const transformations: {
  [instanceType: string]: {
    serialize: (value: any) => { instanceType: string; value: any };
    deserialize: (value: any) => any;
  };
} = {
  set: {
    serialize: (value: any) => ({
      instanceType: 'set',
      value: Array.from(value),
    }),
    deserialize: (value: any) => new Set(value),
  },
  map: {
    serialize: (value: any) => ({
      instanceType: 'map',
      value: Array.from(value.entries()),
    }),
    deserialize: (value: any) => new Map(value),
  },
};

export function getObjectDeepClone<T>(obj: T): T {
  try {
    const stringified = JSON.stringify(
      obj,
      (_, value) => {
        if (value instanceof Set) {
          return transformations.set.serialize(value);
        } else if (value instanceof Map) {
          return transformations.map.serialize(value);
        } else {
          return value;
        }
      },
      2
    );

    const cloned = JSON.parse(stringified, (_, value) => {
      const instanceType = value['instanceType'];

      if (instanceType && instanceType in transformations) {
        return transformations[instanceType].deserialize(value.value);
      } else {
        return value;
      }
    });

    return cloned;
  } catch {
    return obj;
  }
}
