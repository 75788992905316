import { Button } from './button';
import { ButtonProps } from './types';

export const ButtonFilter: React.FC<ButtonProps> = (props) => {
  const DEFAULT_TEXT = props.disabled ? 'Filter' : 'Filter';
  const buttonText = props.children || props.text || DEFAULT_TEXT;
  return (
    <Button icon="filter" data-testid="button-filter" {...props}>
      {buttonText}
    </Button>
  );
};
