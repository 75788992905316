import { generatePath } from 'react-router-dom';
import { Divider } from 'yarn-design-system-react-components';
import { Button, ButtonSave } from '../../../atoms';
import { AbsolutePath } from '../../../app/routes';
import { FlagCloneProps } from './types';

export const FlagClone: React.FC<FlagCloneProps> = ({
  projectName,
  environmentName,
  linkedEnvironments,
  flagName,
  flagType,
  copyFlag,
  createFlag,
  queryState,
}) => {
  const { params, isLoading } = queryState;

  if (
    !linkedEnvironments ||
    !flagName ||
    !flagType ||
    !projectName ||
    !environmentName
  ) {
    console.error(
      `Some props of the FlagClone component are missing: 
      linkedEnvironments (${linkedEnvironments}), 
      flagName (${flagName}), 
      flagType (${flagType}), 
      projectName (${projectName}), 
      environmentName (${environmentName}) should be defined!`
    );
    return null;
  }

  const isGoingToBeCreated = !params.sourceEnvironmentName;
  const isGoingToBeCloned = (envName: string) =>
    params.sourceEnvironmentName === envName;

  return (
    <>
      <p data-testid="modal-description">
        The {linkedEnvironments.length > 1 ? 'flags' : 'flag'} with the same
        name <strong>{flagName}</strong>{' '}
        {linkedEnvironments.length > 1 ? 'have' : 'has'} been found within the{' '}
        <strong>{projectName?.toUpperCase()}</strong> project. Would you like to
        clone the flag data from other environments?
      </p>
      <ul className="clearfix">
        {linkedEnvironments.map((sourceEnvironmentName) => (
          <li key={sourceEnvironmentName} className="space-m-v-2">
            <a
              href={generatePath(AbsolutePath.FLAG_DETAILS, {
                projectName,
                environmentName: sourceEnvironmentName,
                flagName,
              })}
              target="_blank"
              rel="noreferrer"
              className="inline-block space-p-v-1"
            >
              {sourceEnvironmentName}
            </a>
            <div className="pull-right">
              <Button
                type="secondary"
                size="sm"
                onClick={() =>
                  copyFlag(
                    sourceEnvironmentName,
                    environmentName,
                    flagName,
                    flagType
                  )
                }
                isLoading={
                  isLoading && isGoingToBeCloned(sourceEnvironmentName)
                }
                data-testid="modal-creation-clone-flag-button"
              >
                Clone data
              </Button>
            </div>
          </li>
        ))}
      </ul>
      <Divider type="horizontal" />
      <footer className="space-m-t-4 text-right">
        <ButtonSave
          onClick={() => createFlag(flagName, flagType)}
          isLoading={isLoading && isGoingToBeCreated}
          type="primary"
          data-testid="modal-creation-blank-flag-button"
        >
          Create a blank flag
        </ButtonSave>
      </footer>
    </>
  );
};
