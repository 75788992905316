import {
  List,
  ListItem,
  ListItemLabel,
} from 'yarn-design-system-react-components';
import { Loader, ButtonAdd } from '../../atoms';
import { ProjectListTemplateProps } from '../../types';
import { Header, ModalCreation } from '../../organisms';

export const ProjectListTemplate: React.FC<ProjectListTemplateProps> = ({
  data: { projects },
  controller: {
    projectListController: { goToProject, createProject },
  },
  modal,
  isLoaded,
}) => {
  return (
    <>
      <Header />
      <main data-testid="project-list-template">
        {isLoaded ? (
          <section className="container container-x-padding fill">
            <header className="flex">
              <h3 className="flex-1">Projects</h3>
              <ButtonAdd
                type="primary"
                className="space-m-t-4"
                data-testid="create-project-button"
                onClick={modal.create.show}
                text="New project"
              />
            </header>
            <List
              type="ul"
              data-testid="project-list"
              className="w-auto negative-margin"
            >
              {projects?.map((project) => (
                <ListItem
                  key={project.name}
                  onClick={() => goToProject(project)}
                  interactive
                >
                  <ListItemLabel>{project.name}</ListItemLabel>
                </ListItem>
              ))}
            </List>
            {modal.state.create && (
              <ModalCreation
                onSubmit={createProject}
                placeholder="Project name"
                title="Create a new project"
              ></ModalCreation>
            )}
          </section>
        ) : (
          <Loader />
        )}
      </main>
    </>
  );
};
