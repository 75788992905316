import { useState } from 'react';
import { Brands } from '../../constants';
import { VisualizationListTemplate } from '../../templates';
import { FilterProps } from '../../types';
import { useVisualizationPageController } from './controller';

export function VisualizationListPage() {
  const {
    environments,
    environmentName,
    flagsData,
    isLoadingFlags,
    isLoadingSegments,
    projectName,
    segmentsData,
  } = useVisualizationPageController();

  const [flagsFilterText, setFlagsFilterText] = useState<string>('');
  const updateFilterFlags = (text: string) => {
    setFlagsFilterText(text.toLowerCase());
  };

  const [countryFilterText, setCountryFilterText] = useState<string>('');
  const updateFilterCountries = (text: string) => {
    setCountryFilterText(text.toLowerCase());
  };

  const filters: FilterProps[] = [
    {
      name: 'Flag',
      placeholder: 'Filter by Flag...',
      value: flagsFilterText,
      onChange: updateFilterFlags,
    },
    {
      name: 'Country',
      placeholder: 'Filter by Country...',
      value: countryFilterText,
      onChange: updateFilterCountries,
    },
  ];

  const flags = (flagsData || []).filter((flag) =>
    flag.name.toLowerCase().includes(flagsFilterText)
  );

  const segments = (segmentsData || []).filter(
    (segment) =>
      segment.name.toLowerCase().includes(countryFilterText) ||
      segment.name === Brands.adidas ||
      segment.name === Brands.reebok
  );

  return (
    <VisualizationListTemplate
      engineId={`${projectName}-${environmentName}`}
      environments={environments}
      filters={filters}
      flags={flags}
      isLoadingFlags={isLoadingFlags}
      isLoadingSegments={isLoadingSegments}
      segments={segments}
    />
  );
}
