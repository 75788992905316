import React, { useContext } from 'react';
import { Button, Icon } from 'yarn-design-system-react-components';
import { DragAndDropContextValue, DragAndDropTogglerProps } from './types';
import { DragAndDropContext } from './drag-n-drop-context';
import styles from './drag-n-drop.module.scss';

/**
 * Render the Button-Toggler to turn on/off DnD mode,
 * (this is required component when you specify hasToggler property as true)
 * @component
 * @param {string[]} text Button text for the ON/OFF mode
 * @param {(text: string) => JSX.Element} render Function to render the custom button
 * @example
 * <DragAndDropToggler text={["Turn on", "Turn off"]} render={renderFunction} />
 */

export const DragAndDropToggler: React.FC<DragAndDropTogglerProps> = ({
  text,
  render,
}) => {
  const { state, onToggle, hasToggler } = useContext(
    DragAndDropContext
  ) as DragAndDropContextValue;
  const togglerText = text[Number(state.draggable)];
  const defaultButton = (
    <Button>
      <Icon name="move" size="md" status="default" />
      <span>{togglerText}</span>
    </Button>
  );
  return hasToggler ? (
    <span ref={state.toggler} className={styles.toggler} onClick={onToggle}>
      {render ? render(togglerText) : defaultButton}
    </span>
  ) : null;
};
