import { ModalConfirm } from '../../../organisms';
import { FlagDeletionProps } from './types';

export const FlagDeletion: React.FC<FlagDeletionProps> = ({
  flagState,
  deleteFlag,
}) => {
  return (
    <ModalConfirm onSubmit={deleteFlag}>
      Do you want to delete {flagState!.name} flag?
    </ModalConfirm>
  );
};
