import React from 'react';
import { CardHeaderProps } from './types';
import {
  Highlight as YarnHighlight,
  TextField as YarnTextField,
  Icon as YarnIcon,
} from 'yarn-design-system-react-components';
import { detectTheHexColor } from '../../helpers/detectHex';
import { Button } from '../../atoms/button';
import styles from './card.module.scss';

export const CardHeader: React.FC<CardHeaderProps> = ({
  item,
  title,
  icon,
  placeholder,
  actions,
  onChange,
}) => {
  const renderTitle = () => {
    if (onChange) {
      return (
        <YarnHighlight
          matcher={{
            warning: function warning(value: string) {
              return value === '';
            },
          }}
          value={title}
        >
          <YarnTextField
            className={styles['form-group']}
            type="text"
            placeholder={placeholder}
            value={title}
            onChange={onChange}
            required
          />
        </YarnHighlight>
      );
    } else {
      return <span>{title}</span>;
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderPrefix = () => {
    if (!item || !item.value || typeof item.value !== 'string') {
      return null;
    }
    if (icon === 'hex') {
      const hexValue = item.value.replace(/['"`]/g, '');
      return (
        detectTheHexColor(hexValue) && (
          <div
            className={`${styles['hex']}`}
            style={{ backgroundColor: hexValue }}
          ></div>
        )
      );
    } else if (icon) {
      return <YarnIcon name={icon} />;
    }
  };

  const renderActions = () => {
    return actions?.map((action, index) => (
      <React.Fragment key={action.name + index}>
        {action.element || (
          <Button
            icon={action.icon}
            onClick={action.onClick!}
            data-testid={`button-${action.icon}`}
            noText
          />
        )}
      </React.Fragment>
    ));
  };

  return (
    <div className={`${styles['card-header']}`}>
      <div className={styles['card-title']}>{renderTitle()}</div>
      <div className={styles['card-actions']}>{renderActions()}</div>
    </div>
  );
};
