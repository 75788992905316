import { useState } from 'react';
import { Segment } from '@feature-flags/entities';
import { Loader } from '../../atoms';
import { SegmentListTemplate } from '../../templates';
import { useSegmentListPageController } from './controller';

export function SegmentListPage() {
  const {
    environments,
    isLoading,
    segmentsData,
    navigateToSegment,
    saveNewSegment,
  } = useSegmentListPageController();

  const [filterText, setFilterText] = useState<string>('');
  const segments = segmentsData
    ? segmentsData.filter(
        (segment) =>
          !filterText ||
          segment.name.toLowerCase().includes(filterText.toLowerCase())
      )
    : undefined;

  const filterItems = (text: string) => {
    setFilterText(text);
  };

  const handleItemSelect = (segment: Segment) => {
    return navigateToSegment(segment.name);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <SegmentListTemplate
      environments={environments || []}
      filterText={filterText}
      filterItems={filterItems}
      segments={segments || []}
      handleItemSelect={handleItemSelect}
      saveNewSegment={saveNewSegment}
    />
  );
}
