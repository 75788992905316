import { useModalContext } from './context';
import { ModalProps } from './types';
import { Button } from '../../atoms';
import { Modal } from '../../organisms';

export const ModalConfirm: React.FC<ModalProps> = (props) => {
  const modal = useModalContext();
  const { onSubmit, onClose, footer, ...rest } = props;

  if (!rest.title) {
    rest.title = `Are you sure?`;
  }

  function handleClose() {
    if (onClose) {
      onClose();
    }
    modal.confirm.hide();
  }

  function handleSubmit() {
    onSubmit!();
    handleClose();
  }

  return (
    <Modal
      {...rest}
      onClose={handleClose}
      footer={
        <>
          <Button onClick={handleClose} text="Cancel" />
          <Button type="primary" onClick={handleSubmit} text="Confirm" />
        </>
      }
    >
      {props.children}
    </Modal>
  );
};
