import { useState } from 'react';
import { QueryState, QueryParams, QueryStateHook } from './types';

export const queryDefaultState: QueryState = {
  data: undefined,
  error: undefined,
  isLoading: false,
  queryName: '',
};

export const queryDefaultParams: QueryParams = {};

export function useQueryState(
  params: QueryParams = queryDefaultParams,
  state: QueryState = queryDefaultState
): QueryStateHook {
  const [queryParams, setQueryParams] = useState<QueryParams>(params);
  const [queryState, setQueryState] = useState<QueryState>(state);

  const setParams: QueryStateHook['setParams'] = (params) => {
    setQueryParams((prevState) => ({
      ...prevState,
      ...params,
    }));
  };

  const setError: QueryStateHook['setError'] = (error) => {
    setQueryState((prevState) => ({
      ...prevState,
      error,
    }));
  };

  const start: QueryStateHook['start'] = (
    queryName,
    filledQueryParams = {}
  ) => {
    setQueryParams(filledQueryParams); // refresh the query params with updated values
    setQueryState((prevState) => ({
      ...queryState, // clear all the state with the defaults
      data: prevState.data, // always keep previosly received data for rendering conditions
      isLoading: true,
      queryName,
    }));
  };

  const finish: QueryStateHook['finish'] = (data) => {
    setQueryState((prevState) => ({
      ...prevState,
      data,
      isLoading: false,
    }));
  };

  const clear: QueryStateHook['clear'] = () => {
    setQueryParams(queryDefaultParams);
    setQueryState(queryDefaultState);
  };

  return {
    ...queryState,
    params: queryParams,
    setParams,
    setError,
    start,
    finish,
    clear,
  };
}
