import React, { createContext, useReducer, useContext } from 'react';
import { ModalState, ModalContextValue, ModalAction, ModalApi } from './types';
import { ModalTypes, ModalActions } from './constants';

export const ModalContext = createContext<ModalContextValue | null>(null);

const modalState = (): ModalState => ({
  [ModalTypes.DEFAULT]: false,
  [ModalTypes.CREATE]: false,
  [ModalTypes.CONFIRM]: false,
  [ModalTypes.HISTORY]: false,
  [ModalTypes.COPY]: false,
});

const modalFieldsRef = {
  name: React.createRef<HTMLInputElement>(),
};

const modalReducer = (state: ModalState, action: ModalAction) => {
  switch (action.type) {
    case ModalActions.SHOW: {
      return {
        ...state,
        [action.payload]: true,
      };
    }
    case ModalActions.HIDE: {
      return {
        ...state,
        [action.payload]: false,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export const ModalProvider: React.FC = ({ children }) => {
  // init state
  const initialState = modalState();
  const [state, dispatch] = useReducer(modalReducer, initialState);

  // context api
  const actions = Object.values(ModalTypes).reduce((types, modal) => {
    return (types = {
      ...types,
      [modal]: Object.values(ModalActions).reduce((actions, action) => {
        return (actions = {
          ...actions,
          [action]: () => {
            dispatch({
              type: action,
              payload: modal,
            });
          },
        });
      }, {}),
    });
  }, {} as ModalApi);

  const api: ModalContextValue = {
    state,
    dispatch,
    fields: modalFieldsRef,
    ...actions,
  };

  return <ModalContext.Provider value={api}>{children}</ModalContext.Provider>;
};

export function useModalContext() {
  const context = useContext(ModalContext);
  if (context === null) {
    throw new Error('useModalContext must be used within a ModalProvider');
  }
  return context;
}
