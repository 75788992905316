import React, { useContext } from 'react';
import { DragAndDropContextValue, DragAndDropHandlerProps } from './types';
import { DragAndDropContext } from './drag-n-drop-context';
import { Button, Icon } from 'yarn-design-system-react-components';
import styles from './drag-n-drop.module.scss';

/**
 * Render the Drag Handler (for example, to wrap some icon or a small button)
 * (this is required component when you specify hasHandler property as true)
 * @component
 * @param {number} index Index of the item in the list array
 * @param {(icon: JSX.Element|string) => JSX.Element} render Function to render the handler with icon
 * @example
 * <DragAndDropHandler index={index} render={renderFunction} />
 */

export const DragAndDropHandler: React.FC<DragAndDropHandlerProps> = ({
  index,
  render,
}) => {
  const { state, hasHandler } = useContext(
    DragAndDropContext
  ) as DragAndDropContextValue;
  const isVisible = state.draggable ? `` : styles.hiding;
  const iconJSX = <Icon name="move" size="md" status="default" />;
  const defaultButton = (
    <Button behavior="button" type="icon" aria-label="move">
      {iconJSX}
    </Button>
  );
  return hasHandler && state.handlers.length > 1 ? (
    <span
      className={`${styles.handler} ${isVisible}`}
      ref={state.handlers[index]}
    >
      {render ? render(iconJSX) : defaultButton}
    </span>
  ) : null;
};
