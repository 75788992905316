import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import type { XYCoord, Identifier } from 'dnd-core';
import {
  DragAndDropContextValue,
  DragItemProps,
  DragAndDropItemProps,
} from './types';
import { DragAndDropContext } from './drag-n-drop-context';
import styles from './drag-n-drop.module.scss';

/**
 * Render Drag-and-Drop item (to wrap initial item with the DnD functionality)
 * @component
 * @param {number|string} id Automated generated, need to remember the items on rerender
 * @param {number} index For the sorting list
 * @param {function} moveCard Callback for DnD events
 * @example
 * <DragAndDropItem id={id} key={id} index={index} moveCard={moveCard} />
 */

export const DragAndDropItem: React.FC<DragAndDropItemProps> = ({
  children,
  id,
  index,
  moveCard,
}) => {
  const { state, hasHandler } = React.useContext(
    DragAndDropContext
  ) as DragAndDropContextValue;
  const previewRef = useRef<HTMLLIElement>(null);
  const dragRef = hasHandler ? state.handlers[index] : previewRef;

  const [{ handlerId }, drop] = useDrop<
    DragItemProps,
    void,
    { handlerId: Identifier | null }
  >({
    accept: 'item',
    collect: (monitor: any) => ({
      handlerId: monitor.getHandlerId(),
    }),
    hover(item: DragItemProps, monitor) {
      if (!previewRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = previewRef.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'item',
    item: () => {
      return { index, id };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(dragRef);
  drop(preview(previewRef));

  return (
    <li
      ref={previewRef}
      data-testid="dnd-listitem"
      className={`${styles.item} ${!hasHandler ? styles.item_veiled : ''} ${
        isDragging ? styles.item_dragging : ''
      }`}
      data-handler-id={handlerId}
    >
      {children}
    </li>
  );
};
