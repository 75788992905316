import { useEffect, useState } from 'react';
import {
  generatePath,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import {
  useApiClient,
  useEnvironments,
  useFlags,
  useTagsWithFlags,
} from '../../api-client/api-client-hooks';
import { AbsolutePath } from '../../app/routes';
import { useNotify } from '../../atoms';
import { FlagStatusMessages } from '../../constants';
import { FlagListController, FlagListState } from '../../types';
import { useQueryState } from '../../helpers/query-state';
import {
  buildAdvancedFilterDropdowns,
  getParamsFromUrl,
  filterConditions,
} from './utils';

export function useFlagListPageController() {
  // API CLIENT GROUP
  const { projectName, environmentName } = useParams();

  const [urlParams, setUrlParams] = useSearchParams();
  const { createFlag, updateFlag, copyFlag, getFlagLinkedEnvironments } =
    useApiClient();
  const navigate = useNavigate();
  const notify = useNotify();

  // DATA GROUP
  const { data: flagsData, refetch: flagsRefetch } = useFlags(
    projectName!,
    environmentName!
  );
  const { data: environmentData } = useEnvironments(projectName!);
  const { data: tagsData } = useTagsWithFlags(projectName!, environmentName!);

  // filters params data subgroup
  const deserializedFilterParams = getParamsFromUrl(urlParams, {
    omit: ['search'],
  });
  const deserializedSearchParams = getParamsFromUrl(urlParams, {
    pick: ['search'],
  });
  const isNoAdvancedFilterParametersSelected = !Object.keys(
    deserializedFilterParams
  ).length;
  const [searchValue] = deserializedSearchParams.search || [''];

  // STATES GROUP
  const [showAdvancedFilterState, setShowAdvancedFilterState] = useState<
    FlagListState['showAdvancedFilterState']
  >(Boolean(Object.keys(deserializedFilterParams).length));

  const [filterDropdownsOptionsState, updateFilterDropdownsOptionsState] =
    useState<FlagListState['filterDropdownsOptionsState']>(null);

  const flagListQueryState = useQueryState({
    flagName: '',
    flagType: '',
    sourceEnvironmentName: '',
  });

  // WATCHERS GROUP
  useEffect(() => {
    if (tagsData && showAdvancedFilterState && !filterDropdownsOptionsState) {
      const dropdownsOptions = buildAdvancedFilterDropdowns(
        flagsData,
        tagsData.map((tag) => tag.value)
      );
      updateFilterDropdownsOptionsState(dropdownsOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagsData]);

  // METHODS GROUP
  const createThis: FlagListController['createFlag'] = async (
    flagName,
    flagType
  ) => {
    flagListQueryState.start('createFlag', { flagName, flagType });
    let responseData;

    try {
      let variants;
      switch (flagType) {
        case 'string':
          variants = [
            { name: 'off', value: 'False' },
            { name: 'on', value: 'True' },
          ];
          break;
        case 'number':
          variants = [
            { name: 'off', value: 0 },
            { name: 'on', value: 1 },
          ];
          break;
        default:
          variants = [
            { name: 'off', value: false },
            { name: 'on', value: true },
          ];
          break;
      }

      const newFlag = {
        name: flagName,
        disabled: true,
        defaultVariant: 'off',
        rules: [],
        variants,
      };

      responseData = await createFlag(projectName!, environmentName!, newFlag);
      await flagsRefetch();
      notify.success(`${flagName} ${FlagStatusMessages.CREATED}`);
    } catch (error) {
      notify.unknown(error, {
        UniqueViolationError: `Flag "${flagName}" already exists!`,
      });
      flagListQueryState.setError(error);
    }

    flagListQueryState.finish(responseData);

    if (responseData) {
      const newFlagPath = generatePath(AbsolutePath.FLAG_DETAILS, {
        projectName,
        environmentName,
        flagName: responseData.name,
      });
      navigate(newFlagPath);
    }

    return responseData;
  };

  const checkThisBeforeCreate: FlagListController['checkFlagBeforeCreate'] =
    async (flagName, flagType) => {
      flagListQueryState.start('checkFlagBeforeCreate', { flagName, flagType });
      let responseData;
      let isFlagReadyToBeCreated = true;

      try {
        responseData = await getFlagLinkedEnvironments(
          projectName!,
          environmentName!,
          flagName
        );
        isFlagReadyToBeCreated = responseData.environments.length === 0;
      } catch (error) {
        notify.unknown(error);
        flagListQueryState.setError(error);
      }

      flagListQueryState.finish(responseData);

      return isFlagReadyToBeCreated;
    };

  const disableThis: FlagListController['disableFlag'] = async (
    flagName,
    flagDisabled
  ) => {
    flagListQueryState.start('disableFlag');
    let responseData;

    try {
      responseData = await updateFlag(
        projectName!,
        environmentName!,
        flagName,
        { disabled: flagDisabled }
      );
      await flagsRefetch();
      notify.success(FlagStatusMessages[flagDisabled ? 'DISABLED' : 'ENABLED']);
    } catch (error) {
      notify.unknown(error);
      flagListQueryState.setError(error);
    }

    flagListQueryState.finish(responseData);
    return responseData;
  };

  const copyThis: FlagListController['copyFlag'] = async (
    sourceEnvironmentName,
    targetEnvironmentName,
    flagName,
    flagType
  ) => {
    flagListQueryState.start('copyFlag', {
      sourceEnvironmentName,
      targetEnvironmentName,
      flagName,
      flagType,
    });
    let responseData;

    try {
      responseData = await copyFlag(
        projectName!,
        sourceEnvironmentName,
        targetEnvironmentName,
        flagName
      );
      await flagsRefetch();
      notify.success(`${flagName} ${FlagStatusMessages.COPIED}`);
    } catch (error) {
      notify.unknown(error);
      flagListQueryState.setError(error);
    }

    flagListQueryState.finish(responseData);

    if (responseData) {
      const newFlagPath = generatePath(AbsolutePath.FLAG_DETAILS, {
        projectName,
        environmentName,
        flagName: responseData.name,
      });
      navigate(newFlagPath);
    }

    return responseData;
  };

  // EVENTS GROUP
  const goToFlag: FlagListController['goToFlag'] = (flag, event) => {
    const flagStatusSwitchNode = event?.currentTarget?.querySelector(
      '[data-switch="flag-status"]'
    );

    if (flagStatusSwitchNode?.contains(event.target as Node)) {
      return;
    }

    const flagRoute = generatePath(AbsolutePath.FLAG_DETAILS, {
      projectName,
      environmentName,
      flagName: flag.name,
    });

    navigate(flagRoute);
  };
  const setFilterBySearchText: FlagListController['setFilterBySearchText'] = (
    text
  ) => {
    setUrlParams(
      text
        ? { ...deserializedFilterParams, search: text }
        : { ...deserializedFilterParams, search: [] }
    );
  };
  const setFilterByParams: FlagListController['setFilterByParams'] = ({
    filterProperty,
    filterValue,
  }) => {
    const newQuery = {
      ...deserializedSearchParams,
      ...deserializedFilterParams,
      [filterProperty]: filterValue,
    };
    setUrlParams(newQuery);
  };
  const toggleFilters: FlagListController['toggleFilters'] = () => {
    if (!showAdvancedFilterState && !filterDropdownsOptionsState && flagsData) {
      const dropdownsOptions = buildAdvancedFilterDropdowns(
        flagsData,
        tagsData?.map((tag) => tag.value)
      );
      updateFilterDropdownsOptionsState(dropdownsOptions);
    }
    setShowAdvancedFilterState(!showAdvancedFilterState);
  };
  const isAdvancedFiltersApplied: FlagListController['isAdvancedFiltersApplied'] =
    (flag) => {
      const result = Object.keys(deserializedFilterParams).every(
        (filterParam) => {
          const paramValue = deserializedFilterParams[filterParam];
          const conditionToApply = filterConditions[filterParam];

          return paramValue && Boolean(conditionToApply)
            ? conditionToApply(flag, paramValue)
            : false;
        }
      );

      return result;
    };

  // MODIFIERS GROUP
  const flagsFiltered = flagsData
    ? flagsData.filter((flag) => {
        const noFiltersApplied =
          !searchValue && isNoAdvancedFilterParametersSelected;
        const flagNameSatisfiesFilterConditions =
          flag.name.toLowerCase().includes(searchValue.toLowerCase()) &&
          isAdvancedFiltersApplied(flag);

        return noFiltersApplied || flagNameSatisfiesFilterConditions;
      })
    : undefined;

  return {
    projectName,
    environmentName,
    flags: flagsFiltered,
    environments: environmentData,
    tags: tagsData,
    deserializedSearchParams,
    deserializedFilterParams,
    showAdvancedFilterState,
    filterDropdownsOptionsState,
    flagListQueryState,
    createFlag: createThis,
    checkFlagBeforeCreate: checkThisBeforeCreate,
    disableFlag: disableThis,
    copyFlag: copyThis,
    goToFlag,
    setFilterBySearchText,
    setFilterByParams,
    toggleFilters,
    isAdvancedFiltersApplied,
  };
}
