import { useEffect, useState } from 'react';
import { PropertyMatch, SegmentRecord } from '@feature-flags/entities';
import {
  ButtonDelete,
  ButtonHelp,
  ButtonSave,
  InputDescription,
  Loader,
} from '../../../atoms';
import { ButtonList, MatchList } from '../../../molecules';
import { Modal, ModalConfirm, useModalContext } from '../../../organisms';
import { CollectionEvents } from '../../../constants';
import { CollectionEvent } from '../../../types';
import { SegmentDetailsProps } from './types';

export function SegmentDetails({
  segmentData,
  usageInFlags,
  deleteSegment,
  getUsageInFlags,
  saveSegment,
}: SegmentDetailsProps) {
  const modal = useModalContext();
  const [segment, setSegment] = useState<SegmentRecord>();

  useEffect(() => {
    setSegment(segmentData);
  }, [segmentData]);

  const setDescription = (description: string) => {
    if (!segment) {
      return;
    }

    setSegment({
      ...segment,
      description,
    });
  };

  const setMatches = (event: CollectionEvent<PropertyMatch>) => {
    if (!segment) {
      return;
    }

    if (event.type === CollectionEvents.ItemAdded) {
      const newMatch: PropertyMatch = {
        ...event.item,
      };
      const match = [...segment.match, newMatch];

      setSegment({
        ...segment,
        match: [...match],
      });
    } else if (event.type === CollectionEvents.ItemRemoved) {
      const matches = segment.match.filter((match) => match !== event.item);

      setSegment({
        ...segment,
        match: [...matches],
      });
    } else if (event.type === CollectionEvents.ItemUpdated) {
      const itemIndex = segment.match.findIndex((m) => m === event.oldItem);
      const matches = [...segment.match];
      matches[itemIndex] = event.newItem;

      setSegment({
        ...segment,
        match: [...matches],
      });
    }
  };

  if (!segment) {
    return <Loader />;
  }

  return (
    <>
      <header>
        <h3>{segment.name}</h3>
        <ButtonList className="space-p-v-3">
          <ButtonSave type="primary" onClick={() => saveSegment(segment)} />
          <ButtonDelete
            type="secondary"
            onClick={async () => {
              getUsageInFlags?.();
              modal.confirm.show();
            }}
          />
          <ButtonHelp
            type="secondary"
            onClick={async () => {
              getUsageInFlags?.();
              modal.default.show();
            }}
            icon="search"
            text="Where used?"
          />
          {usageInFlags?.isLoading && <Loader size="sm" />}
        </ButtonList>
        <InputDescription
          value={segment.description || ''}
          onChange={setDescription}
          className={Date.now().toString()}
        />
      </header>
      <MatchList matches={segment.match} onChange={setMatches} />
      {modal.state.confirm && !usageInFlags?.isLoading && (
        <ModalConfirm
          title={`Are you sure you want to delete ${segment.name} segment?`}
          onSubmit={() => deleteSegment(segment)}
        >
          {!!usageInFlags?.flags.length && (
            <>
              <p>It is used in the following flags:</p>
              <ul>
                {usageInFlags.flags.map((flagName) => (
                  <li key={flagName}>{flagName}</li>
                ))}
              </ul>
            </>
          )}
        </ModalConfirm>
      )}
      {modal.state.default && !usageInFlags?.isLoading && (
        <Modal title={` Where is ${segment.name} segment used?`}>
          {usageInFlags?.flags.length ? (
            <ul>
              {usageInFlags.flags.map((flagName) => (
                <li key={flagName}>{flagName}</li>
              ))}
            </ul>
          ) : (
            <p>This segment is not used in flags</p>
          )}
        </Modal>
      )}
    </>
  );
}
