import { Dropdown } from 'yarn-design-system-react-components';
import { Card } from '../card';
import { DefaultVariantProps } from './types';

export const DefaultVariant: React.FC<DefaultVariantProps> = ({
  value,
  variants,
  label,
  onChange,
}) => {
  const optionVariants = variants.map((variant) => ({
    value: variant.name,
    label: variant.name,
    selected: variant.name === value,
  }));

  const currentVariantOption = optionVariants.find((opt) => opt.selected);

  function handleChange(option: any) {
    onChange(option.value);
  }

  return (
    <Card header={{ title: label }}>
      <div className="flex w-half gap-6 items-center form-groups-no-margin">
        <small>Serve</small>
        <div className="flex-1">
          <Dropdown
            portaling={true}
            value={currentVariantOption}
            type="single"
            clearable={false}
            options={optionVariants}
            onChange={handleChange}
          />
        </div>
      </div>
    </Card>
  );
};
