import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SegmentRecord } from '@feature-flags/entities';
import { omit } from '@feature-flags/utils';
import { RequestError } from '../../api-client/api-client';
import {
  useEnvironments,
  useApiClient,
  useSegment,
} from '../../api-client/api-client-hooks';
import { useNotify } from '../../atoms';
import { SegmentUsageInFlags } from '../../organisms/segment/segment-details/types';
import { SegmentStatusMessages } from './constants';

export function useSegmentDetailsController() {
  const { projectName, environmentName, segmentName } = useParams();
  const { data: environments } = useEnvironments(projectName);
  const { data: segmentData, isLoading } = useSegment(
    projectName!,
    environmentName!,
    segmentName!
  );
  const navigate = useNavigate();
  const notify = useNotify();
  const { getFlagsAssociatedWithSegment, updateSegment, deleteSegment } =
    useApiClient();

  const [usageInFlags, setUsageInFlags] = useState<SegmentUsageInFlags>({
    isLoading: false,
    segmentName: '',
    flags: [],
  });

  async function deleteThis(segment: SegmentRecord) {
    if (!segment) {
      return;
    }

    try {
      await deleteSegment(projectName!, environmentName!, segment.name);
      navigate(
        `/projects/${projectName}/environments/${environmentName}/segments`
      );
      notify.success(`${segment.name} ${SegmentStatusMessages.DELETED}`);
    } catch (err) {
      notify.error((err as RequestError).details.message);
    }
  }

  async function saveSegment(segment: SegmentRecord) {
    if (!segment) {
      return;
    }

    try {
      await updateSegment(
        projectName!,
        environmentName!,
        segment.name,
        omit(segment, ['name', 'createdAt', 'updatedAt', 'id', 'environmentId'])
      );

      notify.success(SegmentStatusMessages.UPDATED);
    } catch (err) {
      notify.error((err as RequestError)?.details.message);
    }
  }

  const getUsageInFlags = async () => {
    setUsageInFlags({ ...usageInFlags, isLoading: true });
    const usage = await getFlagsAssociatedWithSegment(
      projectName as string,
      environmentName as string,
      segmentName as string
    );
    setUsageInFlags({ ...usageInFlags, ...usage, isLoading: false });
  };

  return {
    environments,
    isLoading,
    segmentData,
    usageInFlags,
    deleteSegment: deleteThis,
    getFlagsAssociatedWithSegment,
    getUsageInFlags,
    saveSegment,
  };
}
