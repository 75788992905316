import React from 'react';
import { CardProps } from './types';
import { CardHeader, CardBody, CardFooter } from './';
import styles from './card.module.scss';

export const Card: React.FC<CardProps> = ({
  children,
  item,
  noPadding,
  header,
  body,
  footer,
}) => {
  return (
    <div
      className={`${styles.card} ${
        noPadding ? styles['card-no-padding'] : ''
      } ${styles['card-type-' + (body?.type || 'default')]}`}
      data-testid="card"
    >
      {header && <CardHeader {...header} item={item} />}
      {body ? (
        <CardBody {...body} item={item}>
          {body.children || null}
        </CardBody>
      ) : (
        <CardBody>{children}</CardBody>
      )}
      {footer && (
        <CardFooter {...footer} item={item}>
          {footer.children || null}
        </CardFooter>
      )}
    </div>
  );
};
