import styles from './card.module.scss';

export const CardList: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
}) => {
  return (
    <div className={`${styles['card-list']} ${className}`}>{children}</div>
  );
};
