import { Link, generatePath } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'yarn-design-system-react-components';
import { AuditLogRecord } from '@feature-flags/entities';
import { AbsolutePath } from '../../app/routes';
import { timeSince, prettyDate } from '../../helpers/datetime';

interface AuditlogListProps {
  logs?: AuditLogRecord[];
}

export function AuditlogList({ logs }: AuditlogListProps) {
  let reversedLogs: Array<AuditLogRecord> = [];

  if (logs) {
    reversedLogs = [...logs].reverse();
  }

  const renderRow = (logItem: AuditLogRecord, index: number) => {
    return (
      <TableRow variant="body" key={index}>
        {renderColumn(logItem)}
      </TableRow>
    );
  };

  const renderColumn = (logItem: AuditLogRecord) => {
    const nameWrappedWithLink =
      logItem.type === 'flag' ? (
        <Link
          className="text-black"
          to={generatePath(AbsolutePath.FLAG_DETAILS, {
            projectName: logItem.projectName,
            environmentName: logItem.environmentName,
            flagName: logItem.name,
          })}
        >
          {logItem.name}
        </Link>
      ) : (
        logItem.name
      );

    return (
      <>
        <TableCell className="text-right">{logItem.id}</TableCell>
        <TableCell className="text-nowrap">
          <span data-testid="datetime" title={logItem.createdAt.toString()}>
            {prettyDate(logItem.createdAt)}
          </span>
          &nbsp;
          <span className="opacity-50">({timeSince(logItem.createdAt)})</span>
        </TableCell>
        <TableCell className="text-left">
          <div className="flex gap-1">
            <span>{logItem.userName}</span>
            <span>{logItem.action}</span>
            <span>{nameWrappedWithLink}</span>
            <span>{logItem.type}</span>
          </div>
        </TableCell>
      </>
    );
  };

  const renderTablePlaceholder = (placeholder: JSX.Element | string) => (
    <TableRow variant="body">
      <TableCell colSpan="3">
        <div
          className="flex flex-align-center flex-justify-center"
          style={{ height: '200px' }}
        >
          {placeholder}
        </div>
      </TableCell>
    </TableRow>
  );

  return (
    <div className="table-responsive space-m-b-3">
      <Table className="table-condensed table-hover">
        <TableHead>
          <TableRow variant="head">
            <TableCell className="text-right" width="48">
              id
            </TableCell>
            <TableCell>Date</TableCell>
            <TableCell width="100%">Logs</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reversedLogs.length
            ? reversedLogs.map(renderRow)
            : renderTablePlaceholder('No logs have been detected here...')}
        </TableBody>
      </Table>
    </div>
  );
}
