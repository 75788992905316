import { parseSafe } from '../../helpers/json-transform';
import { Card } from '../card';
import { CardTypes } from '../card/types';
import { VariantDetailsProps } from './types';

export function VariantDetails({
  variant,
  onChange,
  onDelete,
}: VariantDetailsProps) {
  function handleChange(property: string, value: string) {
    onChange({
      ...variant,
      [property]: parseSafe(value),
    });
  }

  const actions = [];
  if (typeof onDelete !== 'undefined') {
    actions.push({
      name: 'Delete',
      icon: 'delete',
      onClick: () => onDelete(variant),
    });
  }

  return (
    <Card
      item={variant}
      noPadding={true}
      header={{
        title: variant.name,
        icon: 'hex',
        placeholder: 'Name',
        onChange: (value) => handleChange('name', value),
        actions,
      }}
      body={{
        type: CardTypes.TEXTFIELD,
        value: variant.value,
        placeholder: 'Value',
        onChange: (value) => handleChange('value', value),
      }}
    />
  );
}
