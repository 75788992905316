import { Button } from './button';
import { ButtonProps } from './types';

export const ButtonAdd: React.FC<ButtonProps> = (props) => {
  const DEFAULT_TEXT = props.disabled ? 'Added' : 'Add';
  const buttonText = props.children || props.text || DEFAULT_TEXT;
  return (
    <Button icon="plus" data-testid="button-add" {...props}>
      {buttonText}
    </Button>
  );
};
