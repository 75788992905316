import { useMsal } from '@azure/msal-react';
import { Link } from 'react-router-dom';
import {
  ContentPosition,
  Header as YarnHeader,
  HeaderBrand,
} from 'yarn-design-system-react-components';
import { AbsolutePath } from '../../app/routes';
import styles from './header.module.scss';

export const Header: React.FC = () => {
  const { accounts } = useMsal();
  return (
    <YarnHeader menuOpen={false} data-testid="header">
      <ContentPosition position="left">
        <Link
          to={AbsolutePath.INDEX}
          className={styles.logo}
          data-testid="logo"
        >
          <HeaderBrand
            logo={
              <img
                src="/logobwr.png"
                alt="Feature Flags"
                className="space-m-h-2"
              />
            }
          >
            <span>Feature Flags</span>
          </HeaderBrand>
        </Link>
      </ContentPosition>
      <ContentPosition position="middle"></ContentPosition>
      <ContentPosition position="right">
        <span className="space-m-r-3" data-testid="username">
          {accounts[0]?.username}
        </span>
      </ContentPosition>
    </YarnHeader>
  );
};
