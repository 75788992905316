import { CollectionEvents } from '../../constants';
import { parseSafe } from '../../helpers/json-transform';
import { Card } from '../card';
import { CardTypes } from '../card/types';
import { VariantDetails } from './variant-details';
import { Variant, VariantDetailsListProps } from './types';

export function VariantDetailsList({
  variants,
  onChange,
}: VariantDetailsListProps) {
  function handleChange(newVariant: Variant, index: number) {
    onChange({
      type: CollectionEvents.ItemUpdated,
      newItem: newVariant,
      oldItem: variants[index],
    });
  }

  function findUniqueName(): string {
    let counter = variants.length;
    const name = 'new_variant_' + counter;
    let running = true;
    while (running) {
      const variant = [...variants].find((v) => v.name === name);
      if (!variant) {
        running = false;
      }
      counter++;
    }
    return name;
  }

  function handleAdd() {
    const item: Variant = {
      name: findUniqueName(),
      value: undefined,
    };

    onChange({ type: CollectionEvents.ItemAdded, item });
  }

  function handleDelete(item: Variant) {
    onChange({ type: CollectionEvents.ItemRemoved, item });
  }

  function renderVariant(variant: Variant, index: number, variants: Variant[]) {
    const isPossibleToDelete = variants.length > 1;
    return (
      <VariantDetails
        key={index}
        variant={variant}
        onChange={(newVariant) => handleChange(newVariant, index)}
        onDelete={isPossibleToDelete ? handleDelete : undefined}
      ></VariantDetails>
    );
  }

  function getVariantType(variants: Variant[]) {
    const noTypeToDetect =
      variants.length === 0 ||
      typeof variants[0].value === 'undefined' ||
      variants[0].value === '';

    return noTypeToDetect ? `` : `(${typeof parseSafe(variants[0].value)})`;
  }

  return (
    <div className="row" data-testid="variants">
      <h5>Variants {getVariantType(variants)}</h5>
      <div className="grid grid-cols-3 auto-rows-fr gap-4">
        {variants.map(renderVariant)}
        <Card
          noPadding={true}
          body={{
            type: CardTypes.BUTTON,
            value: 'Add variant',
            icon: 'plus',
            onClick: handleAdd,
          }}
        />
      </div>
    </div>
  );
}
