import {
  EnvironmentRecord,
  FlagRecord,
  ProjectRecord,
  SegmentRecord,
  TagRecord,
} from '@feature-flags/entities';
import React, { useContext, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useAccessToken } from '../access-token-provider';
import { Errors } from '../constants';
import { ApiClient, createApiClient } from './api-client';

type ApiHookOptions<TData> = {
  enabled?: boolean;
  onSuccess?: (data: TData) => void;
  onError?: (error: Error) => void;
};

export function useProjects(options?: ApiHookOptions<ProjectRecord[]>) {
  const { getProjects } = useApiClient();
  return useQuery('getProjects', () => getProjects(), options);
}

export function useProject(
  projectName: string,
  options?: ApiHookOptions<ProjectRecord>
) {
  const { getProject } = useApiClient();
  return useQuery('getProject', () => getProject(projectName), options);
}

export function useEnvironments(
  projectName?: string,
  options?: ApiHookOptions<EnvironmentRecord[]>
) {
  const { getEnvironments } = useApiClient();
  return useQuery(
    ['getEnvironments', projectName],
    () => getEnvironments(projectName!),
    options
  );
}

export function useSegments(
  projectName: string,
  environmentName: string,
  options?: ApiHookOptions<SegmentRecord[]>
) {
  const { getSegments } = useApiClient();
  return useQuery(
    ['getSegments', projectName, environmentName],
    () => getSegments(projectName, environmentName),
    options
  );
}

export function useSegment(
  projectName: string,
  environmentName: string,
  segmentName: string,
  options?: ApiHookOptions<SegmentRecord>
) {
  const { getSegment } = useApiClient();
  return useQuery(
    ['getSegment', projectName, environmentName, segmentName],
    () => getSegment(projectName, environmentName, segmentName),
    options
  );
}

export function useFlags(
  projectName: string,
  environmentName: string,
  options?: ApiHookOptions<FlagRecord[]>
) {
  const { getFlags } = useApiClient();
  return useQuery(
    ['getFlags', projectName, environmentName],
    () => getFlags(projectName, environmentName),
    options
  );
}

export function useFlag(
  projectName: string,
  environmentName: string,
  flagName: string,
  options?: ApiHookOptions<FlagRecord>
) {
  const { getFlag } = useApiClient();
  return useQuery(
    ['getFlag', projectName, environmentName, flagName],
    () => getFlag(projectName, environmentName, flagName),
    {
      retry: (_, error: Error) =>
        (error as any).message === Errors.NotFound ? false : true,
      useErrorBoundary: (error: Error, query) => {
        if (error.message === Errors.NotFound) {
          query.invalidate();
          query.cacheTime = 0;
        }

        return false;
      },
    }
  );
}

export function useLinkedEnvironments(
  projectName: string,
  environmentName: string,
  flagName: string
) {
  const { getFlagLinkedEnvironments } = useApiClient();
  return useQuery(
    ['getFlagLinkedEnvironments', projectName, environmentName, flagName],
    () => getFlagLinkedEnvironments(projectName, environmentName, flagName)
  );
}

export function useFlagVersions(
  projectName: string,
  environmentName: string,
  flagName: string
) {
  const { getFlagVersions } = useApiClient();
  return useQuery(
    ['getFlagVersions', projectName, environmentName, flagName],
    () => getFlagVersions(projectName, environmentName, flagName)
  );
}

export function useLogs(projectName: string, environmentName: string) {
  const { getLogs } = useApiClient();
  return useQuery(['getLogs', projectName, environmentName], () =>
    getLogs(projectName, environmentName)
  );
}

export function useTags(
  projectName?: string,
  environmentName?: string,
  options?: ApiHookOptions<TagRecord[]>
) {
  const { getTags } = useApiClient();
  return useQuery(
    ['getTags', projectName, environmentName],
    () => getTags(projectName, environmentName),
    options
  );
}

export function useTagsWithFlags(
  projectName?: string,
  environmentName?: string,
  options?: ApiHookOptions<TagRecord[]>
) {
  const { getTagsWithFlags } = useApiClient();
  return useQuery(
    ['getTagsWithFlags', projectName, environmentName],
    () => getTagsWithFlags(projectName, environmentName),
    options
  );
}

const defaultValue: ApiClient = createApiClient();

export const ApiClientContext = React.createContext<ApiClient>(defaultValue);

export const useApiClient = () => useContext(ApiClientContext);

type AccessTokenProviderProps = {
  children: React.ReactNode;
};

export function ApiClientProvider({ children }: AccessTokenProviderProps) {
  const accessToken = useAccessToken();
  const apiClient = useMemo(() => createApiClient(accessToken), [accessToken]);
  return (
    <ApiClientContext.Provider value={apiClient}>
      {children}
    </ApiClientContext.Provider>
  );
}
