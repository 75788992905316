import { useEffect, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import {
  BooleanVariant,
  FlagRecord,
  NumberVariant,
  Split,
  StringVariant,
} from '@feature-flags/entities';
import { omit } from '@feature-flags/utils';
import {
  useApiClient,
  useEnvironments,
  useFlag,
  useSegments,
} from '../../api-client/api-client-hooks';
import { AbsolutePath } from '../../app/routes';
import { useNotify } from '../../atoms';
import { stringifySafe } from '../../helpers/json-transform';
import { timeSince } from '../../helpers/datetime';
import { CollectionEvents, FlagStatusMessages } from '../../constants';
import { FlagDetailsController, FlagDetailsTemplateProps } from '../../types';
import { useQueryState } from '../../helpers/query-state';
import { removeEmptyRules } from './utils';

export function useFlagDetailsPageController() {
  // API CLIENT
  const { projectName, environmentName, flagName } = useParams();
  const {
    getFlagVersions,
    getFlagLastVersion,
    updateFlag,
    deleteFlag,
    copyFlag,
  } = useApiClient();
  const navigate = useNavigate();
  const notify = useNotify();

  // DATA
  const {
    data: flagData,
    refetch: flagRefetch,
    isError: isFlagError,
  } = useFlag(projectName!, environmentName!, flagName!);
  const { data: segmentsData } = useSegments(projectName!, environmentName!);
  const { data: environmentsData } = useEnvironments(projectName!);
  const [versionsData, setVersionsData] =
    useState<FlagDetailsTemplateProps['data']['versions']>(undefined);
  const [versionsToDiff, setVersionsToDiff] = useState<
    FlagDetailsTemplateProps['data']['versionsToDiff']
  >([undefined, undefined]);

  // STATES
  const [flagState, setFlagState] = useState(flagData!);
  const [isFlagModified, setIsFlagModified] = useState(false);
  const [diffState, setDiffState] = useState<
    FlagDetailsTemplateProps['state']['diffState']
  >({
    options: [],
    baseVersion: -1,
    targetVersion: -1,
    baseSnapshot: null,
    targetSnapshot: null,
  });
  const flagDetailsQueryState = useQueryState();

  // WATCHERS
  // save the flag data to the flag state when it is fetched
  useEffect(() => {
    if (isFlagError) {
      return;
    }
    setFlagState(flagData!);
    // eslint-disable-next-line
  }, [flagData]);

  // check a difference between flagData and flagState
  useEffect(() => {
    const isFlagModified = !(
      stringifySafe(flagState) === stringifySafe(flagData)
    );
    setIsFlagModified(isFlagModified);
    // eslint-disable-next-line
  }, [flagState]);

  // get and set the flag versions of the flag and set the diffState
  useEffect(() => {
    if (!flagData) return;
    let isHookUnmounted = false;

    setFlagState(flagData);
    getFlagVersions(projectName!, environmentName!, flagName!).then(
      (versions) => {
        if (isFlagError || isHookUnmounted) {
          return;
        }
        setVersionsData(versions);
        setDiffState({
          ...diffState,
          options: versions
            .filter((_, index) => index < versions.length - 1)
            .map((version, i) => {
              const { userName, createdAt } = version;
              return {
                value: i + 1,
                label: `Version ${i + 1} by ${userName} (${timeSince(
                  createdAt
                )})`,
              };
            })
            .reverse(),
        });
      }
    );
    return () => {
      isHookUnmounted = true; // to avoid unmounted react error with async functions or callbacks
    };
    // eslint-disable-next-line
  }, [flagData]);

  // get and set two selected versions of flags to compare them (different environments)
  useEffect(() => {
    let isHookUnmounted = false;

    if (flagDetailsQueryState.params?.sourceEnv) {
      getFlagLastVersion(
        projectName!,
        flagDetailsQueryState.params.sourceEnv,
        flagName!
      ).then((sourceLastVersion) => {
        if (isHookUnmounted) return;
        setVersionsToDiff((prevState) => [sourceLastVersion, prevState?.[1]]);
      });
    } else {
      setVersionsToDiff((prevState) => [undefined, prevState?.[1]]);
    }

    if (flagDetailsQueryState.params?.destinationEnv) {
      getFlagLastVersion(
        projectName!,
        flagDetailsQueryState.params.destinationEnv,
        flagName!
      ).then((destinationLastVersion) => {
        if (isHookUnmounted) return;
        setVersionsToDiff((prevState) => [
          prevState?.[0],
          destinationLastVersion,
        ]);
      });
    } else {
      setVersionsToDiff((prevState) => [prevState?.[0], undefined]);
    }

    return () => {
      isHookUnmounted = true; // to avoid unmounted react error with async functions or callbacks
    };
    // eslint-disable-next-line
  }, [flagDetailsQueryState.params]);

  // redirect to the error page when flag's error exists
  useEffect(() => {
    if (isFlagError) {
      const flagNotFoundPath = generatePath(AbsolutePath.FLAG_NOT_FOUND, {
        projectName,
        environmentName,
        flagName,
      });
      navigate(flagNotFoundPath);
    }
    // eslint-disable-next-line
  }, [isFlagError]);

  // METHODS
  const updateThis: FlagDetailsController['updateFlag'] = async (
    flag = flagState
  ) => {
    flagDetailsQueryState.start('updateFlag');
    let responseData;

    try {
      const newFlagData = omit(flag, [
        'name',
        'createdAt',
        'updatedAt',
        'environmentId',
        'id',
        'tags',
      ]);

      removeEmptyRules(newFlagData as FlagRecord);

      responseData = await updateFlag(
        projectName!,
        environmentName!,
        flagName!,
        newFlagData
      );
      await flagRefetch();
      notify.success(FlagStatusMessages.UPDATED);
    } catch (error) {
      notify.unknown(error);
      flagDetailsQueryState.setError(error);
    }

    flagDetailsQueryState.finish(responseData);
    return responseData;
  };

  const deleteThis: FlagDetailsController['deleteFlag'] = async (
    flag = flagState
  ) => {
    flagDetailsQueryState.start('deleteFlag');
    let responseData;

    try {
      responseData = await deleteFlag(
        projectName!,
        environmentName!,
        flag.name || flagName!
      );
      notify.success(
        `${FlagStatusMessages.DELETED.replace(/the flag/i, flagName!)}`
      );
    } catch (error) {
      notify.unknown(error);
      flagDetailsQueryState.setError(error);
    }

    flagDetailsQueryState.finish(responseData);

    if (responseData) {
      const flagsPath = generatePath(AbsolutePath.FLAG_LIST, {
        projectName,
        environmentName,
      });
      navigate(flagsPath);
    }

    return responseData;
  };

  const restoreThis: FlagDetailsController['restoreFlag'] = async (
    snapshot
  ) => {
    if (!snapshot) {
      console.error(
        'Snapshot in undefined, impossible to restore the flag version!'
      );
      return;
    }

    const newSnapshotData = omit(snapshot, ['name']);

    flagDetailsQueryState.start('restoreFlag');
    let responseData;

    try {
      responseData = await updateFlag(
        projectName!,
        environmentName!,
        flagName!,
        newSnapshotData
      );
      flagRefetch();
      notify.success(FlagStatusMessages.RESTORED);
    } catch (error) {
      notify.unknown(error);
      flagDetailsQueryState.setError(error);
    }

    flagDetailsQueryState.finish(responseData);
    return responseData;
  };

  const disableThis: FlagDetailsController['disableFlag'] = async (
    flagName,
    flagDisabled
  ) => {
    flagDetailsQueryState.start('disableFlag');
    let responseData;

    try {
      responseData = await updateFlag(
        projectName!,
        environmentName!,
        flagName,
        { disabled: flagDisabled }
      );
      await flagRefetch();
      notify.success(FlagStatusMessages[flagDisabled ? 'DISABLED' : 'ENABLED']);
    } catch (error) {
      notify.unknown(error);
      flagDetailsQueryState.setError(error);
    }

    flagDetailsQueryState.finish(responseData);
    return responseData;
  };

  const copyThis: FlagDetailsController['copyFlag'] = async (
    sourceEnv,
    destinationEnv,
    flagName,
    flagType
  ) => {
    flagDetailsQueryState.start('copyFlag', {
      sourceEnv,
      destinationEnv,
      flagName,
      flagType,
    });
    let responseData;

    try {
      responseData = await copyFlag(
        projectName!,
        sourceEnv,
        destinationEnv,
        flagName
      );
      await flagRefetch();
      notify.success(`${flagName} ${FlagStatusMessages.COPIED}`);
    } catch (error) {
      notify.unknown(error);
      flagDetailsQueryState.setError(error);
    }

    flagDetailsQueryState.finish(responseData);

    return responseData;
  };

  // EVENTS
  const setDescription: FlagDetailsController['setDescription'] = (
    description
  ) => {
    if (!flagState) {
      console.warn('No flagState to setDescription', flagState);
      return;
    }
    setFlagState((prevState) => ({
      ...prevState,
      description,
    }));
  };

  const setVersion: FlagDetailsController['setVersion'] = (option) => {
    if (!diffState || !versionsData) {
      console.warn('No diffState or versionsData to setVersion', diffState);
      return;
    }
    const baseVersion = option ? versionsData.length : -1;
    const targetVersion = option ? option.value : -1;
    const baseSnapshot = option
      ? versionsData[baseVersion - 1]?.snapshot
      : null;
    const targetSnapshot = option
      ? versionsData[targetVersion - 1]?.snapshot
      : null;

    setDiffState((prevState) => ({
      ...prevState,
      baseVersion,
      targetVersion,
      baseSnapshot,
      targetSnapshot,
    }));
  };

  const setVariants: FlagDetailsController['setVariants'] = (event) => {
    if (!flagState) {
      console.warn('No flagState to setVariants', flagState);
      return;
    }

    if (event.type === CollectionEvents.ItemAdded) {
      const variants = [...flagState.variants, event.item] as
        | BooleanVariant[]
        | StringVariant[]
        | NumberVariant[];

      const rules = flagState.rules.map((rule) => {
        const isVariantSet = rule.splits.length === 1;

        const splits: Split[] = !isVariantSet
          ? [...rule.splits, { variant: event.item.name, size: 0 }]
          : [...rule.splits];

        return { ...rule, splits };
      });

      setFlagState((prevState) => ({
        ...prevState,
        rules,
        variants,
      }));
    } else if (event.type === CollectionEvents.ItemRemoved) {
      const defaultVariant =
        flagState.defaultVariant === event.item.name
          ? flagState.variants[0]?.name
          : flagState.defaultVariant;

      const rules = flagState.rules.map((rule) => {
        const splits = rule.splits.filter(
          (split) => split.variant !== event.item.name
        );
        return { ...rule, splits };
      });

      const variants = [...flagState.variants].filter(
        (variant) => variant.name !== event.item.name
      ) as BooleanVariant[] | StringVariant[] | NumberVariant[];

      setFlagState((prevState) => ({
        ...prevState,
        defaultVariant,
        rules,
        variants,
      }));
    } else if (event.type === CollectionEvents.ItemUpdated) {
      const defaultVariant =
        flagState.defaultVariant === event.oldItem.name
          ? event.newItem.name
          : flagState.defaultVariant;
      const rules = flagState.rules.map((rule) => {
        const splits = rule.splits.map((split) => {
          const variantName =
            split.variant === event.oldItem.name
              ? event.newItem.name
              : split.variant;

          return { ...split, variant: variantName };
        });
        return { ...rule, splits };
      });

      const variantIndex = flagState.variants.findIndex(
        (v) => v.name === event.oldItem.name
      );

      const variants = [...flagState.variants] as
        | BooleanVariant[]
        | StringVariant[]
        | NumberVariant[];

      variants[variantIndex] = {
        ...event.newItem,
      };

      setFlagState((prevState) => ({
        ...prevState,
        defaultVariant,
        rules,
        variants,
      }));
    }
  };

  const setRules: FlagDetailsController['setRules'] = (event) => {
    if (!flagState) {
      console.warn('No flagState to setRules', flagState);
      return;
    }
    let rules = flagState.rules;
    if (event.type === CollectionEvents.ItemAdded) {
      const newRule = {
        ...event.item,
        splits: [{ size: 100, variant: flagState.defaultVariant }],
      };
      rules = [newRule, ...flagState.rules];
    } else if (event.type === CollectionEvents.ItemRemoved) {
      rules = flagState.rules.filter((rule) => rule !== event.item);
    } else if (event.type === CollectionEvents.ItemUpdated) {
      const ruleIndex = flagState.rules.findIndex((r) => r === event.oldItem);
      rules = [...flagState.rules];
      rules[ruleIndex] = event.newItem;
    } else if (event.type === CollectionEvents.ItemSwapped) {
      if (
        event.firstIndex < 0 ||
        event.secondIndex < 0 ||
        event.firstIndex > rules.length - 1 ||
        event.secondIndex > rules.length - 1
      ) {
        return;
      }

      // swap them with ES6 :)
      [rules[event.firstIndex], rules[event.secondIndex]] = [
        rules[event.secondIndex],
        rules[event.firstIndex],
      ];
    }

    setFlagState((prevState) => ({
      ...prevState,
      rules: [...rules],
    }));
  };

  const setRebuiltRules: FlagDetailsController['setRebuiltRules'] = (
    rebuiltRules
  ) => {
    if (!flagState) {
      console.warn('No flagState to setRebuiltRules', flagState);
      return;
    }

    setFlagState((prevState) => ({
      ...prevState,
      rules: rebuiltRules,
    }));
  };

  const setDefaultVariant: FlagDetailsController['setDefaultVariant'] = (
    defaultVariant
  ) => {
    if (!flagState) {
      console.warn('No flagState to setDefaultVariant', flagState);
      return;
    }
    setFlagState((prevState) => ({
      ...prevState,
      defaultVariant,
    }));
  };

  const setTags: FlagDetailsController['setTags'] = (tags) => {
    if (!flagState) {
      console.warn('No flagState to setTags', flagState);
      return;
    }
    setFlagState((prevState) => ({
      ...prevState,
      tags,
    }));
  };

  return {
    projectName,
    environmentName,
    flagName,
    flag: flagData,
    segments: segmentsData,
    environments: environmentsData,
    versions: versionsData,
    versionsToDiff,
    flagState,
    isFlagModified,
    diffState,
    flagDetailsQueryState,
    updateFlag: updateThis,
    deleteFlag: deleteThis,
    restoreFlag: restoreThis,
    disableFlag: disableThis,
    copyFlag: copyThis,
    setDescription,
    setVersion,
    setVariants,
    setRules,
    setRebuiltRules,
    setDefaultVariant,
    setTags,
  };
}
