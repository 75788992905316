import { Icon, SelectionControl } from 'yarn-design-system-react-components';
import { timeSince } from '../../../helpers/datetime';
import { FlagItemProps } from './../types';
import styles from './../flag.module.scss';

export function FlagItem({
  description,
  enabled,
  name,
  showFlagStatusSwitch,
  tags,
  type,
  updatedAt,
  onFlagStatusUpdate,
}: FlagItemProps) {
  function handleChange(enabled: boolean) {
    if (onFlagStatusUpdate) {
      return onFlagStatusUpdate(enabled);
    }
  }

  return (
    <>
      <div className="flex space-p-v-2">
        <div>
          {showFlagStatusSwitch && (
            <SelectionControl
              checked={enabled}
              type="switch"
              onChange={handleChange}
              data-switch="flag-status"
              className={`${styles['flag-status-switch']}`}
              data-testid="flag-status-switch"
            />
          )}
        </div>
        <div className="flex-expand">
          <Title>{name}</Title>
          <Description>{description}</Description>
          <Metadata>
            <Type value={type} />
            <Time value={updatedAt} />
            {tags && !!tags.length && (
              <div className={`${styles['tags']}`}>
                <Icon
                  name="ila"
                  size="md"
                  className={`${styles['tag-icon']}`}
                />{' '}
                <span>{tags?.join(', ')}</span>
              </div>
            )}
          </Metadata>
        </div>
      </div>
    </>
  );
}

type TitleProps = {
  children: React.ReactNode;
};
function Title({ children }: TitleProps) {
  return <p className={`${styles['title']}`}>{children}</p>;
}

type DescriptionProps = {
  children: React.ReactNode;
};
function Description({ children }: DescriptionProps) {
  return (
    <p className={`${styles['description']}`}>
      {children ? (
        children
      ) : (
        <span className={`${styles['empty']}`}>No description provided.</span>
      )}
    </p>
  );
}

type MetadataProps = {
  children: React.ReactNode;
};
function Metadata({ children }: MetadataProps) {
  return <div className={`${styles['metadata']}`}>{children}</div>;
}

type TypeProps = {
  value: string;
};
function Type({ value }: TypeProps) {
  let type = '';
  switch (value) {
    case 'string':
      type = 'ab';
      break;
    case 'number':
      type = '123';
      break;
    default:
      type = 'y/n';
  }
  return (
    <div className={`flex flex-align-center ${styles['type']}`}>
      <span className={`space-m-r-2 ${styles['type-icon']}`}>{type}</span>
      {value}
    </div>
  );
}

type TimeProps = {
  value: Date;
};
function Time({ value }: TimeProps) {
  return (
    <div className="flex flex-align-center">
      <Icon name="time" size="sm" className="space-m-r-1" />
      {timeSince(value)}
    </div>
  );
}
