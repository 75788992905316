import React from 'react';
import {
  DragAndDropStateValue,
  DragAndDropContextValue,
  DragAndDropProviderProps,
} from './types';

export const DragAndDropContext =
  React.createContext<DragAndDropContextValue | null>(null);

/**
 * Drag-and-Drop State Prodiver, context
 * @component
 * @param {boolean} hasToggler Use toggler button to on/off drag mode or keep always on the DnD events
 * @param {boolean} hasHandler Use handler to drag (an icon) or drag by entire area of an item
 * @example
 * <DragAndDropProvider hasToggler={false} hasHandler={true}></DragAndDropProvider>
 */

export const DragAndDropProvider: React.FC<DragAndDropProviderProps> = ({
  children,
  hasToggler = false,
  hasHandler = false,
}) => {
  const [state, setState] = React.useState<DragAndDropStateValue>({
    draggable: !hasToggler,
    ids: [], // fake id to correctly re-render the list (need the unique key property)
    handlers: [],
    toggler: React.useRef<HTMLDivElement>(null),
  });
  const onToggle: DragAndDropContextValue['onToggle'] = () =>
    setState((prev) => ({
      ...prev,
      draggable: !prev.draggable,
    }));
  const setIds: DragAndDropContextValue['setIds'] = (items, swap) => {
    if (!items || items.length === 0) {
      return;
    }
    // This is a fake id generator for the items keys, which in need for React rendering
    const ids: DragAndDropStateValue['ids'] = swap ? state.ids : [];
    const handlers: DragAndDropStateValue['handlers'] = swap
      ? state.handlers
      : [];
    if (!swap) {
      for (let index = 0; index < items.length; index++) {
        ids.push(`dnd_id_${Math.random()}`);
        if (hasHandler) {
          handlers.push(React.createRef());
        }
      }
    } else {
      [ids[swap[0]], ids[swap[1]]] = [ids[swap[1]], ids[swap[0]]];
    }
    setState((prev) => ({
      ...prev,
      ids,
      handlers,
    }));
  };

  return (
    <DragAndDropContext.Provider
      value={{ state, onToggle, setIds, hasToggler, hasHandler }}
    >
      {children}
    </DragAndDropContext.Provider>
  );
};
