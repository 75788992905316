import React, { useContext, useEffect } from 'react';
import { DragAndDropContextValue, DragAndDropListProps } from './types';
import { DragAndDropContext } from './drag-n-drop-context';
import { DragAndDropItem } from './drag-n-drop-item';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import styles from './drag-n-drop.module.scss';

/**
 * Render Drag-and-Drop area with items, use DragAndDropToggle to switch DnD mode ON/OFF
 * @component
 * @param {T[]} items Array of the DnD items in the list
 * @param {(item, index) => JSX.Element} render Item render function which return JSX
 * @param {(firstIndex, secondIndex) => void} onChange Function to swap the two items
 * @example
 * <DragAndDropList items={items} render={renderFunction} onChange={onChange} />
 */

export function DragAndDropList<T>({
  items,
  render,
  onChange,
}: DragAndDropListProps<T>) {
  if (!(items instanceof Array) || (items.length > 0 && !items[0])) {
    throw Error(
      `DragAndDrop: The items property should be the array. Recheck you're passing the property correctly.`
    );
  }
  const { state, setIds } = useContext(
    DragAndDropContext
  ) as DragAndDropContextValue;

  const moveCard = (dragIndex: number, hoverIndex: number) => {
    setIds(items, [dragIndex, hoverIndex]);
    onChange(dragIndex, hoverIndex);
  };

  const renderItem = (item: any, index: number) => {
    const id = item?.id || state.ids[index]; // fake id to re-render the list correctly
    if (!id) {
      return null;
    }
    return (
      <DragAndDropItem key={id} id={id} index={index} moveCard={moveCard}>
        {render(item, index)}
      </DragAndDropItem>
    );
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setIds(items);
  }, [items.length]);

  return state.draggable && state.ids.length > 0 ? (
    <DndProvider backend={HTML5Backend}>
      <ul className={styles.list} data-testid="dnd-list">
        {items.map(renderItem)}
      </ul>
    </DndProvider>
  ) : (
    <>{items.map(render)}</>
  );
}
