import { PropertyMatch } from '@feature-flags/entities';
import { MatchListProps } from './match-types';
import { ButtonAdd } from '../../atoms';
import { CollectionEvents } from '../../constants';
import { CardList, Match } from '..';

export function MatchList({ matches, onChange }: MatchListProps) {
  function handleChange(newSegmentMatch: PropertyMatch, index: number) {
    onChange({
      type: CollectionEvents.ItemUpdated,
      newItem: newSegmentMatch,
      oldItem: matches[index],
    });
  }

  function handleAdd() {
    const item: PropertyMatch = {
      operator: 'eq',
      value: undefined,
      key: '',
    };

    onChange({ type: CollectionEvents.ItemAdded, item });
  }

  function handleDelete(item: PropertyMatch) {
    onChange({ type: CollectionEvents.ItemRemoved, item });
  }

  function renderItem(item: PropertyMatch, index: number) {
    return (
      <Match
        key={index}
        index={index}
        match={item}
        onChange={(item) => handleChange(item, index)}
        onDelete={handleDelete}
      ></Match>
    );
  }

  return (
    <>
      <h5>Matches</h5>
      <CardList>{matches.map(renderItem)}</CardList>
      <div className="row row-no-gutters space-m-v-3">
        <div className="col-xs-12">
          <ButtonAdd type="secondary" onClick={handleAdd} text="Add Match" />
        </div>
      </div>
    </>
  );
}
