import { MatchProps } from './match-types';
import { Input } from '../../atoms';
import { Card } from '../';
import { Dropdown } from 'yarn-design-system-react-components';

export function Match({ index, match, onChange, onDelete }: MatchProps) {
  function handleChange(property: string, value: any) {
    onChange({
      ...match,
      [property]: value,
    });
  }

  function handleOperatorChange(option: any) {
    let value: any;
    if (option.value === 'in') {
      if (typeof match.value === 'string') {
        value = match.value.split(', ');
      }
    } else {
      if (Array.isArray(match.value)) {
        value = match.value.join(', ');
      }
    }

    onChange({ ...match, operator: option.value, value });
  }

  function handleDelete() {
    onDelete(match);
  }

  function renderValueEditor() {
    if (match.operator === 'in') {
      const selectedOptions: any = [];
      if (Array.isArray(match.value)) {
        selectedOptions.push(
          ...match.value.map((v) => ({ value: v, label: v, selected: true }))
        );
      } else if (typeof match.value === 'string') {
        selectedOptions.push(
          ...match.value
            .split(', ')
            .map((v) => ({ value: v, label: v, selected: true }))
        );
      }

      return (
        <Dropdown
          value={selectedOptions}
          portaling={true}
          multiple
          clearable
          creatable={true}
          onChange={(values: any) =>
            handleChange(
              'value',
              values.map((v: any) => v.value)
            )
          }
        />
      );
    }

    return (
      <Input
        placeholder="Value"
        value={match.value}
        onChange={(value: string) => handleChange('value', value)}
      ></Input>
    );
  }

  const operators = [
    {
      value: 'eq',
      label: 'equals',
      selected: match.operator === 'eq',
    },
    {
      value: 'in',
      label: 'in',
      selected: match.operator === 'in',
    },
  ];

  const selectedOperator = operators.find((o) => o.selected);

  let title =
    typeof index !== 'undefined' && index >= 0 ? '#' + (index + 1) : '';
  title += ` Match - ${match.key || '...'}`;
  title += match.value
    ? ': ' +
      (match.value instanceof Array ? match.value.join(', ') : match.value)
    : '';

  return (
    <Card
      item={match}
      header={{
        title,
        actions: [
          {
            name: 'Delete',
            icon: 'delete',
            onClick: handleDelete,
          },
        ],
      }}
    >
      <div className="grid grid-cols-4 gap-4 form-groups-no-margin">
        <div className="">
          <Input
            placeholder="Key"
            value={match.key}
            onChange={(value: string) => handleChange('key', value)}
          ></Input>
        </div>
        <div className="">
          <Dropdown
            portaling={true}
            value={selectedOperator}
            type="single"
            clearable={false}
            options={operators}
            onChange={handleOperatorChange}
          />
        </div>
        <div className="col-span-2">{renderValueEditor()}</div>
      </div>
    </Card>
  );
}
