import { useState } from 'react';
import { Loader } from '../../atoms';
import { TagListTemplate } from '../../templates';
import { useTagListPageController } from './controller';

export function TagListPage() {
  const {
    associatedFlagsPath,
    environments,
    isLoading,
    tags: tagsData,
    saveNewTags,
    saveUpdatedTag,
    deleteTag,
  } = useTagListPageController();

  const [filterText, setFilterText] = useState<string>('');

  function filterItems(text: string) {
    setFilterText(text);
  }

  const tags = filterText
    ? (tagsData || []).filter((tag) =>
        tag.value.toLowerCase().includes(filterText.toLowerCase())
      )
    : tagsData || [];

  if (isLoading) {
    return <Loader />;
  }

  return (
    <TagListTemplate
      associatedFlagsPath={associatedFlagsPath}
      environments={environments || []}
      filterText={filterText}
      tags={tags}
      deleteTag={deleteTag}
      filterItems={filterItems}
      saveNewTags={saveNewTags}
      saveUpdatedTag={saveUpdatedTag}
    />
  );
}
