import { diffLines } from 'diff';
import {
  Dropdown,
  Snackbar,
  SnackbarText,
} from 'yarn-design-system-react-components';
import {
  DiffProps,
  DiffLineItem,
  DiffOption,
  FlagHistoryProps,
} from './../types';
import { Button } from '../../../atoms';
import { useModalContext, Modal } from '../../../organisms';
import styles from '../flag.module.scss';
import { timeSince } from '../../../helpers/datetime';

export const FlagHistory: React.FC<FlagHistoryProps> = ({
  versions,
  diffState,
  onChange,
  onSubmit,
}) => {
  const { options, baseSnapshot, targetSnapshot, targetVersion } = diffState;

  return (
    <div className={`${styles['flag-history']}`}>
      <Dropdown
        className="z-10"
        label={`Select a version to rollback`}
        options={options}
        onChange={(option: DiffOption) => onChange(option)}
      />
      {targetVersion > -1 ? (
        <DiffSideBySide base={baseSnapshot} target={targetSnapshot} />
      ) : (
        <Snackbar
          closable={false}
          icon="attention-big"
          multiline
          status="warning"
          theme="light"
        >
          <SnackbarText>
            Select a version to rollback in the dropdown above to see detailed
            difference of the flag's versions.
          </SnackbarText>
        </Snackbar>
      )}
    </div>
  );
};

function DiffSideBySide({ base, target }: DiffProps) {
  if (!base || !target) {
    return null;
  }

  const keys = Object.keys(base);

  const compareLines = (
    baseItem: any,
    targetItem: any
  ): Array<DiffLineItem> => {
    const baseLines = JSON.stringify(baseItem, undefined, 2);
    const targetLines = JSON.stringify(targetItem, undefined, 2);

    return diffLines(baseLines, targetLines);
  };

  const renderDiffLineNumbers = (
    lineLength: number,
    lineNumber: number
  ): JSX.Element => {
    return (
      <td
        width="48px"
        className="text-right space-p-h-0 space-p-r-2"
        valign="top"
      >
        {new Array(lineLength).fill(0).map((_, i) => {
          return (
            <div key={`diff__line_${i}`} className={`${styles['diff__line']}`}>
              {lineNumber++}
            </div>
          );
        })}
      </td>
    );
  };

  const renderDiffColumn = (currentLine: DiffLineItem): JSX.Element => {
    let bgColor = styles['same'];
    if (currentLine.added) {
      bgColor = styles['added'];
    } else if (currentLine.removed || !currentLine.value) {
      bgColor = styles['removed'];
    }
    return (
      <td width="50%" valign="top" className={bgColor}>
        <pre>{currentLine.value}</pre>
      </td>
    );
  };

  const renderDiffRows = (lines: Array<DiffLineItem>): JSX.Element[] => {
    const rows = [];
    let index = 0;
    let linesCounter = 1;

    while (index < lines.length) {
      const leftLine = lines[index];
      let rightLine = lines[index + 1];

      // side-by-side - distribution items logic
      // no changes items have to be at the both sides
      // removed changes - at the left side only
      // added changes - at the right side only
      if (!leftLine.added && !leftLine.removed) {
        rightLine = leftLine;
      } else if (rightLine) {
        if (!rightLine.added && !rightLine.removed) {
          rightLine = { value: ``, count: 1 };
        } else {
          index++;
        }
      } else {
        rightLine = leftLine;
      }

      rows.push(
        <tr key={index}>
          {renderDiffLineNumbers(leftLine.count, linesCounter)}
          {renderDiffColumn(leftLine)}
          {renderDiffColumn(rightLine)}
        </tr>
      );

      index++;
      linesCounter += leftLine.count;
    }

    return rows;
  };

  return (
    <div className={`${styles['diff-list']}`}>
      {keys.map((k) => {
        const compared = compareLines(base[k], target[k]);
        const rows = renderDiffRows(compared);

        return (
          <div className="row row-no-gutters" key={k}>
            <div className={`${styles['diff__key']}`}>{k}</div>
            <table className={`table table-condensed ${styles.diff}`}>
              <tbody>{rows}</tbody>
            </table>
          </div>
        );
      })}
    </div>
  );
}

export const FlagHistoryWithModal: React.FC<FlagHistoryProps> = ({
  versions = [],
  diffState,
  onChange,
  onSubmit,
}) => {
  const modal = useModalContext();
  const { targetSnapshot } = diffState;

  const handleClose = () => {
    modal.history.hide();
    onChange();
  };

  const handleSubmit = () => {
    handleClose();
    if (targetSnapshot) {
      onSubmit(targetSnapshot);
    }
  };

  const props = { versions, diffState, onChange, onSubmit };
  const activeVersion = versions[versions.length - 1];

  return (
    <Modal
      footer={<Button onClick={handleSubmit} type="primary" text="Restore" />}
      onClose={handleClose}
      title={`History (active version ${versions?.length} by ${
        activeVersion?.userName
      } ${timeSince(activeVersion?.createdAt)})`}
    >
      <FlagHistory {...props} />
    </Modal>
  );
};
