import { SyntheticEvent } from 'react';
import {
  List,
  ListItem,
  ListItemLabel,
} from 'yarn-design-system-react-components';
import { FlagItem } from '../index';
import { FlagListProps } from '../types';

export const FlagList: React.FC<FlagListProps> = ({
  flags,
  goToFlag,
  disableFlag,
}) => {
  return (
    <List type="ul" data-testid="flag-list" className="w-auto negative-margin">
      {flags?.length === 0 && (
        <ListItem>
          <ListItemLabel>{'No flags defined yet.'}</ListItemLabel>
        </ListItem>
      )}
      {flags?.map((flag) => (
        <ListItem
          key={flag.name}
          interactive
          onClick={(event: SyntheticEvent) => goToFlag(flag, event)}
        >
          <ListItemLabel>
            <FlagItem
              enabled={!flag.disabled}
              description={flag.description || ''}
              name={flag.name}
              showFlagStatusSwitch={true}
              tags={flag.tags || []}
              type={typeof flag.variants[0].value}
              updatedAt={flag.updatedAt}
              onFlagStatusUpdate={(enable: boolean) =>
                disableFlag(flag.name, !enable)
              }
            />
          </ListItemLabel>
        </ListItem>
      ))}
    </List>
  );
};
