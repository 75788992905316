import { generatePath, useParams } from 'react-router-dom';
import {
  useApiClient,
  useEnvironments,
  useTagsWithFlags,
} from '../../api-client/api-client-hooks';
import { AbsolutePath } from '../../app/routes';
import { useNotify } from '../../atoms';
import { TagStatusMessages } from './constants';
import { useEffect, useState } from 'react';
import { TagController, TagState } from './types';
import { TagEvents } from '../../constants';

export function useTagListPageController() {
  // API CLIENT
  const { projectName, environmentName } = useParams();
  const { createTag, updateTag, deleteTag, attachTagToFlag } = useApiClient();
  const notify = useNotify();

  // DATA
  const { data: environments } = useEnvironments(projectName);
  const {
    data: tags,
    isLoading,
    refetch: refetchTags,
  } = useTagsWithFlags(projectName!, environmentName!);
  const associatedFlagsPath = generatePath(AbsolutePath.FLAG_LIST, {
    projectName,
    environmentName,
  });

  // STATES
  const [tagState, setTagState] = useState<TagState>({
    attached: new Set(),
    detached: new Set(),
  });
  const [isTagsModified, setIsTagsModified] = useState(false);

  // WATCHERS
  useEffect(() => {
    setIsTagsModified(
      Boolean(tagState.attached.size || tagState.detached.size)
    );
    // eslint-disable-next-line
  }, [tagState]);

  // METHODS
  async function saveNewTags(newTags: string[]) {
    try {
      const tagsToSave = newTags.filter(Boolean);

      if (tagsToSave.length) {
        await createTag(projectName!, environmentName!, tagsToSave);
        refetchTags();
        notify.success(
          tagsToSave.length > 1
            ? `${tagsToSave.length} ${TagStatusMessages.CREATED_TAGS}`
            : `${tagsToSave.length} ${TagStatusMessages.CREATED_TAG}`
        );
      }
    } catch (err) {
      notify.unknown(err, {
        UniqueViolationError: 'This tag already exists!',
      });
    }
  }

  async function saveUpdatedTag(oldValue: string, newValue: string) {
    try {
      if (newValue) {
        await updateTag(projectName!, environmentName!, oldValue, {
          value: newValue,
        });
        refetchTags();
        notify.success(`${oldValue} ${TagStatusMessages.UPDATED}`);
      }
    } catch (err) {
      notify.unknown(err);
    }
  }

  async function deleteThis(value: string) {
    try {
      await deleteTag(projectName!, environmentName!, value);
      refetchTags();
      notify.success(`${value} ${TagStatusMessages.DELETED}`);
    } catch (err) {
      notify.unknown(err);
    }
  }

  async function attachThisToFlag(flagName: string) {
    if (isTagsModified) {
      const tagsData = {
        attached: Array.from(tagState.attached),
        detached: Array.from(tagState.detached),
      };

      try {
        await attachTagToFlag(
          projectName!,
          environmentName!,
          flagName!,
          tagsData
        );
        setTagState({
          attached: new Set(),
          detached: new Set(),
        });
        notify.success(
          TagStatusMessages.ATTACHED.replace('#flagName#', flagName!)
        );
        refetchTags();
      } catch (err) {
        notify.unknown(err);
      }
    }
  }

  // EVENTS
  const changeSelectedTags: TagController['changeSelectedTags'] = (
    event,
    selectedTags
  ) => {
    if (!selectedTags) {
      selectedTags = [];
    }
    if (event.type === TagEvents.ItemCreated && event.item) {
      const newTagValue = event.item.value;

      setTagState((prevState) => ({
        ...prevState,
        attached: prevState.attached.add(newTagValue) && prevState.attached,
      }));

      return [...selectedTags, newTagValue];
    }

    if (event.type === TagEvents.ItemRemoved && event.item) {
      const tagValue = event.item.value;

      if (selectedTags?.includes(tagValue)) {
        setTagState((prevState) => ({
          ...prevState,
          detached: prevState.detached.add(tagValue) && prevState.detached,
        }));
      } else {
        setTagState((prevState) => ({
          ...prevState,
          attached:
            (prevState.attached.delete(tagValue), new Set(prevState.attached)),
        }));
      }

      return selectedTags.filter((tag) => tag !== tagValue);
    }

    if (event.type === TagEvents.ItemAttached && event.item) {
      const tagValue = event.item.value;

      selectedTags?.includes(tagValue)
        ? tagState.detached.delete(tagValue)
        : tagState.attached.add(tagValue);

      setTagState({
        attached: new Set(tagState.attached),
        detached: new Set(tagState.detached),
      });

      return [...selectedTags, tagValue];
    }

    if (event.type === TagEvents.Clear) {
      setTagState({
        attached: new Set(),
        detached: new Set(selectedTags),
      });

      return [];
    }
  };

  return {
    associatedFlagsPath,
    environments,
    isLoading,
    tags,
    tagState,
    isTagsModified,
    deleteTag: deleteThis,
    attachTagToFlag: attachThisToFlag,
    saveNewTags,
    saveUpdatedTag,
    changeSelectedTags,
  };
}
