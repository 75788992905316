import { Header } from '../../organisms';
import { Loader } from '../../atoms';

export const ProjectDetailsTemplate: React.FC = () => {
  return (
    <>
      <Header />
      <main data-testid="project-details-template">
        <Loader />
      </main>
    </>
  );
};
