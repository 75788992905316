import React, { useContext, useReducer } from 'react';
import {
  NotifyContextValue,
  NotifyReducer,
  NotifyAction,
  NotifyState,
  NotifyApi,
  NotifyActions,
  NotifyTypes,
} from './types';
import NotifyList from './notify-list';
import { errorTextSeeker } from '../../helpers/error-text-seeker';

const NotifyContext = React.createContext<NotifyContextValue | null>(null);

function notifyState(): NotifyState {
  return {
    items: [],
    icons: {
      [NotifyTypes.INFO]: 'information-big',
      [NotifyTypes.ERROR]: 'attention-big',
      [NotifyTypes.WARNING]: 'warning',
      [NotifyTypes.SUCCESS]: 'checkmark-released',
      [NotifyTypes.UNKNOWN]: 'attention-big',
    },
  };
}

function notifyReducer(state: NotifyState, action: NotifyAction) {
  switch (action.type) {
    case NotifyActions.PUSH: {
      const {
        id = state.items.length,
        type,
        message,
      } = action.payload as NotifyReducer[typeof action.type];
      const items = [...state.items, { id, type, message }];
      return { ...state, items };
    }
    case NotifyActions.REMOVE: {
      const { id } = action.payload as NotifyReducer[typeof action.type];
      const items = [...state.items];
      items.splice(
        items.findIndex((n) => n.id === id),
        1
      );
      return { ...state, items };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export const NotifyProvider: React.FC<React.ReactNode> = ({ children }) => {
  const initialState = notifyState();
  const [state, dispatch] = useReducer(notifyReducer, initialState);

  // Create quick actions for the context:
  // {success: dispatch, error: dispatch, warning: dispatch, info: dispatch}
  const quickActions = Object.values(NotifyTypes).reduce((actions, type) => {
    if (type === NotifyTypes.UNKNOWN) {
      actions[type] = (err, options) => {
        errorTextSeeker(err, options).forEach((text) =>
          quickActions.error(text)
        );
      };
    } else {
      actions[type] = (message) =>
        dispatch({
          type: NotifyActions.PUSH,
          payload: { type, message: message as string },
        });
    }
    return actions;
  }, {} as NotifyApi);

  const api: NotifyContextValue = {
    state,
    dispatch,
    ...quickActions,
  };

  return (
    <NotifyContext.Provider value={api}>
      {children}
      <NotifyList />
    </NotifyContext.Provider>
  );
};

export function useNotify(extended?: undefined): NotifyApi;
export function useNotify(extended: boolean): NotifyContextValue;
export function useNotify(extended: boolean | undefined) {
  const context = useContext(NotifyContext);
  if (context === null) {
    throw new Error('useNotify must be used within a NotifyProvider');
  }
  if (extended === true) {
    return context;
  }
  const { state, dispatch, ...api } = context;
  return api;
}
