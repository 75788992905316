import { Highlight } from './highlight';
import { HighlightEmptynessProps } from './types';

export const HighlightEmptyness: React.FC<HighlightEmptynessProps> = ({
  children,
  value,
  onChange,
}) => {
  const checkTheEmptynessMatcher = {
    warning: function warning(value: string) {
      return value === '';
    },
  };

  return (
    <Highlight
      value={value}
      matcher={checkTheEmptynessMatcher}
      onChange={onChange}
    >
      {children}
    </Highlight>
  );
};
