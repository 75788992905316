import { ReactNode } from 'react';

interface ItemProps {
  item?: Record<string, any>;
  children?: ReactNode;
}

export interface CardProps extends ItemProps {
  header?: CardHeaderProps;
  body?: CardBodyProps;
  footer?: CardFooterProps;
  noPadding?: boolean;
}

/* Card Header Types */
export interface CardHeaderProps extends ItemProps {
  title: string | undefined;
  icon?: 'hex' | string;
  placeholder?: string;
  actions?: CardActionsProps[];
  onChange?: (value: string) => void;
}

export interface CardActionsProps {
  name: string;
  icon?: string;
  onClick?: (...args: any) => void;
  element?: ReactNode;
}

/* Card Body Types */
export enum CardTypes {
  TEXTFIELD = 'textfield',
  BUTTON = 'button',
}

export interface CardBodyProps extends ItemProps {
  type?: CardTypes;
  value?: string | undefined;
  placeholder?: string;
  icon?: string;
  onChange?: (value: string) => void;
  onClick?: (...args: any) => void;
}

/* Card Footer Types */
export interface CardFooterProps extends ItemProps {
  actions?: CardActionsProps[];
}
