import { QueryClientProvider } from 'react-query';
import { queryClient } from '../query-client';
import { ApiClientProvider } from '../api-client/api-client-hooks';
import { NotifyProvider } from '../atoms';
import { ModalProvider } from '../organisms';

const AppProviders: React.FC = ({ children }) => {
  return (
    <ApiClientProvider>
      <QueryClientProvider client={queryClient}>
        <ModalProvider>
          <NotifyProvider>{children}</NotifyProvider>
        </ModalProvider>
      </QueryClientProvider>
    </ApiClientProvider>
  );
};

export default AppProviders;
