import { useEffect } from 'react';
import { Button, ButtonSave, Input } from '../../atoms';
import { Modal } from '../../organisms';
import { useModalContext } from './context';
import { ModalProps } from './types';

export const ModalCreation: React.FC<ModalProps> = (props) => {
  const modal = useModalContext();
  const { onSubmit, onClose, footer, queryState, ...rest } = props;

  if (!rest.title) {
    rest.title = `Create a new item`;
  }
  if (!rest.placeholder) {
    rest.placeholder = `Enter the name...`;
  }

  function handleClose() {
    if (onClose) {
      onClose();
    }
    modal.create.hide();
  }

  function handleSubmit() {
    if (typeof onSubmit !== 'undefined') {
      onSubmit(modal.fields.name.current?.value);
    }
    if (!queryState) {
      handleClose();
    }
  }

  function renderFooter() {
    return typeof footer !== 'undefined' ? (
      footer
    ) : (
      <>
        <Button onClick={handleClose} text="Cancel" />
        <ButtonSave
          onClick={handleSubmit}
          isLoading={queryState?.isLoading}
          type="primary"
          data-testid="modal-creation-submit"
        />
      </>
    );
  }

  useEffect(() => {
    const hasResponseAfterSubmit = !!(
      queryState &&
      queryState.data &&
      modal.state.create
    );
    if (hasResponseAfterSubmit) {
      handleClose();
    }
    // eslint-disable-next-line
  }, [queryState]);

  return (
    <Modal {...rest} onClose={handleClose} footer={renderFooter()}>
      <Input
        ref={modal.fields.name}
        placeholder={rest.placeholder}
        label="Name"
        data-testid="modal-creation-name"
      />
      {props.children}
    </Modal>
  );
};
