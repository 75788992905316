import { generatePath, useNavigate } from 'react-router-dom';
import { useApiClient, useProjects } from '../../api-client/api-client-hooks';
import { AbsolutePath } from '../../app/routes';
import { ProjectListController } from '../../types';
import { useNotify } from '../../atoms';

export function useProjectListPageController() {
  // API CLIENT GROUP
  const { createProject } = useApiClient();
  const navigate = useNavigate();
  const notify = useNotify();

  // DATA GROUP
  const { data: projectsData, refetch: refetchProjects } = useProjects();

  // METHODS GROUP
  const createThis: ProjectListController['createProject'] = async (name) => {
    try {
      const newProject = { name };
      const createdProject = await createProject(newProject);
      refetchProjects();

      const route = generatePath(AbsolutePath.PROJECT_DETAILS, {
        projectName: name,
      });
      navigate(route);

      return createdProject;
    } catch (err) {
      notify.unknown(err, {
        UniqueViolationError: `Project "${name}" already exists!`,
      });
      return null;
    }
  };

  // EVENTS GROUP
  const goToProject: ProjectListController['goToProject'] = async (project) => {
    const projectRoute = generatePath(AbsolutePath.PROJECT_DETAILS, {
      projectName: project.name,
    });

    navigate(projectRoute);
  };

  return {
    projects: projectsData,
    createProject: createThis,
    goToProject,
  };
}
