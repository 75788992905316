export enum ModalTypes {
  DEFAULT = 'default',
  CREATE = 'create',
  CONFIRM = 'confirm',
  HISTORY = 'history',
  COPY = 'copy',
}

export enum ModalActions {
  SHOW = 'show',
  HIDE = 'hide',
}

export enum ModalTitles {
  CREATE = 'Create a new item',
  CONFIRM = 'Are you sure?',
}
