import { useEffect } from 'react';
import { useNavigate, useParams, generatePath } from 'react-router-dom';
import { useEnvironments } from '../../api-client/api-client-hooks';
import { AbsolutePath } from '../../app/routes';
import { ProjectDetailsTemplate } from '../../templates';

export function ProjectDetailsPage() {
  const { projectName } = useParams();
  const { data: environmentsData } = useEnvironments(projectName);
  const navigate = useNavigate();

  useEffect(() => {
    if (!environmentsData) {
      return;
    }
    const route = generatePath(AbsolutePath.FLAG_LIST, {
      projectName,
      environmentName: environmentsData[0].name,
    });

    navigate(route, {
      replace: true,
    });
  }, [environmentsData, projectName, navigate]);

  return <ProjectDetailsTemplate />;
}
