import React, { useEffect, useState } from 'react';
import { useNotify } from './notify-context';
import { NotifyProps, NotifyActions } from './types';
import { Snackbar, SnackbarText } from 'yarn-design-system-react-components';
import styles from './notify.module.scss';
export const notifyTimeout = 10000;

const Notify: React.FC<NotifyProps> = ({
  id,
  type,
  message,
  timeout = notifyTimeout,
}) => {
  const { state, dispatch } = useNotify(true);
  const handleClose = () =>
    dispatch({ type: NotifyActions.REMOVE, payload: { id } });
  const [timerId, setTimerId] = useState<any>(0);
  const icons = state.icons;

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (timeout) {
      setTimerId(() => setTimeout(handleClose, timeout));
    }
    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, []);

  return (
    <Snackbar
      role="listitem"
      multiline
      status={type}
      icon={icons[type]}
      theme="light"
      closable
      onClose={handleClose}
      className={styles.notify_item}
    >
      <SnackbarText>{message}</SnackbarText>
    </Snackbar>
  );
};

export default Notify;
