import { Loader as YarnLoader } from 'yarn-design-system-react-components';
import { LoaderProps } from './types';

export const Loader: React.FC<LoaderProps> = ({
  size = 'md',
  dimmed = false,
  className = '',
}) => {
  return (
    <YarnLoader
      size={size}
      dimmed={dimmed.toString()}
      className={`m-auto ${className}`}
      aria-busy="true"
      role="status"
      data-testid="loader"
    />
  );
};
