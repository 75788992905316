export enum FlagTypes {
  BOOLEAN = 'boolean',
  STRING = 'string',
  NUMBER = 'number',
}

export enum FlagFilterKeys {
  Disabled = 'disabled',
  Status = 'status',
  Tag = 'tag',
  Type = 'type',
}

export enum FlagStatusMessages {
  CREATED = 'Flag has been created!',
  COPIED = 'Flag has been copied!',
  UPDATED = 'Flag has been saved!',
  DELETED = 'Flag has been deleted!',
  RESTORED = 'Flag version has been restored!',
  ENABLED = 'Flag is enabled!',
  DISABLED = 'Flag is disabled!',
}
