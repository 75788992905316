import { TextField } from 'yarn-design-system-react-components';
import { EnvironmentRecord, Segment } from '@feature-flags/entities';
import { Header, SegmentList, Sidebar } from '../../organisms';
import { ButtonAdd } from '../../atoms';
import { useModalContext, ModalCreation } from '../../organisms';

interface SegmentListTemplateProps {
  environments: EnvironmentRecord[];
  filterText: string;
  segments: Segment[];
  filterItems: (text: string) => void;
  handleItemSelect: (segment: Segment) => void;
  saveNewSegment: (segmentName: string) => void;
}

export function SegmentListTemplate({
  environments,
  filterText,
  segments,
  filterItems,
  handleItemSelect,
  saveNewSegment,
}: SegmentListTemplateProps) {
  const modal = useModalContext();

  return (
    <>
      <Header />
      <main data-testid="segment-list-template">
        <Sidebar environments={environments} />
        <section className="container container-x-padding fill">
          <header>
            <h3>Segments</h3>
            <div className="flex space-p-v-3">
              <div className="flex-expand">
                <TextField
                  placeholder="Search..."
                  type="text"
                  value={filterText}
                  onChange={filterItems}
                  className="space-m-b-0"
                />
              </div>
              <ButtonAdd
                type="primary"
                onClick={modal.create.show}
                className="space-m-l-3"
                data-testid="new-segment-modal"
                text="New Segment"
              />
            </div>
          </header>
          <SegmentList
            segments={segments}
            handleItemSelect={handleItemSelect}
          />
        </section>
      </main>
      {modal.state.create && <ModalCreation onSubmit={saveNewSegment} />}
    </>
  );
}
