import { Badge } from 'yarn-design-system-react-components';
import { VisualizationLabelProps } from './types';

export const VisualizationLabel = ({
  brand,
  color,
}: VisualizationLabelProps) => {
  return (
    <Badge color={color} className="w-full">
      {brand}
    </Badge>
  );
};
