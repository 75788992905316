import { RequestError } from '../../api-client/api-client';
import { NotifyApiOptions } from '../../atoms/notify/types';

export function errorTextSeeker(
  err: unknown,
  options?: NotifyApiOptions
): string[] {
  if (err instanceof RequestError) {
    const details = err.details;
    const message = details?.message || err.message;

    if (options && options[message]) {
      return [options[message]];
    }

    if (details) {
      if (details?.errors) {
        return details.errors.map((error: any) => error.message);
      }
    }

    return [message || 'Unknown Request error...'];
  } else if (err instanceof Error) {
    return [err.message];
  }
  return ['Unknown error...'];
}
