import { InputDescriptionProps } from './types';
import { Icon } from 'yarn-design-system-react-components';
import { Input } from './';
import { HighlightEmptyness } from '../highlight';

export const InputDescription: React.FC<InputDescriptionProps> = ({
  value,
  onChange,
  className,
}) => {
  return (
    <div className={`space-p-v-3 ${className}`}>
      <HighlightEmptyness value={value}>
        <Input
          className={`${Date.now().toString()} space-m-b-0`}
          placeholder="Description"
          value={value}
          onChange={onChange}
          help={
            value === '' && (
              <span className="flex flex-align-center">
                <Icon name="attention-big" /> No description provided.
              </span>
            )
          }
        ></Input>
      </HighlightEmptyness>
    </div>
  );
};
