import { FlagRecord, Rule, SegmentMatch } from '@feature-flags/entities';

export const removeEmptyRules = (flagData: FlagRecord) => {
  flagData.rules = flagData.rules.reduce((withNoEmptyRules, rule) => {
    const isMatchArray = Array.isArray(rule.segmentMatch);

    if (isMatchArray) {
      rule.segmentMatch = (rule.segmentMatch as SegmentMatch[]).filter(
        (match) => Boolean(match.value.length)
      );
    }

    const ruleHasConstraints = isMatchArray
      ? Boolean((rule.segmentMatch as SegmentMatch[]).length)
      : Boolean((rule.segmentMatch as SegmentMatch).value.length);

    if (ruleHasConstraints) {
      withNoEmptyRules.push(rule);
    }

    return withNoEmptyRules;
  }, [] as Rule[]);
};
