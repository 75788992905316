import React from 'react';
import styles from './button-list.module.scss';

type ButtonListProps = {
  className?: string;
};

export const ButtonList: React.FC<ButtonListProps> = ({
  children,
  className,
}) => {
  return (
    <div role="group" className={`${styles['action-bar']} ${className}`}>
      {children}
    </div>
  );
};
