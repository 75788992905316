import { AuditlogListTemplate } from '../../templates';
import { useAuditlogListController } from './controller';

export function AuditlogListPage() {
  const { environments, isLoading, logs } = useAuditlogListController();

  return (
    <AuditlogListTemplate
      environments={environments || []}
      isLoading={isLoading}
      logs={logs}
    />
  );
}
