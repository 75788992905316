import React, { useContext, useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from './config';

export const AccessTokenContext = React.createContext<string | undefined>(
  undefined
);

export const useAccessToken = () => useContext(AccessTokenContext);

type AccessTokenProviderProps = { children: React.ReactNode };

export function AccessTokenProvider({ children }: AccessTokenProviderProps) {
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);

  const { accounts, instance } = useMsal();
  const account = accounts[0];

  useEffect(() => {
    async function getToken() {
      try {
        const { accessToken } = await instance.acquireTokenSilent({
          ...loginRequest,
          account: account as any,
        });

        setAccessToken(accessToken);
      } catch (err) {
        console.error(err);
      }
    }
    getToken();
  }, [account, instance]);

  return (
    <AccessTokenContext.Provider value={accessToken}>
      {children}
    </AccessTokenContext.Provider>
  );
}
