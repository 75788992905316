import {
  Button as YarnButton,
  Icon as YarnIcon,
} from 'yarn-design-system-react-components';
import { ButtonProps } from './types';

export const Button: React.FC<ButtonProps> = ({
  children,
  type = 'default',
  behavior = 'button',
  active = false,
  disabled = false,
  tumbler = false,
  size = 'md',
  icon = null,
  text = null,
  noText,
  onClick,
  isLoading = false,
  ...props
}) => {
  const buttonText = children || text || null;
  const buttonType = icon && noText ? `icon` : type;

  if (!onClick) {
    throw Error('The Button should have onClick method!');
  }

  if (!noText && !buttonText) {
    throw Error(
      'The Button is empty, please specify the text as attribute or as inner HTML content!'
    );
  }

  function renderIcon() {
    const iconName = isLoading ? 'spinner' : icon;
    const animationStyle = isLoading
      ? { animation: 'loader-animation 1.1s infinite linear' }
      : {};
    const dataTestId = isLoading ? 'spinner' : 'button-icon';

    return iconName ? (
      <YarnIcon
        name={iconName}
        size={size}
        status="default"
        style={animationStyle}
        data-testid={dataTestId}
      />
    ) : null;
  }

  return (
    <YarnButton
      {...props}
      type={buttonType}
      behavior={behavior}
      active={active}
      size={size}
      disabled={disabled || isLoading}
      onClick={onClick}
    >
      {renderIcon()}
      {!noText && buttonText && <span>{buttonText}</span>}
    </YarnButton>
  );
};
