import { Rule, Segment } from '@feature-flags/entities';
import { getObjectDeepClone } from '../../helpers/clone/object-deep-clone';
import { CollectionEvents, DropdownActions } from '../../constants';
import {
  ConstraintConfigParams,
  ConstraintDropdownConfig,
  ConstraintDroprownOption,
  SegmentMatch,
  SegmentWithType,
} from './types';

export function getSegmentTypes(segment: Segment): Set<string> {
  const segmentTypes = segment.match.reduce((types, currentMatch) => {
    return types.add(currentMatch.key);
  }, new Set<string>());

  return segmentTypes;
}

export function getSegmentsWithTypes(segments: Segment[]): SegmentWithType[] {
  const segmentsWithTypes: SegmentWithType[] = segments.reduce(
    (withTypes: SegmentWithType[], segment) => {
      const segmentTypes = getSegmentTypes(segment);

      const compositeType =
        segmentTypes.size > 1
          ? Array.from(segmentTypes).sort().join(' + ')
          : null;

      const singleType = compositeType
        ? null
        : segmentTypes.values().next().value;

      withTypes.push({
        ...getObjectDeepClone(segment),
        compositeTypeParts: compositeType ? segmentTypes : null,
        singleType,
        compositeType,
        typeLabel: compositeType || singleType,
      });

      return withTypes;
    },
    []
  );

  return segmentsWithTypes;
}

export function buildConstraintDropdownsConfig(
  rules: Rule[],
  segmentsWithTypes: SegmentWithType[]
): {
  constraintDropdownsConfig: ConstraintDropdownConfig[][];
  typesExcludedFromDropdownOptions: Set<string>[];
} {
  const typesExcludedFromDropdownOptions: Set<string>[] = [];

  const constraintDropdownsConfig = rules.map((rule, ruleIndex) => {
    const constraints = Array.isArray(rule.segmentMatch)
      ? rule.segmentMatch
      : [rule.segmentMatch];

    typesExcludedFromDropdownOptions.push(new Set<string>());

    return constraints.map((constraint) => {
      const firstSelectedConstraint = segmentsWithTypes.find(
        (segment) => segment.name === constraint.value[0]
      );

      if (!firstSelectedConstraint) {
        return {
          selectedType: '',
          value: '',
          types: new Set<string>(),
        };
      }

      const constraintType = firstSelectedConstraint.typeLabel;
      const usedTypes = firstSelectedConstraint.compositeType
        ? (firstSelectedConstraint.compositeTypeParts as Set<string>)
        : [firstSelectedConstraint.singleType];

      usedTypes.forEach((type) => {
        if (type) {
          typesExcludedFromDropdownOptions[ruleIndex].add(type);
        }
      });

      return {
        selectedType: constraintType,
        types: new Set(usedTypes as Set<string>),
      };
    });
  });

  return {
    constraintDropdownsConfig,
    typesExcludedFromDropdownOptions,
  };
}

export function getSegmentMatchesWithTypes(
  rules: Rule[],
  segmentsWithTypes: SegmentWithType[]
): ((SegmentWithType | undefined)[] | (SegmentWithType | undefined)[][])[] {
  return rules.map((rule) => {
    const segmentMatch = rule.segmentMatch;

    if (Array.isArray(segmentMatch)) {
      return segmentMatch.map((match) => {
        return match.value.map((matchValue) => {
          const segmentWithType = segmentsWithTypes.find(
            (segment) => segment.name === matchValue
          );
          return segmentWithType;
        });
      });
    } else {
      return segmentMatch.value.map((matchValue) => {
        const segmentWithType = segmentsWithTypes.find(
          (segment) => segment.name === matchValue
        );
        return segmentWithType;
      });
    }
  });
}

export function rebuildInconsistentConstraintsInRules(
  rules: Rule[],
  segmentsWithTypes: SegmentWithType[]
): Rule[] {
  const restructuredRules = rules.map((rule) => {
    const rebuildMap: { [type: string]: SegmentMatch } = {};
    const uniqueSegmentValues = new Set<string>();

    if (Array.isArray(rule.segmentMatch)) {
      const typesExcludedFromConstraint = new Set<string>();

      const segmentMatch = rule.segmentMatch;
      segmentMatch.forEach((match) => {
        match.value.forEach((matchValue) => {
          const segmentWithType = segmentsWithTypes.find(
            (segment) => segment.name === matchValue
          );

          if (!segmentWithType) {
            return;
          }

          const segmentTypeWasUsedInAnotherConstraint =
            typesExcludedFromConstraint.has(segmentWithType.typeLabel);

          const segmentIsNotUnique = uniqueSegmentValues.has(
            segmentWithType.name
          );

          if (segmentTypeWasUsedInAnotherConstraint || segmentIsNotUnique) {
            return;
          }

          const partOfCompositeTypetWasUsedAsTypeInAnotherConstraint =
            Array.from(segmentWithType.compositeTypeParts || []).some(
              (type) => type in rebuildMap
            );

          if (
            segmentWithType.compositeType &&
            partOfCompositeTypetWasUsedAsTypeInAnotherConstraint
          ) {
            return;
          }

          rebuildMap[segmentWithType.typeLabel] = rebuildMap[
            segmentWithType.typeLabel
          ]
            ? {
                ...rebuildMap[segmentWithType.typeLabel],
                ...match,
                value: [
                  ...rebuildMap[segmentWithType.typeLabel].value,
                  matchValue,
                ],
              }
            : { ...match, value: [matchValue] };

          uniqueSegmentValues.add(matchValue);

          if (segmentWithType.compositeType) {
            (segmentWithType.compositeTypeParts as Set<string>).forEach(
              (type) => {
                typesExcludedFromConstraint.add(type);
              }
            );
          }
        });
      });

      return getObjectDeepClone({
        ...rule,
        segmentMatch: Object.values(rebuildMap),
      });
    } else {
      return getObjectDeepClone(rule);
    }
  });

  return restructuredRules;
}

export function shouldRulesBeRebuilded(
  rules: Rule[],
  segmentsWithTypes: SegmentWithType[]
) {
  const calculatedTypesOfMatches = getSegmentMatchesWithTypes(
    rules,
    segmentsWithTypes
  );

  const needToRebuild = calculatedTypesOfMatches.some((rule) => {
    return rule.some((segments) => {
      if (segments && Array.isArray(segments)) {
        const constraintSelectedType = new Set();
        const previouslyUsedTypes = new Set<string>();

        segments.forEach((segment) => {
          if (!segment) {
            return false;
          }

          if (
            previouslyUsedTypes.size &&
            Array.from(previouslyUsedTypes).includes(segment.typeLabel)
          ) {
            return true;
          }

          constraintSelectedType.add(
            segment.compositeType || segment.singleType
          );

          segment.compositeTypeParts?.forEach((part) =>
            previouslyUsedTypes.add(part)
          );
        });

        if (constraintSelectedType.size > 1) {
          return true;
        }

        previouslyUsedTypes.add(segments[0]?.typeLabel || '');
      }

      return false;
    });
  });

  return needToRebuild;
}

const constraintChangesMap: {
  [actionName: string]: (
    params: ConstraintConfigParams
  ) => ConstraintDropdownConfig[][];
} = {
  [CollectionEvents.ItemAdded]({ ruleIndex, rulesConstraintDropdownsConfig }) {
    const updatedConfig = getObjectDeepClone(rulesConstraintDropdownsConfig);

    updatedConfig[ruleIndex].push({
      selectedType: '',
      types: new Set(),
    });

    return updatedConfig;
  },

  [CollectionEvents.ItemRemoved]({
    action: { removedValue, removedValues },
    constraintIndex,
    ruleIndex,
    rulesConstraintDropdownsConfig,
    typesExcludedFromDropdownOptions,
  }) {
    const updatedConfig = getObjectDeepClone(rulesConstraintDropdownsConfig);
    updatedConfig[ruleIndex].splice(constraintIndex, 1);

    const removed: ConstraintDroprownOption[] =
      removedValues || (removedValue ? [removedValue] : []);

    removed.forEach((option) => {
      typesExcludedFromDropdownOptions[ruleIndex].delete(option.typeLabel);

      if (option.compositeType) {
        (option.compositeTypeParts as Set<string>).forEach((type) => {
          typesExcludedFromDropdownOptions[ruleIndex].delete(type);
        });
      }
    });

    return updatedConfig;
  },

  [DropdownActions.SelectOption]({
    action: { option },
    constraintIndex,
    ruleIndex,
    rulesConstraintDropdownsConfig,
    typesExcludedFromDropdownOptions,
  }) {
    const updatedConfig = getObjectDeepClone(rulesConstraintDropdownsConfig);

    updatedConfig[ruleIndex][constraintIndex] = {
      ...updatedConfig[ruleIndex][constraintIndex],
      selectedType: option.typeLabel,
      types: new Set(
        option.compositeType
          ? [...(option.compositeTypeParts as Set<string>), option.typeLabel]
          : [option.typeLabel]
      ),
    };

    typesExcludedFromDropdownOptions[ruleIndex].add(option.typeLabel);

    if (option.compositeType) {
      (option.compositeTypeParts as Set<string>).forEach((type) => {
        typesExcludedFromDropdownOptions[ruleIndex].add(type);
      });
    }

    return updatedConfig;
  },

  [DropdownActions.RemoveValue](constraintParams) {
    const {
      action: { removedValue, removedValues },
      ruleIndex,
      typesExcludedFromDropdownOptions,
    } = constraintParams;

    const removed: ConstraintDroprownOption[] =
      removedValues || (removedValue ? [removedValue] : []);

    removed.forEach((option) => {
      typesExcludedFromDropdownOptions[ruleIndex].delete(option.typeLabel);

      if (option.compositeType) {
        (option.compositeTypeParts as Set<string>).forEach((type) => {
          typesExcludedFromDropdownOptions[ruleIndex].delete(type);
        });
      }
    });

    return this.checkForEmptyOptions(constraintParams);
  },

  [DropdownActions.Clear](constraintParams) {
    return this[DropdownActions.RemoveValue](constraintParams);
  },

  [DropdownActions.DeselectOption](constraintParams) {
    const {
      action: { option },
      ruleIndex,
      typesExcludedFromDropdownOptions,
    } = constraintParams;
    typesExcludedFromDropdownOptions[ruleIndex].delete(option.typeLabel);

    if (option.compositeType) {
      (option.compositeTypeParts as Set<string>).forEach((type) => {
        typesExcludedFromDropdownOptions[ruleIndex].delete(type);
      });
    }

    return this.checkForEmptyOptions(constraintParams);
  },

  checkForEmptyOptions({
    constraintIndex,
    options,
    rulesConstraintDropdownsConfig,
    ruleIndex,
  }) {
    const updatedConfig = getObjectDeepClone(rulesConstraintDropdownsConfig);

    if (!options.length) {
      updatedConfig[ruleIndex][constraintIndex] = {
        ...rulesConstraintDropdownsConfig[ruleIndex][constraintIndex],
        selectedType: '',
        types: new Set(),
      };
    }

    return updatedConfig;
  },
};

export function getUpdatedConstraintsConfig({
  action,
  constraintIndex,
  options,
  rule,
  ruleIndex,
  rulesConstraintDropdownsConfig,
  typesExcludedFromDropdownOptions,
}: ConstraintConfigParams) {
  const updatedConfig = constraintChangesMap[action.action]({
    action,
    constraintIndex,
    options,
    rule,
    ruleIndex,
    rulesConstraintDropdownsConfig,
    typesExcludedFromDropdownOptions,
  });

  return updatedConfig;
}
