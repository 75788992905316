import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import {
  ProjectListPage,
  ProjectDetailsPage,
  FlagListPage,
  FlagDetailsPage,
  SegmentListPage,
  SegmentDetailsPage,
  VisualizationListPage,
  AuditlogListPage,
  TagListPage,
  NotFoundPage,
  FlagNotFoundPage,
} from '../pages';
import { AppLayout } from './app-layout';
import { AbsolutePath } from './routes';

export default function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={AbsolutePath.INDEX} element={<AppLayout />}>
          <Route
            index
            element={<Navigate to={AbsolutePath.PROJECT_LIST} replace />}
          />
          <Route
            path={AbsolutePath.PROJECT_LIST}
            element={<ProjectListPage />}
          />
          <Route
            path={AbsolutePath.PROJECT_DETAILS}
            element={<ProjectDetailsPage />}
          />
          <Route path={AbsolutePath.FLAG_LIST} element={<FlagListPage />} />
          <Route
            path={AbsolutePath.FLAG_DETAILS}
            element={<FlagDetailsPage />}
          />
          <Route
            path={AbsolutePath.FLAG_NOT_FOUND}
            element={<FlagNotFoundPage />}
          />
          <Route
            path={AbsolutePath.SEGMENT_LIST}
            element={<SegmentListPage />}
          />
          <Route
            path={AbsolutePath.SEGMENT_DETAILS}
            element={<SegmentDetailsPage />}
          />
          <Route
            path={AbsolutePath.VISUALIZATION_LIST}
            element={<VisualizationListPage />}
          />
          <Route
            path={AbsolutePath.AUDITLOG_LIST}
            element={<AuditlogListPage />}
          />
          <Route path={AbsolutePath.TAG_LIST} element={<TagListPage />} />
        </Route>

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}
