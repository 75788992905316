import { Button } from './button';
import { ButtonProps } from './types';

export const ButtonSave: React.FC<ButtonProps> = (props) => {
  const DEFAULT_TEXT = props.disabled ? 'Saved' : 'Save';
  const buttonText = props.children || props.text || DEFAULT_TEXT;
  return (
    <Button icon="checkmark-released" data-testid="button-save" {...props}>
      {buttonText}
    </Button>
  );
};
