import { Icon } from 'yarn-design-system-react-components';
import { LinkProps } from './types';

export const LinkExternal: React.FC<LinkProps> = ({ href, children }) => {
  return (
    <a href={href} target="_blank" rel="noreferrer">
      <Icon name="expand" size="md" status="default" />
      <span>{children}</span>
    </a>
  );
};
