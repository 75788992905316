import { Fragment } from 'react';
import {
  List,
  ListItem,
  ListItemLabel,
  ListTitle,
} from 'yarn-design-system-react-components';
import { Segment } from '@feature-flags/entities';
import { groupSegments } from './utils';
import styles from './segment-list.module.scss';

interface SegmentListProps {
  segments: Segment[];
  handleItemSelect: (segment: Segment) => void;
}

export function SegmentList({ segments, handleItemSelect }: SegmentListProps) {
  const { groupedSegments, notGroupedSegments } = groupSegments(segments || []);

  return (
    <List
      type="ul"
      data-testid="segment-list"
      className={`${styles['segment-list']} w-auto negative-margin`}
    >
      {segments.length === 0 && (
        <ListItem>
          <ListItemLabel>{'No segments...'}</ListItemLabel>
        </ListItem>
      )}
      {Object.keys(groupedSegments).map((groupName, groupIndex) => (
        <Fragment key={groupName + groupIndex}>
          {groupName && (
            <ListTitle className={`${styles['list__title']}`}>
              <ListItemLabel>
                <h6>{groupName}</h6>
              </ListItemLabel>
            </ListTitle>
          )}
          {groupedSegments[groupName].map((segment) => (
            <ListItem
              key={segment.name}
              interactive
              onClick={() => handleItemSelect(segment)}
            >
              <ListItemLabel>{segment.name}</ListItemLabel>
            </ListItem>
          ))}
        </Fragment>
      ))}
      {!!notGroupedSegments.length && (
        <Fragment>
          <ListTitle className={`${styles['list__title']}`}>
            <ListItemLabel>
              <h6>Not grouped</h6>
            </ListItemLabel>
          </ListTitle>
          {notGroupedSegments.map((segment) => (
            <ListItem
              key={segment.name}
              interactive
              onClick={() => handleItemSelect(segment)}
            >
              <ListItemLabel>{segment.name}</ListItemLabel>
            </ListItem>
          ))}
        </Fragment>
      )}
    </List>
  );
}
