import { Button } from './button';
import { ButtonProps } from './types';

export const ButtonDelete: React.FC<ButtonProps> = (props) => {
  const DEFAULT_TEXT = props.disabled ? 'Deleted' : 'Delete';
  const buttonText = props.children || props.text || DEFAULT_TEXT;

  return (
    <Button icon="delete" data-testid="button-delete" {...props}>
      {buttonText}
    </Button>
  );
};
