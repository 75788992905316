import { useRef } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'yarn-design-system-react-components';
import { SegmentRecord } from '@feature-flags/entities';
import { createEngine } from '@feature-flags/lib';
import { Brands } from '../../constants';
import { VisualizationListProps } from './types';
import { VisualizationLabel } from './visualization-label';

export function VisualizationList({
  engineId,
  flags,
  segments,
}: VisualizationListProps & { engineId: string }) {
  const engine = useRef(createEngine({ flags, segments, uniqueId: engineId }));
  const segmentsAsColumns: SegmentRecord[] = segments.filter(
    (segment) =>
      segment.name !== Brands.adidas && segment.name !== Brands.reebok
  );

  return (
    <div className="table-responsive">
      <Table className="w-full">
        <TableHead>
          <TableRow variant="head">
            <TableCell>Flag</TableCell>
            <TableCell>Description</TableCell>
            {segmentsAsColumns.map((segment, columnIndex) => (
              <TableCell
                key={segment.id + '_' + columnIndex}
                className="text-center"
              >
                {segment.name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {flags.map((flag, rowIndex) => (
            <TableRow key={flag.id + '_' + rowIndex} variant="body">
              <TableCell>{flag.name}</TableCell>
              <TableCell>{flag.description}</TableCell>
              {segmentsAsColumns.map((segment, columnIndex) => {
                const [adidasLabelColor, reebokLabelColor] = [
                  Brands.adidas,
                  Brands.reebok,
                ].map((brand) =>
                  engine.current.eval({
                    flagName: flag.name,
                    context: {
                      country: segment.name,
                      brand,
                    },
                  })?.value
                    ? 'black'
                    : 'white'
                );

                return (
                  <TableCell key={segment.id + '_' + columnIndex}>
                    <VisualizationLabel
                      brand={Brands.adidas}
                      color={adidasLabelColor}
                      country={segment.name}
                      flagName={flag.name}
                      flags={flags}
                      segments={segments}
                    />
                    <VisualizationLabel
                      brand={Brands.reebok}
                      color={reebokLabelColor}
                      country={segment.name}
                      flagName={flag.name}
                      flags={flags}
                      segments={segments}
                    />
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
