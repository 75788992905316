export function timeSince(date: Date): string {
  const now = new Date();
  const provided = new Date(date);
  const seconds = Math.floor((now.getTime() - provided.getTime()) / 1000);

  const years = seconds / 31536000;
  if (years >= 1) return getTimeString(years, 'year');

  const months = seconds / 2592000;
  if (months >= 1) return getTimeString(months, 'month');

  const days = seconds / 86400;
  if (days >= 1) return getTimeString(days, 'day');

  const hours = seconds / 3600;
  if (hours >= 1) return getTimeString(hours, 'hour');

  const minutes = seconds / 60;
  if (minutes >= 1) return getTimeString(minutes, 'minute');

  return 'A moment ago';
}

function getTimeString(value: number, unit: string): string {
  const plural = Math.floor(value) !== 1 ? 's' : '';
  return Math.floor(value) + ` ${unit}${plural} ago`;
}
