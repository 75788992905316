import { TextField } from 'yarn-design-system-react-components';
import {
  EnvironmentRecord,
  FlagRecord,
  SegmentRecord,
} from '@feature-flags/entities';
import { Loader } from '../../atoms';
import { Header, Sidebar, VisualizationList } from '../../organisms';
import { FilterProps } from '../../types';

interface VisualizationListTemplateProps {
  engineId: string;
  environments?: EnvironmentRecord[];
  filters: FilterProps[];
  flags: FlagRecord[];
  isLoadingFlags?: boolean;
  isLoadingSegments?: boolean;
  segments: SegmentRecord[];
}

export function VisualizationListTemplate({
  engineId,
  environments,
  filters,
  flags,
  isLoadingFlags,
  isLoadingSegments,
  segments,
}: VisualizationListTemplateProps) {
  return (
    <>
      <Header />
      <main data-testid="visualization-list-template">
        <Sidebar environments={environments} />
        {isLoadingFlags || isLoadingSegments ? (
          <Loader />
        ) : (
          <section className="container container-x-padding fill">
            <header>
              <h3>Visualization</h3>
              <div className="row row-no-edge-gutters space-p-v-3">
                {filters.map((item, index: number) => {
                  return (
                    <div className="col-xs-6" key={item.name + index}>
                      <TextField
                        placeholder={item.placeholder}
                        type="text"
                        value={item.value}
                        onChange={item.onChange}
                        className="space-m-b-0"
                      />
                    </div>
                  );
                })}
              </div>
            </header>
            <VisualizationList
              flags={flags}
              segments={segments}
              engineId={engineId}
            />
          </section>
        )}
      </main>
    </>
  );
}
