import { AuditLogRecord, EnvironmentRecord } from '@feature-flags/entities';
import { Loader } from '../../atoms';
import { Header, Sidebar, AuditlogList } from '../../organisms';

interface AuditlogListTemplateProps {
  environments: EnvironmentRecord[];
  isLoading: boolean;
  logs?: AuditLogRecord[];
}

export function AuditlogListTemplate({
  environments,
  isLoading,
  logs,
}: AuditlogListTemplateProps) {
  return (
    <>
      <Header />
      <main data-testid="logs-list-template">
        <Sidebar environments={environments} />
        {isLoading ? (
          <Loader />
        ) : (
          <section className="container container-x-padding fill">
            <header className="space-p-b-3">
              <h3>Logs</h3>
            </header>
            <AuditlogList logs={logs} />
          </section>
        )}
      </main>
    </>
  );
}
