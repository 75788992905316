import { MsalAuthenticationResult } from '@azure/msal-react';

type ErrorFallbackProps = {
  error: Error | MsalAuthenticationResult['error'];
  resetErrorBoundary?: () => void;
};

export default function ErrorFallback({
  error,
  resetErrorBoundary,
}: ErrorFallbackProps) {
  return (
    <div role="alert" className="space-p-6">
      <h4>Something went wrong...</h4>
      <p>{error?.message}</p>
      {resetErrorBoundary && (
        <button onClick={resetErrorBoundary}>Try again</button>
      )}
    </div>
  );
}
