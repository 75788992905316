import {
  AuditLogRecord,
  EnvironmentRecord,
  FlagRecord,
  ProjectRecord,
  SegmentRecord,
  SegmentWithFlags,
  Tag,
  TagRecord,
} from '@feature-flags/entities';

import { generatePath } from 'react-router-dom';

export class RequestError extends Error {
  details: any;
  status: number;
  constructor(message: string, status: number, details: any) {
    super(message);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, RequestError);
    }

    this.name = 'RequestError';
    this.status = status;
    this.details = details;
  }
}

export type ApiClient = ReturnType<typeof createApiClient>;

export function createApiClient(token?: string) {
  async function doRequest<T>(
    input: RequestInfo,
    init: RequestInit = {}
  ): Promise<T> {
    const response = await fetch(input, {
      ...init,
      headers: {
        ...init.headers,
        Authorization: token ? `Bearer ${token}` : '',
      },
    });
    const data = await response.json();
    if (!response.ok) {
      const statusText = data.message || response.statusText;
      throw new RequestError(statusText, response.status, data.details);
    }
    return data as T;
  }

  enum Routes {
    PROJECT_LIST = `/api/projects`,
    PROJECT_DETAILS = `/api/projects/:projectName`,
    ENVIRONMENT_LIST = `/api/projects/:projectName/environments`,
    FLAG_LIST = `/api/projects/:projectName/environments/:environmentName/flags`,
    FLAG_DETAILS = `/api/projects/:projectName/environments/:environmentName/flags/:flagName`,
    FLAG_COPY = `/api/projects/:projectName/environments/:sourceEnvironmentName/flags?copyFromEnv=:sourceEnvironmentName&copyToEnv=:destinationEnvironmentName&flagName=:flagName`,
    FLAG_LINKED_ENVIRONMENTS = `/api/projects/:projectName/environments/:environmentName/flags/:flagName/linked-environments`,
    FLAG_VERSIONS = `/api/projects/:projectName/environments/:environmentName/flags/:flagName/versions`,
    SEGMENT_LIST = '/api/projects/:projectName/environments/:environmentName/segments',
    SEGMENT_DETAILS = '/api/projects/:projectName/environments/:environmentName/segments/:segmentName',
    SEGMENT_WITH_FLAGS = '/api/projects/:projectName/environments/:environmentName/segments/:segmentName/flags',
    AUDITLOG_LIST = `/api/auditlog/?projectName=:projectName&environmentName=:environmentName`,
    TAG_LIST = '/api/projects/:projectName/environments/:environmentName/tags',
    TAG_DETAILS = '/api/projects/:projectName/environments/:environmentName/tags/:tagName',
    TAG_WITH_FLAGS = '/api/projects/:projectName/environments/:environmentName/tags-with-flags',
    TAGS_ATTACHED_TO_FLAG = '/api/projects/:projectName/environments/:environmentName/tags/:flagName',
  }

  function getProjects(): Promise<ProjectRecord[]> {
    return doRequest<ProjectRecord[]>(generatePath(Routes.PROJECT_LIST));
  }

  function getProject(projectName: string): Promise<ProjectRecord> {
    return doRequest<ProjectRecord>(
      generatePath(Routes.PROJECT_DETAILS, { projectName })
    );
  }

  function createProject(data: Partial<ProjectRecord>): Promise<ProjectRecord> {
    return doRequest<ProjectRecord>(generatePath(Routes.PROJECT_LIST), {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  function getEnvironments(projectName: string): Promise<EnvironmentRecord[]> {
    if (!projectName) {
      return Promise.resolve([]);
    }
    return doRequest<EnvironmentRecord[]>(
      generatePath(Routes.ENVIRONMENT_LIST, { projectName })
    );
  }

  function getFlags(
    projectName: string,
    environmentName: string
  ): Promise<FlagRecord[]> {
    return doRequest<FlagRecord[]>(
      generatePath(Routes.FLAG_LIST, { projectName, environmentName })
    );
  }

  function getFlag(
    projectName: string,
    environmentName: string,
    flagName: string
  ): Promise<FlagRecord> {
    return doRequest<FlagRecord>(
      generatePath(Routes.FLAG_DETAILS, {
        projectName,
        environmentName,
        flagName,
      })
    );
  }

  function createFlag(
    projectName: string,
    environmentName: string,
    data: Partial<FlagRecord>
  ): Promise<FlagRecord> {
    return doRequest<FlagRecord>(
      generatePath(Routes.FLAG_LIST, {
        projectName,
        environmentName,
      }),
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }

  function copyFlag(
    projectName: string,
    sourceEnvironmentName: string,
    destinationEnvironmentName: string,
    flagName: string
  ): Promise<FlagRecord> {
    return doRequest<FlagRecord>(
      generatePath(Routes.FLAG_COPY, {
        projectName,
        sourceEnvironmentName,
        destinationEnvironmentName,
        flagName,
      }),
      {
        method: 'POST',
      }
    );
  }

  function getFlagLinkedEnvironments(
    projectName: string,
    environmentName: string,
    flagName: string
  ): Promise<{ environments: string[] }> {
    if (!flagName) {
      return Promise.resolve({ environments: [] });
    }

    return doRequest<{ environments: string[] }>(
      generatePath(Routes.FLAG_LINKED_ENVIRONMENTS, {
        projectName,
        environmentName,
        flagName,
      })
    );
  }

  function getFlagVersions(
    projectName: string,
    environmentName: string,
    flagName: string
  ): Promise<AuditLogRecord[]> {
    return doRequest<AuditLogRecord[]>(
      generatePath(Routes.FLAG_VERSIONS, {
        projectName,
        environmentName,
        flagName,
      })
    );
  }

  function getFlagLastVersion(
    projectName: string,
    environmentName: string,
    flagName: string
  ): Promise<AuditLogRecord> {
    return doRequest<AuditLogRecord>(
      generatePath(Routes.FLAG_VERSIONS, {
        projectName,
        environmentName,
        flagName,
      }) + `?lastVersion=true`
    );
  }

  function updateFlag(
    projectName: string,
    environmentName: string,
    flagName: string,
    data: Partial<FlagRecord>
  ): Promise<FlagRecord> {
    return doRequest<FlagRecord>(
      generatePath(Routes.FLAG_DETAILS, {
        projectName,
        environmentName,
        flagName,
      }),
      {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }

  function deleteFlag(
    projectName: string,
    environmentName: string,
    flagName: string
  ): Promise<FlagRecord> {
    return doRequest<FlagRecord>(
      generatePath(Routes.FLAG_DETAILS, {
        projectName,
        environmentName,
        flagName,
      }),
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }

  function getSegments(
    projectName: string,
    environmentName: string
  ): Promise<SegmentRecord[]> {
    return doRequest<SegmentRecord[]>(
      generatePath(Routes.SEGMENT_LIST, { projectName, environmentName })
    );
  }

  function getSegment(
    projectName: string,
    environmentName: string,
    segmentName: string
  ): Promise<SegmentRecord> {
    return doRequest<SegmentRecord>(
      generatePath(Routes.SEGMENT_DETAILS, {
        projectName,
        environmentName,
        segmentName,
      })
    );
  }

  function getFlagsAssociatedWithSegment(
    projectName: string,
    environmentName: string,
    segmentName: string
  ): Promise<SegmentWithFlags> {
    return doRequest<SegmentWithFlags>(
      generatePath(Routes.SEGMENT_WITH_FLAGS, {
        projectName,
        environmentName,
        segmentName,
      })
    );
  }

  function createSegment(
    projectName: string,
    environmentName: string,
    data: Partial<SegmentRecord>
  ): Promise<SegmentRecord> {
    return doRequest<SegmentRecord>(
      generatePath(Routes.SEGMENT_LIST, { projectName, environmentName }),
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }

  function updateSegment(
    projectName: string,
    environmentName: string,
    segmentName: string,
    data: Partial<SegmentRecord>
  ): Promise<SegmentRecord> {
    return doRequest<SegmentRecord>(
      generatePath(Routes.SEGMENT_DETAILS, {
        projectName,
        environmentName,
        segmentName,
      }),
      {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }

  function deleteSegment(
    projectName: string,
    environmentName: string,
    segmentName: string
  ): Promise<FlagRecord> {
    return doRequest<FlagRecord>(
      generatePath(Routes.SEGMENT_DETAILS, {
        projectName,
        environmentName,
        segmentName,
      }),
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }

  function getLogs(
    projectName: string,
    environmentName: string
  ): Promise<AuditLogRecord[]> {
    return doRequest<AuditLogRecord[]>(
      generatePath(Routes.AUDITLOG_LIST, {
        projectName,
        environmentName,
      })
    );
  }

  function getTags(
    projectName?: string,
    environmentName?: string
  ): Promise<Tag[]> {
    if (!projectName) {
      return Promise.resolve([]);
    }
    return doRequest<Tag[]>(
      generatePath(Routes.TAG_LIST, { projectName, environmentName })
    );
  }

  function getTagsWithFlags(
    projectName?: string,
    environmentName?: string
  ): Promise<TagRecord[]> {
    if (!projectName) {
      return Promise.resolve([]);
    }
    return doRequest<TagRecord[]>(
      generatePath(Routes.TAG_WITH_FLAGS, { projectName, environmentName })
    );
  }

  function getTagsAttachedToFlag(
    projectName: string,
    environmentName: string,
    flagName: string
  ): Promise<Tag[]> {
    return doRequest<Tag[]>(
      generatePath(Routes.TAGS_ATTACHED_TO_FLAG, {
        projectName,
        environmentName,
        flagName,
      })
    );
  }

  function attachTagToFlag(
    projectName: string,
    environmentName: string,
    flagName: string,
    data: { attached: Tag[]; detached: Tag[] }
  ): Promise<Tag> {
    return doRequest<Tag>(
      generatePath(Routes.TAGS_ATTACHED_TO_FLAG, {
        projectName,
        environmentName,
        flagName,
      }),
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }

  function createTag(
    projectName: string,
    environmentName: string,
    data: Tag[]
  ): Promise<Tag> {
    return doRequest<Tag>(
      generatePath(Routes.TAG_LIST, {
        projectName,
        environmentName,
      }),
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }

  function updateTag(
    projectName: string,
    environmentName: string,
    tagName: string,
    data: Partial<TagRecord>
  ): Promise<TagRecord> {
    return doRequest<TagRecord>(
      generatePath(Routes.TAG_DETAILS, {
        projectName,
        environmentName,
        tagName,
      }),
      {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }

  function deleteTag(
    projectName: string,
    environmentName: string,
    tagName: string
  ): Promise<TagRecord> {
    return doRequest<TagRecord>(
      generatePath(Routes.TAG_DETAILS, {
        projectName,
        environmentName,
        tagName,
      }),
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }

  return {
    /* environments group */
    getEnvironments,

    /* project group */
    getProjects,
    getProject,
    createProject,

    /* flag group */
    getFlags,
    getFlag,
    createFlag,
    copyFlag,
    getFlagLinkedEnvironments,
    getFlagVersions,
    getFlagLastVersion,
    updateFlag,
    deleteFlag,

    /* segment group */
    getSegments,
    getSegment,
    getFlagsAssociatedWithSegment,
    createSegment,
    updateSegment,
    deleteSegment,

    /* auditlog group */
    getLogs,

    /* tag group */
    getTags,
    getTagsWithFlags,
    getTagsAttachedToFlag,
    attachTagToFlag,
    createTag,
    updateTag,
    deleteTag,
  };
}
