import { TextField } from 'yarn-design-system-react-components';
import { TagListTemplateProps } from '../../pages/tag-list-page/types';
import { Header, TagList } from '../../organisms';
import { Sidebar } from '../../organisms';

export function TagListTemplate({
  associatedFlagsPath,
  environments,
  filterText,
  tags,
  deleteTag,
  filterItems,
  saveNewTags,
  saveUpdatedTag,
}: TagListTemplateProps) {
  return (
    <>
      <Header />
      <main data-testid="tag-list-template">
        <Sidebar environments={environments} />
        <section className="container container-x-padding fill">
          <header>
            <h3>Tags</h3>
            <div className="flex space-p-v-3">
              <div className="flex-expand">
                <TextField
                  placeholder="Search..."
                  type="text"
                  value={filterText}
                  onChange={filterItems}
                  className="space-m-b-0"
                />
              </div>
            </div>
          </header>
          <TagList
            associatedFlagsPath={associatedFlagsPath}
            tags={tags}
            deleteTag={deleteTag}
            saveNewTags={saveNewTags}
            saveUpdatedTag={saveUpdatedTag}
          />
        </section>
      </main>
    </>
  );
}
