import React, { useRef, useEffect } from 'react';
import { useModalContext } from './context';
import { ModalProps } from './types';
import { Modal as YarnModal } from 'yarn-design-system-react-components';
import styles from './modal.module.scss';

export const Modal: React.FC<ModalProps> = (props) => {
  const modal = useModalContext();
  let activeIndex = 0;
  const focusableElements = useRef<NodeListOf<HTMLElement>>();
  const submitButton = useRef<HTMLElement>();
  const ref = useRef<any>();
  const oldActiveElement = document.activeElement as HTMLElement;

  // map of keyboard listeners
  const keyListenersMap = new Map([
    ['Escape', handleEscape],
    ['Enter', handleEnter],
    ['Tab', handleTab],
  ]);

  function handleKeydown(evt: KeyboardEvent) {
    const listener = keyListenersMap.get(evt.key);
    return listener && listener(evt);
  }

  function handleTab(evt: KeyboardEvent) {
    const total = focusableElements.current?.length || 0;

    if (!evt.shiftKey) {
      activeIndex + 1 === total ? (activeIndex = 0) : (activeIndex += 1);
      focusableElements.current?.[activeIndex]?.focus();

      return evt.preventDefault();
    }

    if (evt.shiftKey) {
      activeIndex - 1 < 0 ? (activeIndex = total - 1) : (activeIndex -= 1);
      focusableElements.current?.[activeIndex]?.focus();

      return evt.preventDefault();
    }
  }

  function handleEscape(evt: KeyboardEvent) {
    if (evt.key === 'Escape') handleClose();
  }

  function handleEnter(evt: KeyboardEvent) {
    const newActiveElement = document.activeElement;
    if (
      !newActiveElement ||
      newActiveElement?.tagName === 'BODY' ||
      newActiveElement?.classList.contains('form-control')
    ) {
      submitButton.current?.click();
    }
  }

  useEffect(() => {
    if (ref.current) {
      // Select all focusable elements within ref
      focusableElements.current = ref.current.querySelectorAll(
        'a, button:not(.btn-icon), textarea, input, select'
      );
    }
  }, [ref]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    document.addEventListener('keydown', handleKeydown);

    // Keep submit button
    submitButton.current = Array.prototype.find.call(
      focusableElements.current,
      (item: HTMLElement) =>
        item.classList.contains('btn-primary') && item.closest('footer')
    );

    // Let us make an autofocus for the first textfield, otherwise focus for the submit button
    const firstField = focusableElements.current?.[0];
    firstField?.tagName === 'INPUT'
      ? firstField?.focus()
      : submitButton.current?.focus();

    return () => {
      // Detach listener when component unmounts
      document.removeEventListener('keydown', handleKeydown);
      // Return focus to the previously focused element
      oldActiveElement?.focus();
    };
  }, []);

  const { title, onClose, ...rest } = props;

  function renderTitle() {
    return typeof title === 'string' ? (
      <h5 className={styles['modal-title']}>{title}</h5>
    ) : (
      title
    );
  }

  function handleClose() {
    if (onClose) {
      onClose();
    }
    modal.default.hide();
  }

  // Close modal on unmount lifecycle to prevent leaving it open in the Context API
  useEffect(() => {
    return handleClose;
  }, []);

  return (
    <div
      id="modal"
      data-testid="modal"
      className={styles['modal-accessability']}
      ref={ref}
    >
      <YarnModal
        {...rest}
        title={renderTitle()}
        onClose={handleClose}
        overlay="dark"
        closable
        floating
      >
        {props.children}
      </YarnModal>
    </div>
  );
};
