import { FlagRecord, Tag } from '@feature-flags/entities';
import { FlagFilterConditions } from '../../organisms/flag/types';
import { DropdownOption } from '../../types';
import { FlagFilterKeys } from '../../constants';

export function getParamsFromUrl(
  urlParams: URLSearchParams,
  options?: { pick?: string[]; omit?: string[] }
): Record<string, string[]> {
  const deserializedParams: Record<string, string[]> = {};

  if (options && options.pick) {
    options.pick.forEach((pickedParam) => {
      const pickedParamValue = urlParams.get(pickedParam);

      if (pickedParamValue) {
        const currentDeserializedValue = deserializedParams[pickedParam];
        deserializedParams[pickedParam] = currentDeserializedValue
          ? [...currentDeserializedValue, pickedParamValue]
          : [pickedParamValue];
      }
    });

    return deserializedParams;
  }

  for (const [filterProperty, value] of urlParams.entries()) {
    if (!options || (options.omit && !options.omit.includes(filterProperty))) {
      const currentDeseriazlizedValue = deserializedParams[filterProperty];

      deserializedParams[filterProperty] = currentDeseriazlizedValue
        ? [...currentDeseriazlizedValue, value]
        : [value];
    }
  }

  return deserializedParams;
}

export const filterConditions: FlagFilterConditions = {
  [FlagFilterKeys.Status]: (flag: FlagRecord, [statusValue]: string[]) =>
    flag.disabled === (statusValue === 'disabled'),
  [FlagFilterKeys.Type]: (flag: FlagRecord, typeValues: string[]) =>
    typeValues.includes(typeof flag.variants[0].value),
  [FlagFilterKeys.Tag]: (flag: FlagRecord, tagValues: string[]) =>
    tagValues.some((value) => flag.tags?.includes(value)),
};

export function buildAdvancedFilterDropdowns(
  flagData: FlagRecord[] = [],
  TagListData: Tag[] = []
) {
  if (!flagData) {
    return null;
  }

  const dropdownOptions: { [dropdownName: string]: Set<string> } = {
    status: new Set<string>(),
    type: new Set<string>(),
    tag: new Set<string>(TagListData),
  };

  flagData.forEach((flag) => {
    dropdownOptions.status.add(flag.disabled ? 'disabled' : 'enabled');
    dropdownOptions.type.add(typeof flag.variants[0].value);
  });

  const options: {
    [dropdownName: string]: DropdownOption[];
  } | null = Object.keys(dropdownOptions).reduce(
    (
      dropdowns: {
        [dropdownName: string]: DropdownOption[];
      },
      currentDropdownName
    ) => {
      dropdowns[currentDropdownName] = Array.from(
        dropdownOptions[currentDropdownName]
      ).map((parameter) => ({
        value: parameter,
        label: parameter,
      }));

      return dropdowns;
    },
    {}
  );

  return options;
}
