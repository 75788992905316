import { useLocation, useNavigate } from 'react-router-dom';
import { AbsolutePath } from '../../app/routes';

export const useRedirect = (path: string, replace = false) => {
  const navigate = useNavigate();
  const location = useLocation();
  const indexPath = AbsolutePath.INDEX;
  const options = {
    replace,
  };
  if (path === '/') {
    // replace to default index page
    path = indexPath;
  }
  if (
    path === indexPath &&
    (location.pathname === indexPath || location.pathname === '/')
  ) {
    // avoid infinity redirection on index page
    return () => {
      console.warn('Redirect has been canceled to avoid the inifinity loop!');
    };
  }
  return () => navigate(path, options);
};
