import React from 'react';
import { CardBodyProps, CardTypes } from './types';
import { HighlightEmptyness, Input, Button } from '../../atoms';
import styles from './card.module.scss';

export const CardBody: React.FC<CardBodyProps> = ({
  item,
  type,
  value,
  children,
  icon,
  placeholder,
  onChange,
  onClick,
}) => {
  return (
    <div className={`${styles['card-body']}`}>
      {type === CardTypes.TEXTFIELD ? (
        <HighlightEmptyness value={value}>
          <Input
            placeholder={placeholder}
            multiline
            rows={5}
            value={value}
            onChange={onChange}
            className={styles['form-group']}
          ></Input>
        </HighlightEmptyness>
      ) : type === CardTypes.BUTTON ? (
        <Button icon={icon} onClick={onClick!} text={value} />
      ) : (
        children
      )}
    </div>
  );
};
