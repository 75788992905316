import { useParams } from 'react-router-dom';
import { useEnvironments, useLogs } from '../../api-client/api-client-hooks';

export function useAuditlogListController() {
  const { projectName, environmentName } = useParams();
  const { data: environments } = useEnvironments(projectName!);
  const { isLoading, data: logs } = useLogs(projectName!, environmentName!);

  return {
    environments,
    isLoading,
    logs,
  };
}
