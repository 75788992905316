import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { RequestError } from '../../api-client/api-client';
import {
  useApiClient,
  useEnvironments,
  useSegments,
} from '../../api-client/api-client-hooks';
import { AbsolutePath } from '../../app/routes';
import { useNotify } from '../../atoms';

export function useSegmentListPageController() {
  const { projectName, environmentName } = useParams();
  const { data: environments } = useEnvironments(projectName);
  const {
    data: segmentsData,
    isLoading,
    refetch: refetchSegments,
  } = useSegments(projectName!, environmentName!);
  const { createSegment } = useApiClient();
  const navigate = useNavigate();
  const notify = useNotify();

  const navigateToSegment = (segmentName: string) => {
    const segmentPath = generatePath(AbsolutePath.SEGMENT_DETAILS, {
      projectName,
      environmentName,
      segmentName,
    });

    navigate(segmentPath);
  };

  async function saveNewSegment(segmentName: string) {
    try {
      const newSegment = { name: segmentName, match: [] };

      const segment = await createSegment(
        projectName!,
        environmentName!,
        newSegment
      );

      await refetchSegments();

      const route = generatePath(AbsolutePath.SEGMENT_DETAILS, {
        projectName,
        environmentName,
        segmentName: segment.name,
      });

      navigate(route);
    } catch (err) {
      if (err instanceof RequestError) {
        if (err.details.message === 'UniqueViolationError') {
          notify.error(`Segment "${segmentName}" already exists!`);
          return;
        }
      }
      notify.error('Problem on saving a new segment');
    }
  }

  return {
    environments,
    isLoading,
    segmentsData,
    navigateToSegment,
    saveNewSegment,
  };
}
