import { Button } from './button';
import { ButtonProps } from './types';

export const ButtonTumbler: React.FC<ButtonProps> = (props) => {
  const DEFAULT_TEXT = props.tumbler ? 'Enable' : 'Pause';
  const DEFAULT_ICON = props.tumbler ? 'play' : 'pause';
  const buttonText = props.children || props.text || DEFAULT_TEXT;
  return (
    <Button icon={DEFAULT_ICON} data-testid="button-tumbler" {...props}>
      {buttonText}
    </Button>
  );
};
