/* Notify types */
export enum NotifyTypes {
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
  SUCCESS = 'success',
  UNKNOWN = 'unknown',
}

/* Notify item */
export interface NotifyProps {
  id: number | string;
  type: NotifyTypes;
  message: string;
  timeout?: number;
}

/* Notify state */
export interface NotifyState {
  items: NotifyProps[];
  icons: Record<NotifyTypes, string>;
}

/* Notify reducer and actions */
export enum NotifyActions {
  PUSH = 'push',
  REMOVE = 'remove',
}

export interface NotifyReducer {
  [NotifyActions.PUSH]: {
    id?: NotifyProps['id'];
    type: NotifyTypes;
    message: string;
  };
  [NotifyActions.REMOVE]: { id: NotifyProps['id'] };
}

export interface NotifyAction {
  type: NotifyActions;
  payload?: NotifyReducer[NotifyActions];
}

/* Notify Context */
export type NotifyApiOptions = Record<string, string>;
export type NotifyApi = Record<
  NotifyTypes,
  (message: unknown, options?: NotifyApiOptions) => void
>;

export interface NotifyContextValue extends NotifyApi {
  state: NotifyState;
  dispatch: (action: NotifyAction) => void;
}
