import { useModalContext } from '../../organisms';
import { FlagListTemplate } from '../../templates';
import { useFlagListPageController } from './controller';

export const FlagListPage: React.FC = () => {
  // Data and Controller Group
  const {
    projectName,
    environmentName,
    flags,
    environments,
    tags,
    deserializedSearchParams,
    deserializedFilterParams,
    showAdvancedFilterState,
    filterDropdownsOptionsState,
    flagListQueryState,
    ...FlagListController
  } = useFlagListPageController();

  // Context API Group
  const modal = useModalContext();

  // Is this page fully loaded?
  const isLoaded = !!(flags && environments);

  // Render
  return (
    <FlagListTemplate
      data={{
        projectName,
        environmentName,
        flags,
        environments,
        deserializedSearchParams,
        deserializedFilterParams,
      }}
      state={{
        showAdvancedFilterState,
        filterDropdownsOptionsState,
        flagListQueryState,
      }}
      controller={{ FlagListController }}
      modal={modal}
      isLoaded={isLoaded}
    />
  );
};
