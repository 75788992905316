import { Dropdown } from 'yarn-design-system-react-components';
import { DropdownOption } from '../../types';
import { FlagTypes } from '../../constants';
import { Header, Modal } from '../../organisms';
import { Button, ButtonSave, Input } from '../../atoms';
import { useFlagNotFoundPageController } from './controller';
import styles from './flag-not-found-page.module.scss';

export function FlagNotFoundPage() {
  const {
    flagName,
    flagType,
    linkedEnvironments,
    showCreateModal,
    clone,
    create,
    hideModal,
    leavePage,
    setFlagType,
    showModal,
  } = useFlagNotFoundPageController();

  const typeOptions = Object.values(FlagTypes).map((type) => ({
    value: type,
    label: type,
    selected: type === flagType,
  }));

  return (
    <>
      <Header />
      <div className={`${styles['flag-not-found-page']} space-p-6`}>
        <h4>Flag was not found</h4>
        <div className="space-m-v-5">
          <Button type="secondary" onClick={leavePage} className="space-m-r-2">
            Leave the page
          </Button>
          <Button type="primary" onClick={showModal}>
            Create this flag
          </Button>
        </div>
        {showCreateModal && (
          <Modal
            title="Create a new flag"
            onClose={hideModal}
            className={`${styles['create-modal']}`}
          >
            <>
              <Input
                label="Name"
                value={flagName}
                disabled
                data-testid="modal-creation-name"
              />
              <Dropdown
                label="Type"
                value={typeOptions.filter((option) => option.selected)}
                options={typeOptions}
                clearable={false}
                inline={false}
                onChange={(option: DropdownOption) => {
                  setFlagType(option.value);
                }}
              />
              <div className="space-m-t-3 text-right">
                <Button
                  onClick={hideModal}
                  text="Cancel"
                  className="space-m-r-2"
                />
                <ButtonSave
                  onClick={create}
                  type="primary"
                  data-testid="modal-creation-submit"
                />
              </div>
            </>
            {Boolean(linkedEnvironments.length) && (
              <div className="space-m-t-5">
                <b>Flag can be copied from:</b>
                <ul className="space-p-t-3 space-p-b-2">
                  {linkedEnvironments.map((env) => (
                    <li key={env} className="space-m-b-3">
                      <span>{env}</span>
                      <span className="pull-right">
                        <Button
                          type="secondary"
                          size="sm"
                          onClick={() => clone(env)}
                        >
                          Copy
                        </Button>
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </Modal>
        )}
      </div>
    </>
  );
}
