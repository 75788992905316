import { useNotify } from './notify-context';
import { NotifyProps } from './types';
import Notify from './notify';
import styles from './notify.module.scss';

const NotifyList: React.FC = () => {
  const { state } = useNotify(true);
  return (
    <section className={styles.notify}>
      <div className={styles.notify_list} role="list">
        {state.items.map((item: NotifyProps, index: number) => (
          <Notify key={index} {...item} />
        ))}
      </div>
    </section>
  );
};

export default NotifyList;
