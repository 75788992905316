import { FlagListTemplateProps } from '../../types';
import {
  Modal,
  Header,
  Sidebar,
  FlagList,
  FlagFilter,
  FlagCreation,
  FlagClone,
} from '../../organisms';
import { Loader } from '../../atoms';

export const FlagListTemplate: React.FC<FlagListTemplateProps> = ({
  data: {
    projectName,
    environmentName,
    flags,
    environments,
    deserializedSearchParams,
    deserializedFilterParams,
  },
  state: {
    showAdvancedFilterState,
    filterDropdownsOptionsState,
    flagListQueryState,
  },
  controller: {
    FlagListController: {
      goToFlag,
      createFlag,
      copyFlag,
      disableFlag,
      setFilterBySearchText,
      toggleFilters,
      setFilterByParams,
      checkFlagBeforeCreate,
    },
  },
  modal,
  isLoaded,
}) => {
  return (
    <>
      <Header />
      <main data-testid="flag-list-template">
        <Sidebar environments={environments} />
        {isLoaded ? (
          <section className="container container-x-padding fill">
            <header>
              <h3>Flags</h3>
              <FlagFilter
                {...{
                  flags,
                  deserializedFilterParams,
                  deserializedSearchParams,
                  setFilterBySearchText,
                  showAdvancedFilterState,
                  toggleFilters,
                  filterDropdownsOptionsState,
                  setFilterByParams,
                  modal,
                }}
              />
            </header>
            <FlagList
              flags={flags}
              goToFlag={goToFlag}
              disableFlag={disableFlag}
            />
            {modal.state.create && (
              <Modal
                title="Create a new flag"
                onClose={() => {
                  flagListQueryState.clear();
                  modal.create.hide();
                }}
              >
                {flagListQueryState.data?.environments?.length > 0 ? (
                  <FlagClone
                    projectName={projectName}
                    environmentName={environmentName}
                    flagName={flagListQueryState.params?.flagName}
                    flagType={flagListQueryState.params?.flagType}
                    linkedEnvironments={flagListQueryState.data.environments}
                    copyFlag={copyFlag}
                    createFlag={createFlag}
                    queryState={flagListQueryState}
                  />
                ) : (
                  <FlagCreation
                    flagName={flagListQueryState.params?.flagName}
                    flagType={flagListQueryState.params?.flagType}
                    checkFlagBeforeCreate={checkFlagBeforeCreate}
                    createFlag={createFlag}
                    queryState={flagListQueryState}
                  />
                )}
              </Modal>
            )}
          </section>
        ) : (
          <Loader />
        )}
      </main>
    </>
  );
};
