import { Dropdown } from 'yarn-design-system-react-components';
import { FlagTypes } from '../../../constants';
import { DropdownOption } from '../../../types';
import { ButtonSave, Input } from '../../../atoms';
import { FlagCreationProps } from './types';

export const FlagCreation: React.FC<FlagCreationProps> = ({
  flagName = '',
  flagType = FlagTypes.BOOLEAN,
  checkFlagBeforeCreate,
  createFlag,
  queryState,
}) => {
  const { isLoading, setParams } = queryState;

  if (flagType === '') {
    flagType = FlagTypes.BOOLEAN;
  }

  const options = Object.values(FlagTypes).map((flagTypeOption) => ({
    value: flagTypeOption,
    label: flagTypeOption,
    isSelected: flagTypeOption === flagType,
  }));

  async function handleSubmit() {
    const isFlagCanBeCreated = await checkFlagBeforeCreate(flagName, flagType);
    if (isFlagCanBeCreated) {
      createFlag(flagName, flagType);
    }
  }

  return (
    <>
      <Input
        value={flagName}
        label="Name"
        onChange={(inputValue) =>
          setParams({
            flagName: inputValue.toUpperCase().replace('-', '_'),
          })
        }
        placeholder="NEW_DESIGN"
      />
      <Dropdown
        value={options.find((option) => option.isSelected)}
        label="Type"
        options={options}
        clearable={false}
        onChange={(selectedOption: DropdownOption) =>
          setParams({
            flagType: selectedOption.value,
          })
        }
      />
      <footer className="space-m-t-4 text-right">
        <ButtonSave
          onClick={handleSubmit}
          isLoading={isLoading}
          disabled={flagName.trim() === ''}
          type="primary"
          text="Create"
        />
      </footer>
    </>
  );
};
