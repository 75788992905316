import React from 'react';
import {
  Link,
  useMatch,
  useNavigate,
  useParams,
  generatePath,
} from 'react-router-dom';
import {
  Divider,
  Dropdown,
  Icon,
  List,
  ListItem,
  ListItemLabel,
  Sidebar as YarnSidebar,
} from 'yarn-design-system-react-components';
import { LinkExternal } from '../../atoms/link-external';
import { EnvironmentRecord } from '@feature-flags/entities';
import { AbsolutePath } from '../../app/routes';
import { DropdownOption } from '../../types/dropdown';
import { ExternalLinks } from '../../constants/external-links';
import style from './sidebar.module.scss';

type SidebarProps = {
  environments?: EnvironmentRecord[];
};

export function Sidebar({ environments = [] }: SidebarProps) {
  const navigate = useNavigate();

  const { projectName, environmentName } = useParams();

  const match = useMatch(AbsolutePath.PROJECT_ENVIRONMENT + '/:section/*');

  const {
    params: { section },
  } = match!;
  const detailsPagePath = match?.params['*'];

  function changeEnvironment({ value: environmentName }: DropdownOption) {
    const route = generatePath(AbsolutePath.PROJECT_ENVIRONMENT, {
      projectName,
      environmentName,
    });
    const shouldNavigateToDetailsPage = detailsPagePath && section === 'flags';

    navigate(
      `${route}/${section}/${
        shouldNavigateToDetailsPage ? detailsPagePath : ''
      }`
    );
  }

  const environmentsDropdownOptions = environments.map((env) => ({
    value: env.name,
    label: env.name,
    selected: env.name === environmentName,
  }));

  const currentEnvironment = environmentsDropdownOptions.find(
    (opt) => opt.selected
  );

  function navigateToSection(sectionPath: string) {
    const sectionRoute = generatePath(sectionPath, {
      projectName,
      environmentName,
    });

    navigate(sectionRoute);
  }

  return (
    <>
      <YarnSidebar
        animated
        closable={false}
        id="sidebar"
        data-testid="sidebar"
        open
        position="left"
        className={style.sidebar}
      >
        <React.Fragment key=".0">
          <h6 className={`${style['title']} space-p-t-3 space-p-b-2`}>
            <Link
              className="space-p-l-3 space-p-r-3"
              to={AbsolutePath.PROJECT_LIST}
            >
              {projectName}
            </Link>
          </h6>
          <div className="space-p-2 space-p-l-3 space-p-r-3">
            <Dropdown
              value={currentEnvironment}
              type="single"
              portaling={true}
              clearable={false}
              options={environmentsDropdownOptions}
              onChange={changeEnvironment}
            />
          </div>
          <Divider type="horizontal" />
          <List type="ul" data-testid="sidebar-navigation">
            <ListItem
              onClick={() => navigateToSection(AbsolutePath.FLAG_LIST)}
              active={useMatch(`${AbsolutePath.FLAG_LIST}/*`)}
              interactive
            >
              <Icon name="report" size="md" status="default" />
              <ListItemLabel>Flags</ListItemLabel>
            </ListItem>
            <ListItem
              onClick={() => navigateToSection(`${AbsolutePath.SEGMENT_LIST}`)}
              active={useMatch(`${AbsolutePath.SEGMENT_LIST}/*`)}
              interactive
            >
              <Icon name="people-app" size="md" status="default" />
              <ListItemLabel>Segments</ListItemLabel>
            </ListItem>
            <ListItem
              onClick={() => navigateToSection(AbsolutePath.VISUALIZATION_LIST)}
              active={useMatch(`${AbsolutePath.VISUALIZATION_LIST}/*`)}
              interactive
            >
              <Icon name="chart" size="md" status="default" />
              <ListItemLabel>Visualization</ListItemLabel>
            </ListItem>
            <ListItem
              onClick={() => navigateToSection(AbsolutePath.AUDITLOG_LIST)}
              active={useMatch(`${AbsolutePath.AUDITLOG_LIST}/*`)}
              interactive
            >
              <Icon name="logbook" size="md" status="default" />
              <ListItemLabel>Logs</ListItemLabel>
            </ListItem>
            <ListItem
              onClick={() => navigateToSection(AbsolutePath.TAG_LIST)}
              active={useMatch(`${AbsolutePath.TAG_LIST}/*`)}
              interactive
            >
              <Icon
                name="ila"
                size="md"
                status="default"
                className={`${style['tag-icon']}`}
              />
              <ListItemLabel>Tags</ListItemLabel>
            </ListItem>
          </List>
          <Divider type="horizontal" />
          <LinkExternal href={ExternalLinks.LINK_EXT_DOCUMENTATION}>
            Documentation
          </LinkExternal>
        </React.Fragment>
      </YarnSidebar>
    </>
  );
}
