import { SegmentDetailsTemplate } from '../../templates';
import { useSegmentDetailsController } from './controller';

export function SegmentDetailsPage() {
  const {
    environments,
    isLoading,
    segmentData,
    usageInFlags,
    deleteSegment,
    getUsageInFlags,
    saveSegment,
  } = useSegmentDetailsController();

  return (
    <SegmentDetailsTemplate
      environments={environments || []}
      isLoading={isLoading}
      segmentData={segmentData}
      usageInFlags={usageInFlags}
      deleteSegment={deleteSegment}
      saveSegment={saveSegment}
      getUsageInFlags={getUsageInFlags}
    />
  );
}
