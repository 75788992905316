import { Divider } from 'yarn-design-system-react-components';
import { FlagDetailsTemplateProps } from '../../types';
import {
  Button,
  ButtonDelete,
  ButtonSave,
  ButtonTumbler,
  InputDescription,
  Loader,
} from '../../atoms';
import {
  ButtonList,
  DefaultVariant,
  RuleDetailsList,
  VariantDetailsList,
} from '../../molecules';
import {
  Header,
  Sidebar,
  TagSelect,
  FlagHistoryWithModal,
  FlagDeletion,
  FlagCopy,
  Modal,
} from '../../organisms';

export const FlagDetailsTemplate: React.FC<FlagDetailsTemplateProps> = ({
  data: {
    projectName,
    environmentName,
    flag,
    segments,
    environments,
    versions,
    versionsToDiff,
    tags,
  },
  state: { flagState, isFlagModified, diffState, flagDetailsQueryState },
  controller: {
    FlagDetailsController: {
      disableFlag,
      deleteFlag,
      restoreFlag,
      copyFlag,
      setDescription,
      setVersion,
      setVariants,
      setRules,
      setRebuiltRules,
      setDefaultVariant,
    },
  },
  united: { saveFlag, changeTagsInFlag },
  modal,
  isLoaded,
}) => {
  return (
    <>
      <Header />
      <main data-testid="flag-details-template">
        <Sidebar environments={environments} />
        {isLoaded ? (
          <section className="container container-x-padding fill">
            <header>
              <h3>{flagState.name}</h3>
              <ButtonList className="space-p-v-3">
                <ButtonSave
                  type="primary"
                  onClick={saveFlag}
                  disabled={!isFlagModified}
                  isLoading={
                    flagDetailsQueryState.isLoading &&
                    flagDetailsQueryState.queryName === 'updateFlag'
                  }
                />
                <ButtonTumbler
                  type="secondary"
                  onClick={() =>
                    disableFlag(flagState.name, !flagState.disabled)
                  }
                  tumbler={flagState.disabled}
                  isLoading={
                    flagDetailsQueryState.isLoading &&
                    flagDetailsQueryState.queryName === 'disableFlag'
                  }
                />
                <ButtonDelete type="secondary" onClick={modal.confirm.show} />
                <Button
                  type="secondary"
                  icon="logbook"
                  onClick={modal.history.show}
                  text={`History (${versions?.length})`}
                />
                <Button
                  type="secondary"
                  icon="copy"
                  onClick={modal.copy.show}
                  text={`Copy`}
                />
              </ButtonList>
              <InputDescription
                value={flagState.description ?? ''}
                onChange={setDescription}
              />
            </header>
            <VariantDetailsList
              variants={flagState.variants}
              onChange={setVariants}
            />
            <Divider type="horizontal" className="section-details-divider" />
            <RuleDetailsList
              rules={flagState.rules}
              variants={flagState.variants}
              segments={segments}
              onChange={setRules}
              setRebuiltRules={setRebuiltRules}
            />
            <DefaultVariant
              value={flagState.defaultVariant}
              variants={flagState.variants}
              label={flagState.rules.length > 0 ? 'else' : 'default'}
              onChange={setDefaultVariant}
            />
            <Divider type="horizontal" className="section-details-divider" />
            <TagSelect
              tags={tags?.map((tag) => tag.value)}
              selectedTags={flagState.tags}
              onChange={changeTagsInFlag}
            ></TagSelect>
            {modal.state.confirm && (
              <FlagDeletion flagState={flagState} deleteFlag={deleteFlag} />
            )}
            {modal.state.history && (
              <FlagHistoryWithModal
                versions={versions}
                diffState={diffState}
                onChange={setVersion}
                onSubmit={restoreFlag}
              />
            )}
            {modal.state.copy && (
              <Modal
                title="Copy the flag version"
                onClose={() => {
                  flagDetailsQueryState.clear();
                  modal.copy.hide();
                }}
              >
                <FlagCopy
                  flagName={flag?.name}
                  environments={environments}
                  defaultEnv={environmentName}
                  sourceEnv={flagDetailsQueryState.params?.sourceEnv}
                  destinationEnv={flagDetailsQueryState.params?.destinationEnv}
                  isLoading={
                    flagDetailsQueryState.isLoading &&
                    flagDetailsQueryState.queryName === 'copyFlag'
                  }
                  versionsToDiff={versionsToDiff}
                  onChangeSourceEnv={(sourceEnv) =>
                    flagDetailsQueryState.setParams({ sourceEnv })
                  }
                  onChangeDestinationEnv={(destinationEnv) =>
                    flagDetailsQueryState.setParams({ destinationEnv })
                  }
                  onSuccess={() => {
                    flagDetailsQueryState.clear();
                    modal.copy.hide();
                  }}
                  copyFlag={copyFlag}
                />
              </Modal>
            )}
          </section>
        ) : (
          <Loader />
        )}
      </main>
    </>
  );
};
