import { loginRequest, msalConfig } from '../config';
import { AccessTokenProvider } from '../access-token-provider';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import ErrorFallback from '../errors/error-fallback';

const msalPca = new PublicClientApplication(msalConfig);

const AppAuth: React.FC = ({ children }) => {
  return (
    <MsalProvider instance={msalPca}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest}
        errorComponent={ErrorFallback}
      >
        <AccessTokenProvider>{children}</AccessTokenProvider>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
};

export default AppAuth;
