import { Highlight as YarnHighlight } from 'yarn-design-system-react-components';
import { HighlightProps } from './types';

export const Highlight: React.FC<HighlightProps> = ({
  children,
  value,
  matcher,
  onChange,
}) => {
  return (
    <YarnHighlight value={value} matcher={matcher} onChange={onChange}>
      {children}
    </YarnHighlight>
  );
};
