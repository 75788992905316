import { Button } from './button';
import { ButtonProps } from './types';

export const ButtonHelp: React.FC<ButtonProps> = (props) => {
  const DEFAULT_TEXT = props.disabled ? 'Help' : 'Help';
  const buttonText = props.children || props.text || DEFAULT_TEXT;
  return (
    <Button icon="information-big" data-testid="button-help" {...props}>
      {buttonText}
    </Button>
  );
};
