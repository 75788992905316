import { useModalContext } from '../../organisms';
import { FlagDetailsTemplate } from '../../templates';
import { useTagListPageController } from '../tag-list-page/controller';
import { FlagDetailsTemplateProps } from '../../types';
import { useFlagDetailsPageController } from './controller';

export const FlagDetailsPage: React.FC = () => {
  // Data and Controller
  const {
    projectName,
    environmentName,
    flagName,
    flag,
    segments,
    environments,
    versions,
    versionsToDiff,
    flagState,
    isFlagModified,
    diffState,
    flagDetailsQueryState,
    ...FlagDetailsController
  } = useFlagDetailsPageController();
  const { tags, tagState, ...tagController } = useTagListPageController();

  // Context api
  const modal = useModalContext();

  // United methods
  const saveFlag: FlagDetailsTemplateProps['united']['saveFlag'] = () => {
    if (!flagState) return;
    tagController.attachTagToFlag(flagState!.name);
    FlagDetailsController.updateFlag();
  };

  const changeTagsInFlag: FlagDetailsTemplateProps['united']['changeTagsInFlag'] =
    (event, selectedTags) => {
      const newSelectedTags = tagController.changeSelectedTags(
        event,
        selectedTags
      );
      FlagDetailsController.setTags(newSelectedTags);
      return newSelectedTags;
    };

  // Is page fully loaded?
  const isLoaded = !!(
    flag &&
    segments &&
    environments &&
    versions &&
    flagState &&
    diffState
  );

  // Render
  return (
    <FlagDetailsTemplate
      data={{
        projectName,
        environmentName,
        flag,
        segments,
        environments,
        versions,
        versionsToDiff,
        tags,
      }}
      state={{
        flagState,
        isFlagModified,
        diffState,
        tagState,
        flagDetailsQueryState,
      }}
      controller={{ FlagDetailsController }}
      united={{ saveFlag, changeTagsInFlag }}
      modal={modal}
      isLoaded={isLoaded}
    />
  );
};
