import { Button } from '../atoms';
import { useRedirect } from '../helpers/redirect';
import { AbsolutePath } from '../app/routes';

export default function Error404() {
  const redirect = useRedirect(AbsolutePath.INDEX);
  return (
    <>
      <div role="alert" className="space-p-6">
        <h4>The page is not found!</h4>
        <p>
          Looks like there is no page here anymore or it was temporarily moved.
          <br />
          Sorry! Let's start your way from the Home page!
        </p>
        <Button type="primary" onClick={redirect}>
          Go to Home page
        </Button>
      </div>
    </>
  );
}
