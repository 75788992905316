import ErrorBoundary from '../errors/error-boundary';
import AppAuth from './app-auth';
import AppProviders from './app-providers';
import AppRouter from './app-router';

export default function App() {
  return (
    <ErrorBoundary>
      <AppAuth>
        <AppProviders>
          <AppRouter />
        </AppProviders>
      </AppAuth>
    </ErrorBoundary>
  );
}
