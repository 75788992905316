export function parseSafe(valueString: any): any {
  if (typeof valueString !== 'string') {
    return valueString;
  }
  try {
    const value = JSON.parse(valueString);
    return value;
  } catch {
    return valueString;
  }
}
