import { Segment } from '@feature-flags/entities';

export function groupSegments(segments: Segment[]): {
  groupedSegments: { [groupName: string]: Segment[] };
  notGroupedSegments: Segment[];
} {
  const groupedSegments: { [groupName: string]: Segment[] } = {};
  const notGroupedSegments: Segment[] = [];

  (segments || []).forEach((segment) => {
    const segmentSubtypes = segment.match.reduce(
      (finalType: Set<string>, currentMatch) => {
        finalType.add(currentMatch.key);
        return finalType;
      },
      new Set<string>()
    );

    if (!segmentSubtypes.size) {
      return notGroupedSegments.push(segment);
    }

    const segmentCompositType = Array.from(segmentSubtypes).sort().join(' + ');

    if (groupedSegments[segmentCompositType]) {
      groupedSegments[segmentCompositType].push(segment);
    } else {
      groupedSegments[segmentCompositType] = [segment];
    }
  });

  return {
    groupedSegments,
    notGroupedSegments,
  };
}
