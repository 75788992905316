import { useParams } from 'react-router-dom';
import {
  useEnvironments,
  useFlags,
  useSegments,
} from '../../api-client/api-client-hooks';

export function useVisualizationPageController() {
  const { projectName, environmentName } = useParams();
  const { data: environments } = useEnvironments(projectName!);
  const { isLoading: isLoadingFlags, data: flagsData } = useFlags(
    projectName!,
    environmentName!
  );
  const { data: segmentsData, isLoading: isLoadingSegments } = useSegments(
    projectName!,
    environmentName!
  );

  return {
    environments,
    environmentName,
    flagsData,
    isLoadingFlags,
    isLoadingSegments,
    projectName,
    segmentsData,
  };
}
